import clsx from "clsx";
import { HTMLProps } from "react";

export type InsightStatusType = "green" | "amber" | "red" | "grey";
type BodyType = HTMLProps<HTMLDivElement> & { status?: InsightStatusType };
export const Body = ({ status, children }: BodyType) => {
  return (
    <div className="atlas-text-xl atlas-font-medium atlas-py-1 atlas-flex atlas-items-center atlas-gap-1">
      {status && <Status status={status} />}
      {children}
    </div>
  );
};

enum StatusColors {
  green = "atlas-bg-positive-500",
  amber = "atlas-bg-amber-400",
  red = "atlas-bg-red-600",
  grey = "atlas-bg-neutral-300",
}
const Status = ({ status }: { status: InsightStatusType }) => {
  return (
    <div
      className={clsx("atlas-rounded-full atlas-h-3 atlas-w-3", status && StatusColors[status])}
    />
  );
};
