import { Theme } from "react-migration/lib/theme/Theme";

import { FC, useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import {
  exactMatch,
  possibleUKCombinations,
  possibleUSCombinations,
  rejectInvalidCombinations,
} from "react-migration/domains/comparables/util/PropertyTypes";
import { NestedOptions } from "./NestedOptions";
import { PropertyDesignationGQL } from "react-migration/lib/typings/Comparables";
import { DCSCondition } from "react-migration/domains/comparables/typings/Comparables";
import { Accordion } from "react-migration/components/Accordion";

enum DisabledTextContentColor {
  Light = "atlas-text-text-base-primary-disabled",
  Dark = "atlas-text-text-base-primary-disabled",
}

export interface FilterPropertyTypesProps {
  disabled?: boolean;
  disabledFields?: Set<PropertyDesignationGQL>;
  hiddenFields?: Set<PropertyDesignationGQL>;
  selectedPropTypes: DCSCondition[];
  setSelectedPropTypes: (conditions: DCSCondition[]) => void;
  theme?: Theme;
}

export const FilterPropertyTypes: FC<FilterPropertyTypesProps> = ({
  disabled,
  disabledFields,
  hiddenFields,
  selectedPropTypes,
  setSelectedPropTypes,
  theme = Theme.Light,
}) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);
  const options = usAccess ? possibleUSCombinations : possibleUKCombinations;

  const deselect = (deselectedConditions: DCSCondition[]) => {
    const nextConditions = selectedPropTypes.filter((activeCondition) =>
      deselectedConditions.every(
        (deselectedCondition) => !exactMatch(deselectedCondition, activeCondition)
      )
    );
    if (nextConditions.length !== selectedPropTypes.length) {
      setSelectedPropTypes(rejectInvalidCombinations(usAccess ? "US" : "UK", nextConditions));
    }
  };

  const select = (selectedConditions: DCSCondition[]) => {
    const filtered = selectedConditions.filter((selectedCondition) =>
      selectedPropTypes.every((activeCondition) => !exactMatch(activeCondition, selectedCondition))
    );

    if (filtered.length) {
      setSelectedPropTypes(
        rejectInvalidCombinations(usAccess ? "US" : "UK", [...selectedPropTypes, ...filtered])
      );
    }
  };

  const [openedAccordions, setOpenedAccordions] = useState<string[]>([]);
  return (
    <div>
      <h4
        className={`atlas-mb-2 atlas-flex atlas-items-center atlas-justify-between ${
          disabled ? DisabledTextContentColor[theme] : ""
        }`}
      >
        <span>{t("comparables.map.sidebar.filters.filters.type")}</span>
      </h4>
      <Accordion
        type="multiple"
        onValueChange={disabled ? undefined : (value) => setOpenedAccordions(value)}
      >
        {options.map((option) => {
          const designation = option.designation as PropertyDesignationGQL;
          if (hiddenFields?.has(designation)) {
            return null;
          }

          return (
            <NestedOptions
              disabled={disabled || disabledFields?.has(designation)}
              key={designation}
              deselect={disabled ? undefined : deselect}
              openedAccordions={disabled ? openedAccordions : []}
              option={option}
              selectedOptions={selectedPropTypes}
              select={disabled ? undefined : select}
            />
          );
        })}
      </Accordion>
    </div>
  );
};
