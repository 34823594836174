import { LayerType } from "../../types";
import {
  GreenGreyBeltLayerSummary,
  GreenGreyBeltSummarySidebar,
} from "./GreenGreyBeltLayerSummary";

export const GREEN_GREY_BELT_TYPE_ID = "GREEN_GREY_BELT_TYPE_ID";

export const GreenGreyBeltLayerType: LayerType = {
  id: GREEN_GREY_BELT_TYPE_ID,
  LayerSummary: GreenGreyBeltLayerSummary,
  SelectionSidebar: GreenGreyBeltSummarySidebar,
};
