import { isNumber } from "lodash";
import * as Accordion from "@radix-ui/react-accordion";
import { Icon } from "react-migration/components/Icon";
import { useLocalStorage } from "usehooks-ts";
import { getAttributeNumericValue } from "../utils";
import { BarSparkline } from "./charts/BarSparkline";
import { PropsWithChildren } from "react";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { OutputAreaAttribute } from "./types";
import { AttributeComparisonTextSummary } from "./AttributeComparisonTextSummary";

const LOCAL_STORAGE_KEY_PREFIX = "DemographicsLayerAccordion";

type DesignationsComparisonChartProps = {
  attribute: OutputAreaAttribute;
  designation: SingleDesignation;
  comparableDesignation?: SingleDesignation;
};

export const DesignationsComparisonChart = ({
  attribute,
  designation,
  comparableDesignation,
}: DesignationsComparisonChartProps) => {
  const { designation_attributes: attributes } = designation || {};
  const { designation_attributes: comparativeDesignationAttributes } = comparableDesignation || {};
  const summaryNumericValue = getAttributeNumericValue(attributes, attribute.attributeKey);

  const summaryComparativeNumericValue = getAttributeNumericValue(
    comparativeDesignationAttributes,
    attribute.attributeKey
  );

  return (
    <>
      {isNumber(summaryNumericValue) && (
        <>
          <BarSparkline
            valueFormatter={attribute.format}
            value={summaryNumericValue}
            comparativeValue={
              isNumber(summaryComparativeNumericValue) ? summaryComparativeNumericValue : undefined
            }
          />
          <div className="atlas-flex atlas-justify-between atlas-items-center atlas-gap-x-2 atlas-w-full atlas-py-2">
            <AttributeComparisonTextSummary
              label={attribute.label}
              value={summaryNumericValue}
              comparativeValue={summaryComparativeNumericValue}
              comparativeDisplayName={comparableDesignation?.display_name}
            />
          </div>
        </>
      )}
    </>
  );
};

type AttributeComparisonSummarySectionProps = PropsWithChildren<{
  heading: string;
  headlineAttribute: OutputAreaAttribute;
  designation: SingleDesignation;
  comparableDesignation?: SingleDesignation;
}>;

export function AttributeComparisonSummarySection({
  heading,
  headlineAttribute,
  /** The provided designation must have one of these attributes, else it will
   * not render. Should this be the requirement of the children to decide. */
  designation,
  comparableDesignation,
  children,
}: AttributeComparisonSummarySectionProps) {
  const { attributeKey } = headlineAttribute;
  const [accordionState, setAccordionState] = useLocalStorage<string[]>(
    `${LOCAL_STORAGE_KEY_PREFIX}_${attributeKey}`,
    []
  );

  return (
    <Accordion.Root type="multiple" value={accordionState} onValueChange={setAccordionState}>
      <Accordion.Item className="atlas-flex atlas-flex-col atlas-gap-y-3" value={attributeKey}>
        <Accordion.Trigger className="atlas-flex atlas-flex-col atlas-group">
          <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-items-center atlas-gap-x-2 atlas-w-full atlas-my-2">
            <h1 className="atlas-shrink-0 atlas-text-sm atlas-text-neutral-800 atlas-font-semibold">
              {heading}
            </h1>
            <Icon
              icon="icon-lt-arrow-down-s-line"
              extraClasses="atlas-transition-transform group-data-[state=open]:-atlas-rotate-180"
            />
          </div>
          <DesignationsComparisonChart
            attribute={headlineAttribute}
            designation={designation}
            comparableDesignation={comparableDesignation}
          />
        </Accordion.Trigger>
        <Accordion.Content className="atlas-flex atlas-flex-col atlas-gap-y-3 atlas-text-sm atlas-text-neutral-800 data-[state=closed]:atlas-hidden atlas-pb-4">
          {children}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
