import * as React from "react";
import clsx from "classnames";

type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  labelClassName?: string;
  error?: string;
  "data-testid"?: string;
};

// eslint-disable-next-line react/display-name
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, labelClassName, "data-testid": dataTestId, error, ...inputProps }, ref) => {
    const className = clsx(
      "atlas-block",
      "atlas-w-full",
      "atlas-border",
      "atlas-rounded-md",
      "atlas-border-solid",
      "atlas-border-neutral-300",
      !error && "hover:atlas-border-platform-600",
      "hover:atlas-outline-none",
      "focus:atlas-border",
      !error && "focus:atlas-border-platform-600",
      "focus:atlas-outline-none",
      "atlas-placeholder-neutral-400",
      "atlas-placeholder-text-sm",
      "atlas-py-2",
      "atlas-px-3",
      error && "atlas-border-red-700",
      "atlas-bg-white"
    );

    return (
      <label
        className={clsx(
          "atlas-mb-6",
          "atlas-relative",
          "atlas-w-full",
          error && "atlas-text-red-700"
        )}
      >
        <span className={clsx("atlas-block", "atlas-mb-1", labelClassName)}>{label}</span>
        <input
          ref={ref}
          {...inputProps}
          className={className}
          data-testid={dataTestId ?? "text-input"}
          type="text"
        />
        {Boolean(error) && (
          <span
            data-testid={`${dataTestId ?? "text-input"}-error-message`}
            className="atlas-mt-1 atlas-text-red-700 atlas-text-xs atlas-absolute atlas-right-0 -atlas-bottom-4"
          >
            {error}
          </span>
        )}
      </label>
    );
  }
);
