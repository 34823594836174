import classNames from "classnames";
import { AnchorHTMLAttributes, PropsWithChildren } from "react";

type DropdownMenuItemLinkProps = {
  disabled?: boolean;
  "data-testid"?: string;
  className?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const DropdownMenuItemLink = ({
  children,
  className: additionalClasses,
  "data-testid": dataTestId,
  ...restProps
}: PropsWithChildren<DropdownMenuItemLinkProps>) => {
  return (
    <a
      data-testid={dataTestId ?? "dropdown-menu-item-link"}
      className={classNames(
        "atlas-no-underline",
        "atlas-rounded",
        "atlas-cursor-pointer",
        "atlas-p-2",
        "atlas-m-2",
        "hover:atlas-bg-neutral-100",
        "focus:atlas-bg-neutral-100",
        "atlas-text-base",
        "atlas-text-text-base-primary",
        "atlas-block",
        "atlas-flex",
        additionalClasses
      )}
      {...restProps}
    >
      {children}
    </a>
  );
};
