export const SkipTraceTableBorderStyles = [
  "atlas-border-border-action",
  "atlas-border",
  "atlas-border-solid",
  "atlas-overflow-auto",
  "atlas-relative",
  "atlas-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]",
  "atlas-w-full",
  "atlas-h-full",
];

export const SkipTraceTableFixedHeaderStyles = [
  "atlas-sticky",
  "atlas-top-0",
  "atlas-bg-neutral-50",
  "atlas-py-4",
  "atlas-px-6",
  "atlas-font-semibold",
  "atlas-text-sm",
  "atlas-text-text-base-secondary",
  "atlas-border-border-action",
  "atlas-border-r",
  "atlas-border-b",
  "last:atlas-border-r-0",
];

export const SkipTraceTableRowStyles = [
  "atlas-border-x-0",
  "atlas-border-t-0",
  "atlas-border-b",
  "last:atlas-border-b-0",
  "atlas-border-solid",
  "atlas-border-border-action",
  "hover:atlas-bg-background-surface-neutral",
  "atlas-cursor-pointer",
];

export const SkipTraceTableColStyles = [
  "atlas-text-text-base-secondary",
  "atlas-font-normal",
  "atlas-text-sm",
  "atlas-leading-5",
  "atlas-whitespace-nowrap",
  "atlas-text-ellipsis",
  "atlas-overflow-hidden",
  "atlas-py-2",
  "atlas-px-6",
  "atlas-border-border-action",
  "atlas-border-r",
  "last:atlas-border-r-0",
];
