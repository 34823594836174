import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { LayerTypeSelectionSidebarProps } from "../../../types";
import { RecentApplicationsLayerSummary } from "./RecentApplicationsLayerSummary";

export const RecentApplicaitonsLayerSelectionSidebar = ({
  cardExpanded,
  layer,
  onLoadingChange,
  selection,
}: LayerTypeSelectionSidebarProps) => (
  <CollapsibleConsiderationsCard
    heading={layer.title}
    name={layer.title}
    defaultExpanded={cardExpanded}
    contentPadding={4}
  >
    <RecentApplicationsLayerSummary
      selection={selection}
      layer={layer}
      onLoadingChange={onLoadingChange}
    />
  </CollapsibleConsiderationsCard>
);
