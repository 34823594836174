import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { dateString, isAfterDate } from "src/js/util/dates";

type NullishString = string | null | undefined;
type FormatAdoptedPlanType = { date?: NullishString };
type FormatAdoptedPlanReturnType = {
  body: string;
  status?: InsightStatusType;
  footerValue: string;
};

export const formatAdoptedPlan = ({ date }: FormatAdoptedPlanType): FormatAdoptedPlanReturnType => {
  if (date) {
    const planDate = new Date(date);
    const today = new Date();
    today.setFullYear(today.getFullYear() - 5);
    const withinLast5Years = isAfterDate(planDate, today);
    return {
      footerValue: dateString(planDate),
      body: withinLast5Years ? "Within date" : "Out of date",
      // Plan Adoption Date over 5 years old
      status: withinLast5Years ? "red" : "green",
    };
  }
  return { footerValue: "-", body: "Unknown" };
};
