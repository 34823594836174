import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const HAZARDS: Layer = {
  id: ConstraintsCategory.HAZARDS,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  title: "Hazards",
  requiredFeatureAccess: [Feature.hazardsLayer],
  toggleable: true,
  description: "Nearby environmental hazards",
  layerConfig: {
    rootCategory: ConstraintsCategory.HAZARDS,
    categorySchema: [
      { key: "us_flood_zones_high_risk_1_percent_annual_chance_flood_hazard" },
      { key: "us_flood_zones_high_risk_coastal_1_percent_annual_chance_flood_hazard" },
      { key: "us_flood_zones_high_risk_regulatory_floodway" },
      { key: "us_flood_zones_high_risk_coastal_regulatory_floodway" },
      { key: "us_flood_zones_moderate_risk_0_2_percent_annual_chance_flood_hazard" },
      { key: "us_flood_zones_minimal_risk_future_conditions_1_percent_annual_chance_flood_hazard" },
      { key: "us_flood_zones_unknown_risk_area_with_risk_due_to_levee" },
      { key: "us_flood_zones_minimal_risk_area_with_reduced_risk_due_to_levee" },
      { key: "us_flood_zones_high_risk_special_floodway" },
      { key: "us_flood_zones_unknown_risk_area_of_undetermined_flood_hazard" },
      { key: "us_flood_zones_other" },
      { key: "us_wetlands_estuarine_and_marine_deepwater" },
      { key: "us_wetlands_estuarine_and_marine_wetland" },
      { key: "us_wetlands_freshwater_emergent_wetland" },
      { key: "us_wetlands_freshwater_forested_shrub_wetland" },
      { key: "us_wetlands_freshwater_pond" },
      { key: "us_wetlands_lake" },
      { key: "us_wetlands_riverine" },
      { key: "us_wetlands_other" },
    ],
  },
};
