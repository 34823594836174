import { PropsWithChildren } from "react";
import { useElementSize } from "react-migration/lib/util/useElementSize";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type TruncatedProps = PropsWithChildren<{
  onSeeMoreInfo: () => void;
  isExpanded: boolean;
  seeMoreText?: string;
}>;

export function Truncated({ children, onSeeMoreInfo, isExpanded, seeMoreText }: TruncatedProps) {
  const { t } = useTranslation();
  const maxHeight = 296;
  const [contentRef, { height: contentHeight }] = useElementSize();
  const showSeeMore = Math.ceil(contentHeight) >= maxHeight;

  return (
    <>
      {isExpanded ? (
        <div className="atlas-h-full atlas-overflow-auto">{children}</div>
      ) : (
        <div
          ref={contentRef}
          style={{ maxHeight }}
          className="atlas-relative atlas-bg-white atlas-overflow-hidden"
        >
          {children}
          {showSeeMore && (
            <a
              onClick={onSeeMoreInfo}
              style={{ background: "linear-gradient(0deg, white 65%, rgba(255, 255, 255, 0))" }}
              className="atlas-block atlas-p-3 atlas-pt-6 atlas-text-sm atlas-font-semibold atlas-text-text-base-active hover:atlas-text-text-base-active-hover atlas-cursor-pointer atlas-absolute atlas-bottom-0 atlas-right-0 atlas-left-0"
            >
              {seeMoreText || t("domains.bundle.components.see_more_info")}
            </a>
          )}
        </div>
      )}
    </>
  );
}
