import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import classNames from "classnames";
import { Button } from "react-migration/components/DeprecatedButton";
import { clearStore } from "src/js/stores/letter/actions";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { LetterEventName, logLetterEvent } from "./helpers/logLetterEvent";
import { siteStore } from "src/js/stores/site/store";
import { Footer } from "./components/Footer";
import { FinalStepWrapper } from "./components/FinalStep/FinalStepWrapper";

interface OrderConfirmedProps {
  onBackToSiteClick: () => void;
}

const OrderConfirmed = ({ onBackToSiteClick }: OrderConfirmedProps) => {
  const { t } = useTranslation();

  return (
    <FinalStepWrapper>
      <div
        className={classNames(
          "atlas-flex",
          "atlas-w-20",
          "atlas-h-20",
          "atlas-border",
          "atlas-justify-center",
          "atlas-items-center",
          "atlas-rounded-full",
          "atlas-border-background-surface-success-accent",
          "atlas-bg-background-surface-success-accent"
        )}
      >
        <i
          className={classNames(
            "icon-lt-check-line",
            "atlas-text-6xl",
            "atlas-flex",
            "atlas-text-white"
          )}
        />
      </div>

      <span
        className={classNames(
          "atlas-my-4",
          "atlas-text-content",
          "atlas-font-semibold",
          "atlas-text-xl",
          "atlas-leading-7"
        )}
      >
        {t("sites.letters.order_confirmed")}
      </span>

      <div className={classNames("atlas-max-w-xs", "atlas-text-center")}>
        <span className="atlas-text-text-base-secondary">
          {t("sites.letters.your_letters_will_be_sent_out_as_soon_as_possible")}
        </span>
      </div>

      <Button.Primary className="atlas-my-4" onClick={onBackToSiteClick}>
        {t("sites.letters.back_to_site")}
      </Button.Primary>
    </FinalStepWrapper>
  );
};

export const OrderConfirmedStep = () => {
  const { t } = useTranslation();
  const { siteCardId } = useSnapshot(siteStore);

  const onBackToSiteClicked = () => {
    clearStore();
    router.push({
      name: ROUTES.SITES,
      query: {
        card_page: "letters",
        site_id: siteCardId,
      },
    });
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_ORDER_CONFIRMED_PAGE_BACK_TO_SITES_BUTTON_CLICKED,
    });
  };

  return (
    <div
      data-testid="letters-order-confirmed-step"
      className={classNames("atlas-h-full", "atlas-w-full", "atlas-flex", "atlas-flex-col")}
    >
      <OrderConfirmed onBackToSiteClick={onBackToSiteClicked} />
      <Footer percentage={100}>
        <div className={classNames("atlas-flex", "atlas-flex-grow", "atlas-justify-end")}>
          <Button.Primary onClick={onBackToSiteClicked}>
            {t("sites.letters.back_to_site")}
          </Button.Primary>
        </div>
      </Footer>
    </div>
  );
};
