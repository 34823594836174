import { LayerType } from "../../types";
import { PlanningAuthorityControlPage } from "./ControlPage";
import {
  PLANNING_AUTHORITY_LAYER_SUMMARY_TYPE_ID,
  PLANNING_AUTHORITY_LAYER_TYPE_ID,
} from "./constants";
import { PlanningAuthorityLayerTypeProvider } from "./PlanningAuthorityContext";
import { PlanningAuthorityMapLayer } from "./PlanningAuthorityMapLayer";
import { PlanningAuthoritySelectionSidebar } from "./PlanningAuthoritySelectionSidebar";
import { planningAuthorityClickResolver } from "./planningAuthorityClickResolver";
import { PlanningAuthorityLayerSummary } from "./PlanningAuthorityLayerSummary";
import { PlanningAuthoritySummarySidebar } from "./PlanningAuthorityLayerSummary/PlanningAuthoritySummarySidebar";

export const PlanningAuthorityLayerType: LayerType = {
  id: PLANNING_AUTHORITY_LAYER_TYPE_ID,
  Provider: PlanningAuthorityLayerTypeProvider,
  MapLayer: PlanningAuthorityMapLayer,
  ControlPage: PlanningAuthorityControlPage,
  SelectionSidebar: PlanningAuthoritySelectionSidebar,
  clickResolver: planningAuthorityClickResolver,
};

export const PlanningAuthorityLayerSummaryType: LayerType = {
  id: PLANNING_AUTHORITY_LAYER_SUMMARY_TYPE_ID,
  LayerSummary: PlanningAuthorityLayerSummary,
  SelectionSidebar: PlanningAuthoritySummarySidebar,
};
