export function ErrorMessage() {
  const openIntercom = () => window.Intercom?.("show");

  return (
    <span className="atlas-text-sm">
      Please{" "}
      <a
        className="atlas-text-text-base-active hover:atlas-text-text-base-active-hover atlas-cursor-pointer"
        onClick={openIntercom}
      >
        get in touch
      </a>{" "}
      or try again later.
    </span>
  );
}
