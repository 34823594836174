import { KeyIndicatorColor } from "react-migration/lib/map/useKeyIndicatorTags";

export const presumptionInFavourTag = ({
  adoptedPlan,
  housingLandSupply,
  housingDeliverTest,
}: {
  adoptedPlan?: string;
  housingLandSupply?: string;
  housingDeliverTest?: string;
}) => {
  // All 3 key datapoints are favourable to the developer. i.e. the local council are not in a good position.
  if (adoptedPlan === "green" && housingLandSupply === "green" && housingDeliverTest === "green") {
    return {
      color: KeyIndicatorColor.GREEN,
      tag: "Likely to apply",
    };
    // Unlikely: At least one these are unfavourable.
  } else if (adoptedPlan && housingLandSupply && housingDeliverTest) {
    return {
      color: KeyIndicatorColor.RED,
      tag: "Unlikely to apply",
    };
    // Unknown: Data is missing for any of the three key datapoints.
  } else {
    return {
      color: KeyIndicatorColor.GRAY,
      tag: "Unknown",
    };
  }
};
