import { format, parseISO } from "date-fns";

export function protectedDateFormatter(date: string) {
  try {
    return format(parseISO(date), "do MMMM yyyy");
  } catch {
    return "";
  }
}

export function protectedSmallDateFormatter(date: string) {
  try {
    return format(parseISO(date), "dd/MM/yyyy");
  } catch {
    return "";
  }
}
