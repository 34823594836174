import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { UltimateOwner } from "../../typings/Company";

interface ScoreThresholds {
  [k: string]: { text: string; color: string };
}
const scoreThresholds: ScoreThresholds = {
  A: { text: ">= 95%", color: "#16A34A" },
  B: { text: "85% - 94%", color: "#65A30D" },
  C: { text: "75% - 84%", color: "#D97706" },
  D: { text: "60% - 74%", color: "#E11D48" },
  E: { text: "< 60%", color: "#610924" },
};

const QualityMatchLabel = ({ match_quality }: UltimateOwner) => {
  const { t } = useTranslation();

  if (!match_quality) return;

  return (
    <>
      <Tooltip.Wrapper>
        <Tooltip.Trigger>
          <div
            className="atlas-inline-flex atlas-h-5 atlas-w-5 atlas-rounded-full atlas-text-white atlas-text-xs atlas-items-center atlas-justify-center"
            style={{ backgroundColor: scoreThresholds[match_quality].color }}
          >
            {match_quality}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content align="end" side="bottom" theme={Theme.Dark} size="sm">
          {t("sidebar.ownership_sidebar.ultimate_owner.match_quality", {
            scoreThreshold: scoreThresholds[match_quality].text,
          })}
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </>
  );
};

type UltimateOwnerProps = {
  ultimateOwner?: UltimateOwner;
};

export const GlobalUltimateOwner = ({ ultimateOwner }: UltimateOwnerProps) => {
  const { t } = useTranslation();

  if (!ultimateOwner?.name) return null;

  return (
    <DisplayUltimateOwner
      title={t("ownership.react.map.sidebar.title_owner.GUO")}
      name={ultimateOwner.name}
      match_quality={ultimateOwner.match_quality}
    />
  );
};

export const DomesticUltimateOwner = ({ ultimateOwner }: UltimateOwnerProps) => {
  const { t } = useTranslation();

  if (!ultimateOwner?.name) return null;

  return (
    <DisplayUltimateOwner
      title={t("ownership.react.map.sidebar.title_owner.DUO")}
      name={ultimateOwner.name}
      match_quality={ultimateOwner.match_quality}
    />
  );
};

interface DisplayUltimateOwnerProps {
  title: string;
  name: string;
  match_quality?: string;
}

function DisplayUltimateOwner({ title, name, match_quality }: DisplayUltimateOwnerProps) {
  return (
    <div data-testid={title}>
      <div className="atlas-font-bold">{title}</div>
      <div className="atlas-flex atlas-gap-2">
        {name}
        {match_quality && <QualityMatchLabel match_quality={match_quality} />}
      </div>
    </div>
  );
}
