import { useMemo } from "react";
import { Sparkline, SparklineCategory } from "react-migration/components/Sparkline/Sparkline";
import { getCategoryFillColour } from "react-migration/domains/constraints/designation/style/accessors";
import { DesignationArea } from "./useFloodingSummaryQuery";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

interface FloodingSummarySectionProps {
  heading: string;
  areas?: DesignationArea[];
}

export function FloodingSummarySection({ heading, areas }: FloodingSummarySectionProps) {
  const translation = useTranslation();
  const categories = useMemo(
    () =>
      areas
        ?.slice(0)
        .reverse()
        .map((area) => designationAreaToSparklineCategory(area, translation)) || [],
    [areas, translation]
  );

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <h1 className="atlas-text-sm atlas-font-medium atlas-text-text-base-primary">{heading}</h1>
      <Sparkline showLegend categories={categories} />
    </section>
  );
}

function getCategoryFillColourRgb(sub_category: string) {
  return `rgb(${getCategoryFillColour(sub_category).slice(0, 3).join(",")})`;
}

function designationAreaToSparklineCategory(
  { sub_category, display_name, category_coverage_percentage }: DesignationArea,
  { t, checkKey }: ReturnType<typeof useTranslation>
): SparklineCategory {
  const translationKey = `workbench.summary.flooding.risk_level.${sub_category}`;

  return {
    categoryKey: sub_category,
    name: checkKey(translationKey) ? t(translationKey) : display_name,
    percentage: category_coverage_percentage,
    color: getCategoryFillColourRgb(sub_category),
  };
}
