import { useCallback } from "react";
import { Polygon } from "@turf/helpers";
import { Section } from "react-migration/components/Section";
import { Loading } from "react-migration/components/Loading";
import { SelectionType } from "src/js/stores/map/store";
import type { LayerTypeSelectionSidebarProps } from "../../../types";
import {
  UnitListItem,
  sortByAddress,
} from "react-migration/domains/ownership/components/USUnitList";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { ExpandibleList } from "react-migration/components/ExpandibleList/ExpandibleList";
import { Title } from "react-migration/domains/ownership/typings/Title";
import { useTitlesByLocationQuery } from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery";

/**
 * US Ownership components have no concept of direct selection via title or
 * parcel ID, just allow the first one to give saved sites the ability to view
 * underlying Ownership. This should be addressed when the US Ownership model is
 * addressed so the detail page is rendered via ID rather than a list of titles.
 * @param titles
 * @returns Title[]
 */
function filterTitles(titles: Title[], selectedTitleId: string | undefined) {
  return titles.filter(
    (title) => (!!title.title_no || !!title.parcel_identifier) && title.title_no !== selectedTitleId
  );
}

export function OwnershipSelectionSidebarUS({
  selection,
  setDetailSelection,
  layer,
  cardExpanded,
  onLoadingChange,
}: LayerTypeSelectionSidebarProps) {
  const { titlesByLocation, loading } = useTitlesByLocationQuery(
    selection.feature?.geometry as Polygon
  );

  useSelectionSidebarLoading(onLoadingChange, loading);

  const onSelection = useCallback(
    (unit?: Title) =>
      setDetailSelection({
        id: unit?.title_no,
        type: SelectionType.OWNERSHIP_TITLE,
      }),
    [setDetailSelection]
  );

  const filteredTitles = filterTitles(titlesByLocation, selection.id);

  if (!filteredTitles.length) return null;

  if (loading) {
    return (
      <div className="atlas-pt-4 atlas-pb-2">
        <Loading />
      </div>
    );
  }

  const sortedUnits = sortByAddress(filteredTitles);
  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      count={filteredTitles.length}
      defaultExpanded={cardExpanded}
    >
      <Section className="atlas-m-2 atlas-mt-0">
        <ExpandibleList limit={8} showCount itemsName="parcels">
          {sortedUnits.map((unit: Title) => (
            <UnitListItem key={unit.id} unit={unit} action={onSelection} />
          ))}
        </ExpandibleList>
      </Section>
    </CollapsibleConsiderationsCard>
  );
}
