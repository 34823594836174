import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import classNames from "classnames";

export type ProductDetailsProps = {
  unitPricePerLetter: string;
  quantity: string;
};

export const ProductDetails = ({ unitPricePerLetter, quantity }: ProductDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div data-testid="product-details">
      <span
        className={classNames(
          "atlas-font-semibold",
          "atlas-text-base",
          "atlas-text-text-base-primary"
        )}
      >
        {t("sites.letters.letter_summary")}
      </span>
      <div className={classNames("atlas-mt-4", "atlas-flex", "atlas-justify-between")}>
        <div className={classNames("atlas-flex", "atlas-font-medium")}>
          <span className="atlas-text-text-base-secondary">{quantity}x</span>

          <span className={classNames("atlas-ml-2", "atlas-text-text-base-primary")}>
            {t("sites.letters.standard_letter")}
          </span>
        </div>
        <span className={classNames("atlas-flex", "atlas-text-text-base-primary")}>
          {unitPricePerLetter}
        </span>
      </div>
    </div>
  );
};
