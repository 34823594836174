import {
  Duration,
  add,
  format,
  formatDistance,
  isAfter,
  isValid,
  parseISO,
  sub,
  toDate,
} from "date-fns";

export type Dateish = Date | string | number;
export type SupportedLocale = "en-US" | "en" | "en-GB";

/**
 * Throws on invalid date for two reasons
 * 1. because date-fns.format also throws on invalid dates
 * 2. because it prevents typescript errors from trying to pass undefined to date-fns.format
 * @param date
 * @returns
 */
export const validate = (date: Dateish) => {
  if (date instanceof Date) {
    if (isValid(date)) {
      return date;
    } else {
      console.log("validateDate: invalid date value");
      throw new RangeError("Invalid time value");
    }
  } else if (typeof date === "string") {
    if (isValid(parseISO(date))) {
      return parseISO(date);
    } else {
      console.log("validateDate: invalid string value");
      throw new RangeError("Invalid time value");
    }
  } else if (typeof date === "number") {
    if (isValid(toDate(date))) {
      return toDate(date);
    } else {
      console.log("validateDate: invalid number value");
      throw new RangeError("Invalid time value");
    }
  }
  throw new RangeError("Invalid time value");
};

export const DATEFORMAT = "dd/MM/yyyy";
export const SHORT_DATEFORMAT = "MM/yyyy";
export const YEAR_DATEFORMAT = "yyyy";
export const TIMEFORMAT = "HH:mm";

export const dateString = (date: Dateish, locale?: SupportedLocale) =>
  format(validate(date), getDateFormat(locale));
export const dateIsoString = (date: Dateish) => format(validate(date), "yyyy-MM-dd");

export const formattedDateRange = (date: Dateish) => format(validate(date), SHORT_DATEFORMAT);
export const formattedYearRange = (date: Dateish) =>
  Number(format(validate(date), YEAR_DATEFORMAT));
export const formatShortMonthAndYear = (date: Dateish) => format(validate(date), "MM/yy");
export const convertToYearsMonths = (years: number) => {
  const yearsPart = Math.floor(years);
  const monthsPart = Math.round((years - yearsPart) * 12);
  const formattedString =
    `${yearsPart} year${Number(yearsPart === 1) ? "" : "s"}` +
    (Number(monthsPart) > 0 ? ` ${monthsPart} months` : "");
  return { years: yearsPart, months: monthsPart, yearsString: formattedString };
};
export const timeString = (date: Dateish) => format(validate(date), TIMEFORMAT);
export const dateTimeString = (date: Dateish, locale?: SupportedLocale) =>
  format(validate(date), `${getDateFormat(locale)} ${TIMEFORMAT}`);

export const dateFullString = (date: Dateish) => format(validate(date), "MMMM do yyyy, h:mm:ss a");
export const dateSemiFullString = (date: Dateish) => format(validate(date), "dd MMMM yyyy");
export const dateShortMonthString = (date: Dateish) => format(validate(date), "dd MMM yyyy");

export const dateFileName = (date: Dateish) => format(validate(date), "yyyyMMddHHmmss");
export const mastermapDateFileName = (date: Dateish) => format(validate(date), "do MMM yy");

export const dateRelativeString = (date: Dateish) =>
  `${formatDistance(validate(date), Date.now())} ago`;

export const createDate = (dateToCreate?: Dateish) => {
  return dateToCreate ? new Date(dateToCreate) : new Date();
};

/**
 * Add time to a date
 * @param {Date} date the date to add to
 * @param {string} unit the units to add (years, months, weeks, days, hours, minutes, seconds)
 * @param {number} value the value to add
 * @returns A new Date object with the time added
 */
export const addTime = (date: Dateish, unit: keyof Duration, value: number) => {
  return add(validate(date), { [unit]: value });
};

/**
 * Subtract time from a date
 * @param {Date} date the date to subtract from
 * @param {string} unit the units to subtract (years, months, weeks, days, hours, minutes, seconds)
 * @param {number} value the value to subtract
 * @returns A new Date object with the time subtracted
 */
export const subtractTime = (date: Dateish, unit: keyof Duration, value: number) => {
  return sub(validate(date), { [unit]: value });
};

const getDateFormat = (locale?: SupportedLocale) => {
  switch (locale) {
    case "en-US":
      return "MM/dd/yyyy";
    case "en":
    case "en-GB":
    default:
      return "dd/MM/yyyy";
  }
};

export const getDatePickerFormat = (locale?: SupportedLocale) => {
  switch (locale) {
    case "en-US":
      return "mm/dd/yyyy";
    case "en":
    case "en-GB":
    default:
      return "dd/mm/yyyy";
  }
};

export const convertStringToDate = (date?: string | null) => {
  if (!date) {
    return new Date();
  }
  const [month, year] = date.split("/");
  return new Date(`${year}-${month}-01`);
};

export const isAfterDate = (date: Dateish, dateToCompare: Dateish) =>
  isAfter(validate(date), validate(dateToCompare));
