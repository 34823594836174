import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { SingleRecipient } from "./SingleRecipientValidationSchema";
import {
  LetterEventName,
  logLetterEvent,
  mapToLetterFormStepStringEnum,
} from "../../letters/helpers/logLetterEvent";
import { updateLetterName } from "src/js/stores/letter/actions";
import { TextInputV2 } from "react-migration/components/TextInputV2";
import { debounce } from "lodash";
import { letterStore } from "src/js/stores/letter/store";
import { useStageByStageId } from "../../pipeline/store/hooks/useStageByStageId";

type TableTitleProps = {
  recipients: SingleRecipient[] | null;
  selectedRecipientIds: string[] | null;
  selectedStageId: string | null;
};

export const TableTitle = ({
  recipients,
  selectedRecipientIds,
  selectedStageId,
}: TableTitleProps) => {
  const { t } = useTranslation();
  const stage = useStageByStageId(selectedStageId ?? "");
  const { currentStep, letterName } = useSnapshot(letterStore);

  const [letterTitle, setLetterTitle] = useState(letterName || t("sites.letters.untitled_letter"));

  const recipientsHeaderDetails = useMemo(() => {
    const sitesCountInfo =
      stage?._sites?.totalCount &&
      t("sites.letters.recipients.sites", {
        count: stage._sites.totalCount,
      });

    const eligibleSitesCountInfo = t("sites.letters.recipients.eligible_sites", {
      count: recipients?.filter((recipient) => recipient.isGranted).length,
    });

    const recipientsCountInfo = t("sites.letters.recipients.owners", {
      count: recipients?.length,
    });

    const selectedRecipientsInfo = t("sites.letters.recipients.selected", {
      count: selectedRecipientIds?.length,
    });

    return [
      stage && sitesCountInfo,
      stage && eligibleSitesCountInfo,
      recipientsCountInfo,
      selectedRecipientIds?.length && selectedRecipientsInfo,
    ]
      .filter(Boolean) // filtering out falsy values if letter creation is not bulk send
      .join(" \u2022 "); // adding a bullet point between the values
  }, [recipients, selectedRecipientIds, stage, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTitleChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setLetterTitle(value);
      updateLetterName(value);

      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_LETTER_TITLE_UPDATED,
        context: { step: mapToLetterFormStepStringEnum(currentStep) },
      });
    }, 500),
    [letterTitle]
  );

  return (
    <div
      className="atlas-grid atlas-grid-cols-3 atlas-flex-row atlas-p-4"
      data-testid="recipients-table-title"
    >
      <div className="atlas-flex atlas-flex-col atlas-gap-y-1">
        <span className="atlas-flex atlas-text-base atlas-font-semibold">
          {t("sites.letters.recipients")}
        </span>
        <span className="atlas-flex atlas-text-text-base-secondary">{recipientsHeaderDetails}</span>
      </div>
      <div className="atlas-flex atlas-grow atlas-justify-center atlas-items-center">
        <div className="atlas-min-w-96">
          <TextInputV2
            dataTestId="letters-header-letter-name-input"
            size="medium"
            placeholder={t("sites.letters.enter_letter_name")}
            IconLeft={<EditIcon />}
            value={letterTitle}
            onChange={onTitleChange}
          />
        </div>
      </div>
    </div>
  );
};

const EditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit-line" clipPath="url(#clip0_3757_15105)">
        <path
          id="Icon"
          d="M4.276 10.6674L11.0373 3.90604L10.0947 2.96337L3.33333 9.72471V10.6674H4.276ZM4.82867 12.0007H2V9.17204L9.62333 1.54871C9.74835 1.42373 9.91789 1.35352 10.0947 1.35352C10.2714 1.35352 10.441 1.42373 10.566 1.54871L12.452 3.43471C12.577 3.55973 12.6472 3.72926 12.6472 3.90604C12.6472 4.08282 12.577 4.25236 12.452 4.37737L4.82867 12.0007ZM2 13.334H14V14.6674H2V13.334Z"
          fill="#1F2937"
        />
      </g>
      <defs>
        <clipPath id="clip0_3757_15105">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
