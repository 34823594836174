import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";

export function RadioGroupIndicator({
  className,
  style,
  ...rest
}: RadixRadioGroup.RadioGroupIndicatorProps) {
  return (
    <RadixRadioGroup.Indicator
      {...rest}
      className={clsx(
        "atlas-flex",
        "atlas-grow",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-relative",
        "atlas-rounded-full",
        "-atlas-m-px",
        "atlas-bg-platform-600",
        "after:atlas-content-['']",
        "after:atlas-bg-white",
        "after:atlas-block",
        "after:atlas-w-[33%]",
        "after:atlas-h-[33%]",
        "after:atlas-rounded-full",
        className
      )}
      style={style}
    />
  );
}
