import { useMemo } from "react";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { mapStore } from "src/js/stores/map/store";

import { useSnapshot } from "valtio";
import { useDemographicsContext } from "./DemographicsProvider";
import { DemographicsLayerConfig } from "./DemographicsLayerType";
import { Layer } from "../../types";

export type SubCategoryCondition = {
  categoryId: ConstraintsCategory | null;
  alert?: string;
  maxZoom?: number;
};

export function useDemographicSubCategory(layer: Layer) {
  const { selectedAttribute } = useDemographicsContext();
  const layerConfig = layer.layerConfig as DemographicsLayerConfig;

  const selectedStyleOption = layerConfig.attributes_style_options.find(
    (a) => a?.attribute === selectedAttribute
  );

  const subCategoryConditions =
    selectedStyleOption?.customConditions || layerConfig.subCategoryConditions;

  const { settings } = useSnapshot(mapStore);

  return useMemo(() => {
    const matchCondition = subCategoryConditions.find(
      ({ maxZoom = Infinity }) => settings.zoom <= maxZoom
    );

    return { categoryId: matchCondition?.categoryId || null, alert: matchCondition?.alert };
  }, [settings.zoom, subCategoryConditions]);
}
