import { Icon } from "react-migration/components/Icon";
import { Button } from "../Button";

export interface PageControlsProps {
  page: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  disabled?: boolean;
}

export const PageControls = ({
  page,
  totalPages,
  disabled,
  onPreviousPage,
  onNextPage,
}: PageControlsProps) => {
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return (
    <div
      className="atlas-flex atlas-gap-x-1 atlas-items-center atlas-h-9 atlas-select-none"
      data-testid="page-controls"
    >
      <Button
        variant="ghost"
        onClick={onPreviousPage}
        disabled={disabled || isFirstPage}
        className="atlas-w-9"
      >
        <Icon size="md" icon="icon-lt-arrow-left-s-line" extraClasses="atlas-pointer-events-none" />
      </Button>
      <span className="atlas-text-sm atlas-text-blueGrey-950">
        {page}/{totalPages}
      </span>
      <Button
        variant="ghost"
        onClick={onNextPage}
        disabled={disabled || isLastPage}
        className="atlas-w-9"
      >
        <Icon
          size="md"
          icon="icon-lt-arrow-right-s-line"
          extraClasses="atlas-pointer-events-none"
        />
      </Button>
    </div>
  );
};
