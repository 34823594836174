import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as React from "react";
import clsx from "clsx";
import { useIsFirstRender, useDebounce } from "usehooks-ts";

type Value = string | number;

export type TextareaProps = Omit<React.HTMLAttributes<HTMLTextAreaElement>, "onChange"> & {
  placeholder: string;
  onChange: (value: Value) => void; // debounced
  defaultValue?: Value;
  updateValueIfDefaultChanges?: boolean;
  debounceValue?: number;
  className?: string;
  label?: string;
  labelClassName?: string;
  error?: string;
  "data-testid"?: string;
  fitHeightToContent?: boolean;
};

export function Textarea({
  placeholder,
  onChange,
  defaultValue,
  debounceValue,
  className: classNameExt,
  label,
  labelClassName,
  error,
  "data-testid": dataTestId,
  fitHeightToContent = false,
  updateValueIfDefaultChanges = true,
  ...textareaProps
}: TextareaProps) {
  const isFirstRender = useIsFirstRender();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState<Value>(defaultValue ?? "");
  const debouncedValue = useDebounce<Value>(value, debounceValue ?? 500);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  /**
   * Update value to defaultValue in case defaultValue is fetched async and this component
   * is rendered already
   */
  useEffect(() => {
    updateValueIfDefaultChanges && defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isFirstRender) return;
    onChange(value);
  }, [debouncedValue]);

  // Useful when printing and the whole content needs to be visible.
  useEffect(() => {
    if (!fitHeightToContent || !textAreaRef.current) return;

    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  }, [debouncedValue]);

  const className = clsx(
    "atlas-block",
    "atlas-w-full",
    "atlas-border",
    "atlas-rounded-md",
    "atlas-border-solid",
    !error && "atlas-border-neutral-300",
    !error && "hover:atlas-border-platform-600",
    "hover:atlas-outline-none",
    "focus:atlas-border",
    !error && "focus:atlas-border-platform-600",
    "focus:atlas-outline-none",
    "atlas-placeholder-neutral-400",
    "atlas-placeholder-text-sm",
    "atlas-py-2",
    "atlas-px-3",
    error && "atlas-border-red-700",
    classNameExt
  );

  return (
    <label className={clsx("atlas-relative", "atlas-w-full", error && "atlas-text-red-700")}>
      <span className={clsx("atlas-block", "atlas-mb-1", labelClassName)}>{label}</span>
      <textarea
        {...textareaProps}
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        className={className}
        placeholder={placeholder}
        rows={3}
        data-testid={`${dataTestId ?? "textarea-input"}`}
      />
      {error && (
        <span
          data-testid={`${dataTestId ?? "textare-input"}-error-message`}
          className="atlas-text-red-700 atlas-text-xs atlas-absolute atlas-right-0 -atlas-bottom-1"
        >
          {error}
        </span>
      )}
    </label>
  );
}
