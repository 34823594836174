import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { BundleId } from "src/js/stores/map/store";

export const NoDataPlanningSearchAlerts = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    router.push({
      name: ROUTES.LEGACY_SEARCH,
      query: {
        activeSearch: BundleId.PLANNING,
      },
    });
  };
  return (
    <span className="atlas-mt-2 atlas-text-base">
      {t("dashboard.planning_alerts.create_planning_alert")}
      <span
        onClick={handleClick}
        className="atlas-text-platform-600 atlas-cursor-pointer hover:atlas-underline"
      >
        {t("dashboard.planning_alerts.map_view")}.
      </span>
    </span>
  );
};
