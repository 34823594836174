import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Intercom } from "src/js/util/intercom";

export function PurchaseDisabled() {
  const { t } = useTranslation();
  return (
    <div data-testid="purchase-disabled" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.error.disabled.title")}</strong>
      <p>
        {t("title_purchase.error.disabled.message", {
          contactSupport: (
            <span className="atlas-cursor-pointer atlas-text-platform-600" onClick={openHelp}>
              {t("title_purchase.error.disabled.contact_support")}
            </span>
          ),
        })}
      </p>
    </div>
  );

  async function openHelp() {
    await logEvent("Open Intercom Widget", { from: "failed title purchase" });
    Intercom("show");
  }
}
