import { ButtonHTMLAttributes } from "react";
import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import clsx from "clsx";

export interface NavbarItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  anchorActive?: boolean;
  leadIcon?: `${LtIcons}`;
  tailIcon?: `${LtIcons}`;
  mobile?: boolean;
}

export const NavbarItem = ({
  active,
  anchorActive,
  children,
  leadIcon,
  tailIcon,
  mobile,
  ...rest
}: NavbarItemProps) => {
  if (!mobile) {
    return (
      <button
        {...rest}
        className={clsx(
          "atlas-relative atlas-group atlas-h-full atlas-items-center atlas-px-4 hover:atlas-bg-blueGrey-300 atlas-hidden md:atlas-flex",
          {
            "atlas-text-blueGrey-950": !active,
            "atlas-text-platformBlue-700": active,
            "atlas-bg-blueGrey-300": anchorActive,
          }
        )}
        aria-current={active}
      >
        {active && <Line />}
        {leadIcon && <NavbarItemIcon icon={leadIcon} />}
        <span className="atlas-p-1 atlas-hidden md:atlas-block">{children}</span>
        {tailIcon && <NavbarItemIcon icon={tailIcon} />}
      </button>
    );
  }
  return (
    <button
      {...rest}
      className={clsx(
        "atlas-flex atlas-items-center atlas-p-2 atlas-w-full atlas-border-t atlas-border-neutral-200 hover:atlas-bg-platform-100",
        active && "atlas-bg-platform-50"
      )}
      aria-current={active}
    >
      {leadIcon && <NavbarItemIcon icon={leadIcon} />}
      <span className="atlas-pl-2">{children}</span>
    </button>
  );
};

export const NavbarItemMobile = ({ active, children, leadIcon, ...rest }: NavbarItemProps) => {
  return (
    <button
      {...rest}
      className={clsx(
        "atlas-flex atlas-items-center atlas-p-2  atlas-w-full atlas-border-t atlas-border-neutral-200 hover:atlas-bg-platform-100",
        active && "atlas-bg-platform-50"
      )}
      aria-current={active}
    >
      {leadIcon && <NavbarItemIcon icon={leadIcon} />}
      <span className="atlas-pl-2">{children}</span>
    </button>
  );
};

interface NavbarItemIconProps {
  icon: `${LtIcons}`;
}

function NavbarItemIcon({ icon }: NavbarItemIconProps) {
  return (
    <i className={clsx("atlas-py-2 atlas-block md:atlas-hidden atlas-text-xl", `icon-${icon}`)} />
  );
}

function Line() {
  return (
    <div className="atlas-absolute atlas-bottom-0 atlas-w-[calc(100%-1rem)] atlas-h-1 atlas-rounded-t-lg atlas-left-1/2 atlas-transform -atlas-translate-x-1/2 atlas-ease-in-out atlas-duration-100 atlas-bg-platformBlue-700" />
  );
}
