import { PropsWithChildren, useCallback } from "react";
import { PlanningClassification, StatusDerived } from "../../types";
import { formatClassificationValue } from "../../utils/textFormatting";
import { ClassificationBadge } from "../ClassificationBadge";
import { StatusBadge } from "../StatusBadge";
import { CLASSIFICATION_COLS, STATUS_COLS } from "./constants";

export interface PlanningKeyStatsProps {
  classificationStats: Record<PlanningClassification, number>;
  statusStats: Record<StatusDerived, number>;
}

export function PlanningKeyStats({ classificationStats, statusStats }: PlanningKeyStatsProps) {
  const getClassificationCount = useCallback(
    (classification: PlanningClassification) => {
      if (classification === PlanningClassification.OTHER) {
        return (
          (classificationStats[PlanningClassification.OTHER] ?? 0) +
          (classificationStats[PlanningClassification.UNKNOWN] ?? 0)
        );
      }

      return classificationStats[classification] ?? 0;
    },
    [classificationStats]
  );

  return (
    <article className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <KeyStatCard>
        <div className="atlas-grid atlas-grid-cols-4 atlas-gap-1">
          {CLASSIFICATION_COLS.map((classification) => (
            <KeyStatItem
              key={classification}
              heading={formatClassificationValue(classification)}
              badge={
                <ClassificationBadge classification={classification as PlanningClassification} />
              }
              value={getClassificationCount(classification)}
            />
          ))}
        </div>
      </KeyStatCard>
      <KeyStatCard>
        <div className="atlas-grid atlas-grid-cols-5 atlas-gap-1">
          {STATUS_COLS.map((status) => (
            <KeyStatItem
              key={status}
              heading={status}
              value={statusStats[status]}
              badge={<StatusBadge status={status} />}
            />
          ))}
        </div>
      </KeyStatCard>
    </article>
  );
}

function KeyStatCard({ children }: PropsWithChildren) {
  return (
    <section className="atlas-p-4 atlas-border atlas-border-border-action atlas-rounded-xl atlas-bg-white">
      {children}
    </section>
  );
}

interface KeyStatItemProps {
  heading: string;
  badge: JSX.Element;
  value: string | number;
}

function KeyStatItem({ heading, badge, value }: KeyStatItemProps) {
  const valueDisplay = value ?? 0;

  return (
    <section className="atlas-flex atlas-flex-col atlas-items-start atlas-cursor-default atlas-select-none">
      <h1 className="atlas-text-xs atlas-font-normal atlas-text-text-base-secondary-a">
        {heading}
      </h1>
      <div className="atlas-flex atlas-items-center atlas-justify-start atlas-gap-x-1">
        {badge}
        <span
          className="atlas-text-xl atlas-font-medium atlas-text-text-base-primary"
          title={`${heading}: ${valueDisplay}`}
        >
          {valueDisplay}
        </span>
      </div>
    </section>
  );
}
