import * as React from "react";

import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";

import clsx from "clsx";
import { Theme } from "react-migration/lib/theme/Theme";

enum DisabledButtonBackgroundColor {
  Light = "disabled:!atlas-bg-neutral-300",
  Dark = "disabled:!atlas-bg-neutral-700",
}

enum DisabledElementTextContentColorOverride {
  Light = "disabled:!atlas-text-text-base-primary-disabled",
  Dark = "disabled:!atlas-text-text-base-primary-disabled",
}

enum DisabledElementBorderColorOverride {
  Light = "disabled:!atlas-border-border-button-disabled",
  Dark = "disabled:!atlas-border-border-action-disabled",
}

export type ButtonSize = "small" | "medium" | "large" | undefined;

type BaseProps = {
  size?: ButtonSize;
  theme?: Theme;
};

export type ButtonProps =
  | ({ buttonType: "Anchor" } & BaseProps & AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ buttonType?: "Button" } & BaseProps & ButtonHTMLAttributes<HTMLButtonElement>);

const disabledClassnames: Record<Theme, string> = {
  [Theme.Light]: `disabled:atlas-cursor-not-allowed ${DisabledButtonBackgroundColor[Theme.Light]} ${
    DisabledElementTextContentColorOverride[Theme.Light]
  } ${DisabledElementBorderColorOverride[Theme.Light]} disabled:!atlas-shadow-none !atlas-border-1`,
  [Theme.Dark]: `disabled:atlas-cursor-not-allowed ${DisabledButtonBackgroundColor[Theme.Dark]} ${
    DisabledElementTextContentColorOverride[Theme.Dark]
  } ${DisabledElementBorderColorOverride[Theme.Dark]} disabled:!atlas-shadow-none !atlas-border-1`,
};

const sharedClassnames =
  "atlas-inline-flex atlas-items-center atlas-justify-center atlas-font-semibold atlas-rounded atlas-shadow-sm atlas-cursor-pointer atlas-no-underline hover:atlas-no-underline atlas-self-center";

const primaryClassNames =
  "atlas-bg-background-button-primary hover:atlas-bg-background-button-primary-hover atlas-border-none atlas-text-white hover:atlas-text-white focus:atlas-shadow-[0_0_0-4px] focus:atlas-shadow-positive-500/25";
const dangerClassNames =
  "atlas-bg-negative-500 hover:atlas-bg-negative-700 atlas-border-none atlas-text-white hover:atlas-text-white focus:atlas-shadow-[0_0_0-4px] focus:atlas-shadow-negative-500/25";
const secondaryClassNames =
  "atlas-bg-background-surface atlas-border atlas-border-solid atlas-border-neutral-300  hover:atlas-bg-background-surface-neutral-subtle active:atlas-bg-background-surface-neutral-subtle atlas-text-text-base-primary hover:atlas-text-text-base-primary atlas-shadow-secondaryButton focus:atlas-shadow-[0_0_0-4px] focus:atlas-shadow-background-surface-neutral-subtle/25";
const borderlessClassNames: Record<Theme, string> = {
  [Theme.Light]: `atlas-bg-transparent disabled:!atlas-bg-transparent !atlas-shadow-none atlas-border-none atlas-text-text-base-active hover:atlas-text-text-base-active-hover disabled:!${
    DisabledElementTextContentColorOverride[Theme.Light]
  }`,
  [Theme.Dark]: `atlas-bg-transparent disabled:!atlas-bg-transparent !atlas-shadow-none atlas-border-none atlas-text-text-base-active hover:atlas-text-text-base-active-hover disabled:!${
    DisabledElementTextContentColorOverride[Theme.Dark]
  }`,
};

function Base({ size = "medium", theme = Theme.Light, className, ...props }: ButtonProps) {
  const classNames = clsx(
    sharedClassnames,
    disabledClassnames[theme],
    size === "small" && "atlas-px-3 atlas-h-8 atlas-text-xs !atlas-leading-3",
    size === "medium" && "atlas-px-4 atlas-h-9 atlas-text-sm !atlas-leading-4",
    size === "large" && "atlas-px-4 atlas-h-10 atlas-text-base !atlas-leading-4",
    className
  );

  if (props.buttonType === "Anchor") {
    const { buttonType: _, ...rest } = props;
    return <a type="button" {...rest} className={classNames} />;
  }

  const { buttonType: _, ...rest } = props;
  return <button {...rest} className={classNames} />;
}

const Primary: React.FC<ButtonProps> = ({ children, className, ...rest }) => (
  <Base className={clsx(primaryClassNames, className)} {...rest}>
    {children}
  </Base>
);

const Secondary: React.FC<ButtonProps> = ({ children, className, ...rest }) => (
  <Base className={clsx(secondaryClassNames, className)} {...rest}>
    {children}
  </Base>
);

const Danger: React.FC<ButtonProps> = ({ children, className, ...rest }) => (
  <Base className={clsx(dangerClassNames, className)} {...rest}>
    {children}
  </Base>
);

const Borderless: React.FC<ButtonProps> = ({
  children,
  className,
  theme = Theme.Light,
  ...rest
}) => (
  <Base className={clsx(borderlessClassNames[theme], className)} theme={theme} {...rest}>
    {children}
  </Base>
);

export const Button = {
  Primary,
  Secondary,
  Danger,
  Borderless,
  Base,
};

export default Button;
