import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { dateString } from "src/js/util/dates";

type NullishString = string | null | undefined;
type FormatEmergingPlanType = { emStatus?: NullishString; date?: NullishString };
type FormatEmergingPlanReturnType = {
  body: string;
  status?: InsightStatusType;
  footerValue: string;
};

export const formatEmergingPlan = ({
  emStatus,
  date,
}: FormatEmergingPlanType): FormatEmergingPlanReturnType => {
  if (date) {
    return { footerValue: dateString(new Date(date)), body: emStatus || "Unknown" };
  }
  return { footerValue: "-", body: "Unknown" };
};
