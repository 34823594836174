import type { PickingInfo } from "deck.gl";
import { Nullable } from "src/js/types/Nullable";
import UserFeature from "src/js/stores/user/Feature";
import type { ConstraintsLayer, ConstraintLayerConfig } from "./layer_types/ConstraintsLayerType";
import type { OwnershipLayer, OwnershipLayerConfig } from "./layer_types/OwnershipLayerType";

import { SelectionFeature } from "src/js/stores/map/store";
import { SitesLayer } from "./layer_types/SitesLayerType";
import { DemographicsLayer } from "./layers/demographicsLayerUS";
import { DemographicsLayerConfig } from "./layer_types/DemographicsLayerType/DemographicsLayerType";
import {
  PlanningApplicationsLayer,
  PlanningLayerConfig,
} from "./layer_types/PlanningApplicationsLayerType/types";

export type GenericLayer<LC = LayerConfig> = {
  id: string;
  title: string;
  description: string;
  zoomConfig?: LayerZoomConfig;
  layerType: LayerType;
  layerConfig?: LC;
  /**
   * Required licence features, if no access is given the layer will be shown to
   * upsell liceneses. All features must be present.
   */
  requiredFeatureAccess?: UserFeature[];
  /**
   * Required beta features, if no access is given the layer will be hidden. All
   * features must be present.
   */
  requiredBetaAccess?: UserFeature[];
  label?: LayerLabel;
  hideMapLayer?: boolean;
  mapAttributionText?: string;
  cardExpanded?: boolean;
  excludeFromReport?: boolean;
} & (
  | { toggleable: true; hideByDefault?: boolean; collapsed?: boolean }
  | { toggleable: false; hideByDefault?: false; collapsed?: false }
);

export type Layer =
  | OwnershipLayer
  | ConstraintsLayer
  | PlanningApplicationsLayer
  | SitesLayer
  | DemographicsLayer;

export type LayerConfig =
  | OwnershipLayerConfig
  | PlanningLayerConfig
  | ConstraintLayerConfig
  | DemographicsLayerConfig
  | undefined;

export interface LayerType {
  id?: string;
  Provider?: React.ComponentType<LayerTypeProviderProps>;
  ControlPage?: React.ComponentType<LayerTypeControlPageProps>;
  SelectionSidebar?: React.ComponentType<LayerTypeSelectionSidebarProps>;
  Printable?: React.ComponentType<LayerTypePrintableProps>;
  MapLayer?: React.ComponentType<LayerTypeMapLayerProps>;
  RightClickMenu?: React.ComponentType<LayerTypeRightClickMenuProps>;
  clickResolver?: ClickSelectionResolver;
  landAssemblyClickResolver?: ClickSelectionResolver;
  hideMapControls?: boolean;
  LayerSummary?: React.ComponentType<LayerTypeLayerSummaryProps> & { icon: string };
}

export interface DropdownMenuItemProps {
  selection: SelectionFeature;
}

export interface SelectionTitleProps {
  selection: SelectionFeature;
}

export interface LayerTypeSelectionMapLayerProps {
  selection: SelectionFeature;
}

export interface LayerTypeSelectionPrintableSummaryProps {
  selection: SelectionFeature;
  onLoaded(): void;
}

export interface SelectionHandler {
  SelectionHeader?: React.ComponentType<LayerTypeSelectionHeaderProps>;
  SelectionDetailView?: React.ComponentType<LayerTypeSelectionDetailViewProps>;
  SelectionMapLayer?: React.ComponentType<LayerTypeSelectionMapLayerProps>;
  SelectionPrintableSummary?: React.ComponentType<LayerTypeSelectionPrintableSummaryProps>;
  /** Component to render the Title above selection details. Note: Title in a
   * naming sense - not Ownership Title.  */
  SelectionTitle?: React.ComponentType<SelectionTitleProps>;
  selectionResolver?: SelectionResolver;

  /** This can be used to clear a selection if selection is outside of zoom range restriction. */
  useSelectionOutsideZoomRestriction?: UseOutsideZoomRestriction;
}

export type LayerTypeProviderProps = React.PropsWithChildren<{
  id: string;
  layers: Layer[];
  visibleLayerIds: string[];
  selection?: SelectionFeature;
  isWorkbench?: boolean;
  setSelection(selection: SelectionFeature | undefined): void;
  setDetailSelection(selection?: SelectionFeature): void;
}>;

export interface LayerTypeControlPageProps {
  layer: Layer;
  disabled: boolean;
}

export interface LayerTypeMapLayerProps {
  layer: Layer;
  zOrder: number;
  visible: boolean;
  selection: SelectionFeature | undefined;
  setSelection(selection: SelectionFeature | undefined): void;
  detailSelection: SelectionFeature | undefined;
  setDetailSelection(selection: SelectionFeature | undefined): void;
}

export interface LayerTypeRightClickMenuProps {
  layer: Layer;
  setSelection(selection?: SelectionFeature): void;
  setHoveredFeature(selection?: SelectionFeature): void;
  rightClickLocation: RightClickLocation;
}

export type LayerTypePrintableProps = Readonly<{
  selection: SelectionFeature;
  layer: Layer;
  onUnmount(): void;
  onLoaded(error?: string): void;
}>;

export interface LayerTypeSelectionHeaderProps {
  selection: SelectionFeature;
  setSelection(selection: SelectionFeature | undefined): void;
  /** If provided render selection header as link. */
  onHeaderClick?: (selection: SelectionFeature) => void;
}

export type LayerTypeSelectionSidebarProps = {
  selection: SelectionFeature;
  layer: Layer;
  cardExpanded: boolean;
  onSelection(selection: SelectionFeature | undefined): void;
  setDetailSelection(selection: SelectionFeature | undefined): void;
  onLoadingChange(value: boolean): void;
};

export type LayerTypeLayerSummaryProps = {
  selection: SelectionFeature;
  layer: Layer;
  onLoadingChange(value: boolean): void;
};

export type LayerTypeSelectionSidebarHeaderProps = {
  selection: SelectionFeature;
  layer: Layer;
  defaultTitle: string;
};

export interface LayerTypeSelectionDetailViewProps {
  selection: SelectionFeature;
  clearSelection(): void;
  isSummary: boolean;
  setDetailSelection?(selection: SelectionFeature | undefined): void;
  setSelection(selection?: SelectionFeature): void;
}

export type ClickSelectionResolver = (
  pickInfo: PickingInfo<unknown>
) => Nullable<Promise<Nullable<SelectionFeature>> | SelectionFeature>;

export type SelectionResolver = (id: string) => Promise<Nullable<SelectionFeature>>;

export type UseOutsideZoomRestriction = (selection: SelectionFeature) => boolean | "indeterminate";

export const LAYER_ID_SEPARATOR = "_+_";
type BrandedString<
  Type extends string,
  Separator extends string,
  Name extends string
> = `${Type}${Separator}${Name}`;

export type ConstraintsLayerId = BrandedString<string, typeof LAYER_ID_SEPARATOR, string>;

// TODO: Create & implement ClickResolver function type across all click resolvers

/** Note:
 * - Works in accordion. Impacts visualisation on Constraints layers only.
 */
export interface LayerZoomConfig {
  minVisualisationZoom?: number;
  maxVisualisationZoom?: number;
  minConsiderationZoom?: number;
  maxConsiderationZoom?: number;
}

export interface LayerLabel {
  translationKey: string;
  color: "BLUE";
}

export interface RightClickLocation {
  deckCoordinate: [x: number, y: number];
  screenCoordinate: [x: number, y: number];
  mapCoordinate: [lng: number, lat: number];
}
