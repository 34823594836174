import clsx from "clsx";
import { MouseEventHandler, ReactNode, forwardRef } from "react";

export enum TagColor {
  GREEN = "GREEN",
  GRAY = "GRAY",
  LIGHT_GRAY = "LIGHT_GRAY",
  RED = "RED",
  ORANGE = "ORANGE",
  PURPLE = "PURPLE",
  DEFAULT = "DEFAULT",
}

export interface TagProps {
  tag: ReactNode;
  color?: TagColor;
  title?: string;
  onClose?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  size?: "sm" | "md";
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ tag, title, color, className, onClose, size = "sm" }, tagRef) => (
    <div
      ref={tagRef}
      title={title}
      className={clsx(
        "atlas-inline-block",
        "atlas-font-medium atlas-leading-4",
        {
          "atlas-text-xs atlas-rounded atlas-px-1 atlas-py-0": size === "sm",
          "atlas-text-sm atlas-rounded-lg atlas-px-3 atlas-py-1.5": size === "md",
        },
        {
          "atlas-bg-background-badge-red atlas-text-text-accent-red": color === TagColor.RED,
          "atlas-bg-background-badge-orange atlas-text-text-accent-amber":
            color === TagColor.ORANGE,
          "atlas-bg-background-badge-green atlas-text-text-accent-green": color === TagColor.GREEN,
          "atlas-bg-background-badge-purple atlas-text-text-accent-brand-purple-primary":
            color === TagColor.PURPLE,
          "atlas-bg-background-badge-blue atlas-text-text-accent-blue": color === TagColor.DEFAULT,
          "atlas-bg-background-badge-grey-accent atlas-text-white": color === TagColor.GRAY,
          "atlas-bg-background-badge-grey atlas-text-text-base-secondary":
            color === TagColor.LIGHT_GRAY || !color,
        },
        className
      )}
    >
      <div className="atlas-flex atlas-text-nowrap">
        {tag}
        {onClose && (
          <div onClick={onClose} className="atlas-cursor-pointer" data-testid="remove-label">
            <i className="icon-lt-close-line atlas-ml-1" />
          </div>
        )}
      </div>
    </div>
  )
);

Tag.displayName = "Tag";

export { Tag };
