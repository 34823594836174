import { ConstraintLayerConfig } from "../types";
import { Layer, LayerTypePrintableProps } from "../../../types";
import { PrintableMapLayer } from "./ConstraintsPrintableMapLayer";
import { useCallback, useEffect, useId, useMemo, useReducer, useState } from "react";

import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";
import {
  DesignationSubTitle,
  DesignationTitle,
} from "react-migration/layouts/designations/Designation/Designation";
import {
  ConstraintsLayerTypeProvider,
  useConstraintsLayerTypeContext,
} from "../ConstraintsContext";
import { noop } from "lodash";
import { apiDesignationIsVisible } from "../ConstraintsContext/utils";

function ConstraintsPrintableInner({
  layer,
  selection,
  onLoaded,
  onUnmount,
}: LayerTypePrintableProps) {
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const layerStore = constraintsStore.layers[layer.id];

  const [mapRendered, setMapRendered] = useState(false);
  const [mapRenderingError, setMapRenderingError] = useState<string>();

  const onMapLoaded = useCallback((error?: string) => {
    setMapRendered(true);
    setMapRenderingError(error);
  }, []);

  const layerConfig = layer.layerConfig as ConstraintLayerConfig;

  const rootCategories = useMemo(
    () => layerConfig.layerRoots || [layerConfig.rootCategory],
    [layerConfig.layerRoots, layerConfig.rootCategory]
  );

  const { designations, loading: designationsLoading } = useDesignationsByGeometry({
    geometry: selection.feature?.geometry ?? null,
    rootCategories,
    /** This more closely matches the behaviour in the app - specifically for
     * datasets such as "grey belt". We walk the geometry in by a meter to
     * prevent false positives with neighbouring geospatial data.  */
    bufferMeters: -1,
  });

  const visibleDesignations = useMemo(
    () => designations.filter((designation) => apiDesignationIsVisible(designation, layerStore)),
    [designations, layerStore]
  );

  useEffect(() => {
    return () => {
      onUnmount?.();
    };
  }, [onUnmount]);

  useEffect(() => {
    if (
      !designationsLoading &&
      layerStore.layerConfigHash &&
      (mapRendered || !visibleDesignations.length)
    ) {
      onLoaded(mapRenderingError);
    }
  }, [
    designationsLoading,
    layer.id,
    layerStore.layerConfigHash,
    mapRendered,
    onLoaded,
    visibleDesignations.length,
  ]);

  if (!visibleDesignations.length) return null;

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-2 print:atlas-break-inside-avoid-page">
      <h2 className="atlas-text-base">{layer.title}</h2>
      <div className="atlas-flex">
        <div className="atlas-w-7/12">
          <div className="atlas-flex atlas-flex-col atlas-gap-1">
            {visibleDesignations.map((designation) => (
              <div key={designation.id}>
                <div className="atlas-flex atlas-items-start atlas-gap-2">
                  <DesignationHatch id={designation.sub_category_id} />
                  <div>
                    <DesignationTitle designation={designation} />
                    <DesignationSubTitle designation={designation} />
                    <p>{designation.sub_category?.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="atlas-w-5/12">
          <div className="atlas-relative atlas-aspect-square">
            <PrintableMapLayer
              id={layer.id}
              selection={selection}
              layerConfig={layerConfig}
              onLoaded={onMapLoaded}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/** Modify `layerProp.id` to prevent clashes on id. Noticed this occurring
 * across flooding & significant constraints both using `policy` id. */
function useLayer(layer: Layer) {
  const id = useId();
  return useMemo<Layer>(() => ({ ...layer, id: `${id}-${layer.id}` }), [id, layer]);
}

function withConstraintsContext(Component: React.ComponentType<LayerTypePrintableProps>) {
  return function ConstraintsContextWrapper({
    layer: layerProp,
    ...props
  }: LayerTypePrintableProps) {
    const layer = useLayer(layerProp);

    const [layers, visibleLayerIds] = useMemo(() => [[layer], [layer.id]], [layer]);

    return (
      <ConstraintsLayerTypeProvider
        id="CONSTRAINTS_PRINTABLE"
        setDetailSelection={noop}
        setSelection={noop}
        layers={layers}
        visibleLayerIds={visibleLayerIds}
      >
        <Component {...props} layer={layer} />
      </ConstraintsLayerTypeProvider>
    );
  };
}

export const ConstraintsPrintable = withConstraintsContext(ConstraintsPrintableInner);
