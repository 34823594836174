import clsx from "classnames";
/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";

import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

export type EditableTableCellProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  help?: string;
  helpTooltip?: string;
  className?: string;
  "data-testid"?: string;
  onEscPressed?: () => void;
  submit?: () => void;
};

export const EditableTableCell = React.forwardRef<HTMLInputElement, EditableTableCellProps>(
  (
    {
      label,
      className: classNameProps,
      "data-testid": dataTestId = "editable-table-cell",
      error,
      help,
      helpTooltip,
      onEscPressed,
      submit,
      disabled,
      ...inputProps
    },
    forwardedRef
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isEscPressed, setIsEscPressed] = useState(false);

    const inputRef = useRef<HTMLInputElement>();

    const className = clsx(
      "atlas-block",
      "atlas-w-full",
      "atlas-border",
      "atlas-whitespace-nowrap",
      "atlas-overflow-hidden",
      "atlas-text-ellipsis",
      "focus:atlas-rounded-md",
      "hover:atlas-rounded-md",
      "atlas-border-solid",
      "atlas-border-transparent",
      "atlas-outline-none",
      "hover:atlas-outline-none",
      "focus:atlas-outline-none",
      "atlas-placeholder-neutral-400",
      "atlas-placeholder-text-sm",
      "atlas-p-6",
      "focus:atlas-border-platform-600",
      "hover:atlas-border-platform-600",
      error && "atlas-text-red-700 atlas-bg-negative-500/10 hover:atlas-bg-negative-500/20",
      classNameProps
    );

    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape" && isFocused) {
          onEscPressed?.();
          setIsEscPressed(true);
          inputRef.current?.blur();
        }
        if (event.key === "Enter" && isFocused && submit) {
          inputRef.current?.blur();
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [onEscPressed, isFocused, submit]);

    useEffect(() => {
      const handleBlur = () => {
        if (isEscPressed) {
          setIsEscPressed(false);
          return;
        }
        if (!error && isFocused) {
          submit?.();
        }
      };

      inputRef.current?.addEventListener("blur", handleBlur);

      return () => {
        inputRef.current?.removeEventListener("blur", handleBlur);
      };
    }, [error, isEscPressed, isFocused, onEscPressed, submit]);

    const ref = (element: HTMLInputElement) => {
      inputRef.current = element;
      if (typeof forwardedRef === "function") {
        forwardedRef(element);
      } else if (forwardedRef && typeof forwardedRef === "object") {
        forwardedRef.current = element;
      }
    };

    return (
      <div className="atlas-relative">
        <input
          ref={ref}
          {...inputProps}
          className={className}
          data-testid={dataTestId}
          type="text"
          title={label}
          aria-label={label}
          onFocus={() => {
            !disabled && setIsFocused(true);
          }}
          onBlur={() => !disabled && setIsFocused(false)}
          disabled={disabled}
        />
        {Boolean(error) && (
          <span
            data-testid={`${dataTestId}-error-message`}
            className="atlas-mt-1 atlas-text-red-700 atlas-text-xs atlas-absolute atlas-left-6 atlas-bottom-2"
          >
            {error}
          </span>
        )}
        {!error && (help || helpTooltip) && (
          <div
            className={clsx(
              "atlas-mt-1",
              "atlas-text-xs",
              "atlas-absolute",
              "atlas-left-6",
              "atlas-right-2",
              "atlas-flex",
              "atlas-flex-row",
              "atlas-items-center",
              "atlas-gap-1",
              !help && "atlas-justify-end",
              "atlas-bottom-1",
              "atlas-pointer-events-none"
            )}
          >
            {help && (
              <span
                className={clsx(
                  "atlas-overflow-hidden",
                  "atlas-text-ellipsis",
                  "atlas-whitespace-nowrap"
                )}
                data-testid={`${dataTestId}-help-message`}
              >
                {help}
              </span>
            )}
            {helpTooltip && (
              <Tooltip.Wrapper>
                <Tooltip.Trigger>
                  <i className="icon-lt-information-line atlas-text-lg atlas-cursor-pointer atlas-text-text-base-active" />
                </Tooltip.Trigger>
                <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
                  <span className="atlas-whitespace-normal">{helpTooltip}</span>
                </Tooltip.Content>
              </Tooltip.Wrapper>
            )}
          </div>
        )}
      </div>
    );
  }
);
