import { Button } from "react-migration/components/Button";
import { Tooltip } from "react-migration/components/Tooltip";
import { IconButton } from "react-migration/components/IconButton";
import { logEvent } from "react-migration/lib/util/logEvent";

export const UKQuickOptions = ({
  handleAmountValueChange,
}: {
  handleAmountValueChange: (v: number) => void;
}) => {
  const quickTopup = (v: number) => {
    logEvent("Top Up Quick", { amount: v });
    handleAmountValueChange(v);
  };

  return (
    <div className="atlas-mt-2 atlas-flex atlas-justify-between atlas-items-center atlas-pt-1">
      <Button variant="secondary" onClick={() => quickTopup(30)}>
        £30
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(50)}>
        £50
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(80)}>
        £80
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(200)}>
        £200
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(500)}>
        £500
      </Button>
      <Tooltip.Wrapper>
        <Tooltip.Trigger>
          <Button variant="ghost" leadIcon="lt-information-line" />
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="bottom" size="sm">
          <UKPriceTable />
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </div>
  );
};

const UKPriceTable = () => {
  return (
    <table className="atlas-border-separate atlas-p-2">
      <thead>
        <tr>
          <th>Item</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Letter</td>
          <td>£1.20</td>
        </tr>
        <tr>
          <td className="atlas-pr-3">Title Register / Plan</td>
          <td>£3</td>
        </tr>
        <tr>
          <td>Utility Report</td>
          <td>£720</td>
        </tr>
      </tbody>
    </table>
  );
};
