import { useCallback } from "react";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { SelectionType } from "src/js/stores/map/store";
import { useWorkbench } from "../../../Bundle/Workbench/WorkbenchContext";

export const usePlanningDetailSelectionHandler = () => {
  const { setDetailSelection } = useWorkbench();

  return useCallback(
    (application: PlanningApplication) =>
      setDetailSelection({
        type: SelectionType.PLANNING_APPLICATION,
        id: application.id,
      }),
    [setDetailSelection]
  );
};
