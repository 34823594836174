import { LayerTypePrintableProps } from "../../../types";
import { useMaxAreaSelectedElevationConstraint } from "../useMaxAreaSelectedElevationConstraint";
import { useTopographyStatsQuery } from "../useTopographyStatsQuery";
import { useEffect, useState } from "react";

export enum Sections {
  Elevation = "Elevation",
  Slope = "Slope",
  SlopeMap = "SlopeMap",
  Aspect = "Aspect",
  AspectMap = "AspectMap",
  HillshadeContour = "HillshadeContour",

  LoadingSections = "LoadingSections",
}

export type UseTopographyPrintDataResult = {
  sections: Sections[];
  loading: boolean;
  topographyData?: ReturnType<typeof useTopographyStatsQuery>["data"];
};

export function useTopographyPrintData({
  selection,
}: LayerTypePrintableProps): UseTopographyPrintDataResult {
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(selection);
  const selectionIsWithinAreaConstraint = !exceedsMaximumAreaConstraint;

  const {
    data: topographyData,
    error,
    loading,
  } = useTopographyStatsQuery(selection.feature?.geometry, {
    selectionIsWithinAreaConstraint,
  });

  const [sectionsToDisplay, setSectionsToDisplay] = useState([Sections.HillshadeContour]);

  useEffect(() => {
    if (topographyData?.elevation) {
      setSectionsToDisplay((sections) => [...sections, Sections.Elevation]);
      setSectionsToDisplay((sections) => [...sections, Sections.Slope, Sections.SlopeMap]);
      setSectionsToDisplay((sections) => [...sections, Sections.Aspect, Sections.AspectMap]);
    }
  }, [topographyData]);

  return {
    loading: loading,
    sections: sectionsToDisplay,
    topographyData,
  };
}
