import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { LayerTypeLayerSummaryProps } from "../../types";
import { useEffect, useMemo } from "react";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { useDesignationIntersections } from "./OutputAreaDetailView/useDesignationIntersectionsQuery";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { DesignationsComparisonChart } from "./OutputAreaDetailView/AttributeComparisonSummarySection";
import * as ATTRS from "./OutputAreaDetailView/OutputAreaAttributesUK";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { OutputAreaAttribute } from "./OutputAreaDetailView/types";
import { getAttributeNumericValue } from "./utils";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { useDemographicSubCategory } from "./useDemographicSubCategory";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";

type DemographicsLayerSummaryItemProps = {
  label: string;
  attribute: OutputAreaAttribute;
  designation: SingleDesignation;
  comparableDesignation?: SingleDesignation;
};

const DemographicsLayerSummaryItem = ({
  label,
  attribute,
  designation,
  comparableDesignation,
}: DemographicsLayerSummaryItemProps) => {
  const summaryNumericValue = getAttributeNumericValue(
    designation?.designation_attributes,
    attribute.attributeKey
  );

  useKeyIndicatorTags(
    (tags) => {
      return [
        ...tags,
        {
          tag:
            attribute.format && summaryNumericValue != null
              ? attribute.format(summaryNumericValue)
              : summaryNumericValue,
          id: attribute.attributeKey,
          color: KeyIndicatorColor.DEFAULT,
        },
      ];
    },
    [attribute, summaryNumericValue]
  );
  return (
    <div>
      <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-items-center atlas-gap-x-2 atlas-w-full atlas-my-2">
        <h1 className="atlas-shrink-0 atlas-text-sm atlas-text-neutral-800 atlas-font-semibold">
          {label}
        </h1>
      </div>
      <DesignationsComparisonChart
        attribute={attribute}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />
    </div>
  );
};

export const DemographicsLayerSummary = ({
  layer,
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const { categoryId } = useDemographicSubCategory(layer);

  const { designations, loading: designationsLoading } = useDesignationsByGeometry({
    geometry: selection.feature?.geometry || null,
    // TODO: Dumb to default here?
    rootCategories: [categoryId ?? ConstraintsCategory.UK_DEMOGRAPHICS_LSOA],
  });

  const { data: designationAreas, loading: designationAreasLoading } = useDesignationAreaByGeometry(
    selection.feature?.geometry || null,
    [categoryId ?? ConstraintsCategory.UK_DEMOGRAPHICS_LSOA],
    { skip: !designations || designations.length === 1 }
  );

  const selectedDesignation = useMemo<SingleDesignation | null>(() => {
    if (!designations) {
      return null;
    }
    if (designations.length === 1) {
      return designations[0];
    }

    const largest = designationAreas?.reduce((max, obj) =>
      obj.category_coverage_percentage > max.category_coverage_percentage ? obj : max
    );

    return designations.find((d) => d.id === largest?.id) ?? null;
  }, [designations, designationAreas]);

  const { designation, loading: designationLoading } = useDesignationById(selectedDesignation?.id);

  const { designations: intersectingDesignations, loading: intersectionsLoading } =
    useDesignationIntersections({
      id: selectedDesignation?.id,
      bufferMeters: -5,
      rootCategories: [ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY],
      skip: !selectedDesignation?.id || designationsLoading,
    });

  // TODO: Are we always comparing to the national level?
  const comparableDesignation = intersectingDesignations[0];

  const loading =
    designationsLoading || designationLoading || intersectionsLoading || designationAreasLoading;

  useEffect(() => {
    onLoadingChange(loading);
  }, [onLoadingChange, loading]);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[230px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (!designation) {
    throw new Error("Could not retrieve designations");
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-divide-y atlas-divide-solid atlas-divide-neutral-200">
      <DemographicsLayerSummaryItem
        label="Age"
        attribute={ATTRS.AGE_ATTRS[0]}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />

      <DemographicsLayerSummaryItem
        label="Income"
        attribute={ATTRS.INCOME_ATTRS[0]}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />

      <div className="atlas-pt-2">
        <span className="atlas-text-neutral-500 ">{designation.display_name}</span>
      </div>
    </div>
  );
};

DemographicsLayerSummary.icon = "icon-lt-line-chart-line";
