import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";

type NullishString = string | null | undefined;
type FormatHousingDeliverTestType = { measure?: NullishString; change?: NullishString };
type FormatHousingDeliverTestReturnType = {
  body: string;
  status?: InsightStatusType;
  footerValue: string;
};

export const formatHousingDeliverTest = ({
  measure,
  change,
}: FormatHousingDeliverTestType): FormatHousingDeliverTestReturnType => {
  return {
    footerValue: change || "-",
    body: measure ? `${Math.ceil(Number(measure) * 100)}%` : "Unknown",
    // Housing Delivery Test is under 75%
    status: measure ? (Number(measure) > 0.75 ? "red" : "green") : undefined,
  };
};
