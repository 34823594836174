import { LabelTag } from "react-migration/components/LabelTag";
import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { debounce } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

export interface ListSearchInputProps {
  value: string;
  onChange(searchInput: string): void;
  excludeValue?: boolean;
  setExcludeValue?: (value: boolean) => void;
}

const debouncedLogevent = debounce(
  (text: string) => logEventV2({ name: "Designations list filter", properties: { text } }),
  1000
);

export function ListSearchInput({
  value,
  onChange,
  excludeValue,
  setExcludeValue,
}: ListSearchInputProps) {
  const { t } = useTranslation();

  function handleFilterTextChange(text: string) {
    debouncedLogevent(text);
    onChange(text);
  }

  return (
    <div
      data-testid="list-search-input"
      className="atlas-w-full atlas-p-2 atlas-space-y-2 atlas-items-center atlas-sticky atlas-top-0 atlas-z-10 atlas-bg-white"
    >
      <div className="atlas-flex">
        <i className="icon-lt-search-line atlas-text-neutral-600 atlas-text-xl atlas-mr-2" />
        <input
          type="text"
          max={24}
          placeholder={t("domains.constraints.components.designation.filter_placeholder")}
          value={value}
          onChange={(e) => handleFilterTextChange(e.target.value.replace(/(?![\w ])./g, ""))}
          pattern="\w"
          className="atlas-min-w-[65px] atlas-w-full atlas-bg-white atlas-peer atlas-font-sans atlas-text-sm atlas-leading-none atlas-rounded atlas-py-1.5 atlas-pl-2.5 atlas-pr-1 atlas-border atlas-border-neutral-300 focus:atlas-border-platform-500 placeholder:atlas-text-text-base-tertiary"
        />
        {!!value.length && (
          <button
            className="atlas-absolute atlas-right-1.5 atlas-pr-2 atlas-cursor-pointer atlas-text-neutral-400 focus:atlas-text-neutral-500 hover:atlas-text-neutral-500"
            onClick={() => onChange("")}
          >
            <i className="icon-lt-close-line atlas-text-xl" />
          </button>
        )}
      </div>
      {setExcludeValue && (
        <div className="atlas-flex atlas-justify-end atlas-w-full">
          <LabelTag className="atlas-italic" text="Exclude terms..." />
          <Checkbox
            value={excludeValue ? CheckboxState.CHECKED : CheckboxState.EMPTY}
            onChange={() => setExcludeValue(!excludeValue)}
          />
        </div>
      )}
    </div>
  );
}
