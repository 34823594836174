import { FC } from "react";

const isBoundNumber = (n?: number) => !isNaN(n!) && isFinite(n!);

interface CountPillProps {
  count?: number;
}

export const CountPill: FC<CountPillProps> = ({ count }) =>
  isBoundNumber(count) ? (
    <span className="atlas-inline-block atlas-px-1 atlas-rounded atlas-text-icon-base-secondary-a atlas-bg-background-badge-grey atlas-text-xs atlas-font-medium">
      {count}
    </span>
  ) : null;
