import { FC } from "react";
import { DetailsList } from "react-migration/components/DetailsList";
import { CompaniesHouseLink } from "./CompaniesHouseLink";
import { SearchOwnershipLink } from "./SearchOwnershipLink";
import { Company } from "react-migration/domains/ownership/typings/Company";

export interface CompanySectionProps {
  heading?: string;
  company: Partial<Pick<Company, "name" | "addresses" | "is_foreign" | "company_no">>;
  proprietorshipAdded?: Date;
}

export const CompanySection: FC<CompanySectionProps> = ({
  heading = "Company",
  proprietorshipAdded,
  company,
}) => (
  <div className="atlas-space-y-2 atlas-p-2">
    <div className="atlas-flex atlas-font-semibold atlas-text-sm atlas-text-text-base-tertiary atlas-mb-3 atlas-justify-between">
      <div>{heading}</div>
    </div>
    <DetailsList>
      {company.name && (
        <DetailsList.Item label="ownership.react.map.sidebar.company_details.name_label">
          {company.name} <CompaniesHouseLink query={company.name} />{" "}
          <SearchOwnershipLink {...company} />
        </DetailsList.Item>
      )}
      {company.addresses?.[0] && (
        <DetailsList.Item label="ownership.react.map.sidebar.company_details.address_label">
          {company.addresses?.[0]}
        </DetailsList.Item>
      )}

      {company.is_foreign && (
        <DetailsList.Item label="ownership.react.map.sidebar.company_details.is_foreign_label">
          {company.is_foreign}
        </DetailsList.Item>
      )}
      {company.company_no && (
        <DetailsList.Item label="ownership.react.map.sidebar.company_details.company_no_label">
          {company.company_no} <CompaniesHouseLink query={company.company_no} />{" "}
          <SearchOwnershipLink {...company} />
        </DetailsList.Item>
      )}
      {proprietorshipAdded && (
        <DetailsList.Item label="ownership.react.map.sidebar.company_details.last_ownership_change_label">
          {new Date(proprietorshipAdded.toString()).toLocaleDateString()}
        </DetailsList.Item>
      )}
    </DetailsList>
  </div>
);
