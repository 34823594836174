import { useMemo } from "react";
import { AttributeComparisonSummarySection } from "./AttributeComparisonSummarySection";
import { AttributeSummaryTable } from "./charts/AttributeSummaryTable";
import { AttributeComparisonSummaryProps } from "./types";

import * as ATTRS from "./OutputAreaAttributesUK";
import { HorizontalBarChart } from "./charts/HorizontalBarChart";
import { useDemographicsColourAccessor } from "./charts/colorUtils";
import { hasAnyRequiredAttribute } from "./utils";
import { NationalDensityComparisonChart } from "./charts/NationalDensityComparisonChart";
import { Alert } from "react-migration/components/Alert";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { LinearTrendChart } from "./charts/LinearTrendChart";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { isDefined } from "react-migration/lib/util/isDefined";

/* TODO:
- Resolve comments
- Align ordering + naming between map and attributes.
- Deprivation on map, but missing from attributes
- Property tenure in attributes, but missing from map.
*/

const POPULATION_GROWTH_DATA_POINTS = [
  {
    value: 0,
    xValue: 2024,
  },
  ATTRS.POPULATION_CHANGE_5YR,
  ATTRS.POPULATION_CHANGE_10YR,
];

const POPULATION_GROWTH_65_PLUS_DATA_POINTS = [
  {
    value: 0,
    xValue: 2024,
  },
  ATTRS.POPULATION_CHANGE_65_5YR,
  ATTRS.POPULATION_CHANGE_65_10YR,
];

export function AttributeComparisonSummaryUK({
  outputArea,
  comparableDesignation,
  allOutputAreas,
}: AttributeComparisonSummaryProps) {
  const colorAccessor = useDemographicsColourAccessor(outputArea, comparableDesignation);

  const sections = useMemo(
    () => ({
      affordability: hasAnyRequiredAttribute(ATTRS.AFFORDABILITY_ATTRS, outputArea),
      age: hasAnyRequiredAttribute(ATTRS.AGE_ATTRS, outputArea),
      dwellingAge: hasAnyRequiredAttribute(ATTRS.DWELLING_AGE_ATTRS, outputArea),
      dwellingDensity: hasAnyRequiredAttribute(ATTRS.DWELLING_DENSITY_ATTRS, outputArea),
      employment: hasAnyRequiredAttribute(ATTRS.EMPLOYMENT_ATTRS, outputArea),
      income: hasAnyRequiredAttribute(ATTRS.INCOME_ATTRS, outputArea),
      occupancy: hasAnyRequiredAttribute(ATTRS.OCCUPANCY_ATTRS, outputArea),
      populationDensity: hasAnyRequiredAttribute(ATTRS.POPULATION_DENSITY_ATTRS, outputArea),
      populationGrowth: hasAnyRequiredAttribute(ATTRS.POPULATION_CHANGE_ATTRS, outputArea),
      propertyTenure: hasAnyRequiredAttribute(ATTRS.PROPERTY_TENURE_ATTRS, outputArea),
    }),
    [outputArea]
  );

  const { countryDesignation } = useMemo(
    () => ({
      countryDesignation: allOutputAreas.find(
        (oa) => oa.sub_category_id === ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY
      )!,
    }),
    [allOutputAreas]
  );

  const populationGrowthOutputAreas = useMemo(
    () => [outputArea, comparableDesignation, countryDesignation].filter(isDefined),
    [outputArea, comparableDesignation, countryDesignation]
  );

  const affordabilityOutputAreas = useMemo(
    () => [outputArea, comparableDesignation].filter(isDefined),
    [outputArea, comparableDesignation]
  );

  return (
    <>
      {sections.age && (
        <AttributeComparisonSummarySection
          heading="Age"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.AGE_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.AGE_ATTRS.slice(0, 2)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
          <HorizontalBarChart
            attributes={ATTRS.AGE_ATTRS.slice(2)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}
      {/* No data available above LSOA + MSOA*/}
      <AttributeComparisonSummarySection
        heading="Population Density"
        designation={outputArea}
        comparableDesignation={comparableDesignation}
        headlineAttribute={ATTRS.POPULATION_DENSITY_ATTRS[0]}
      >
        {sections.populationDensity && (
          <AttributeSummaryTable
            attributes={ATTRS.POPULATION_DENSITY_ATTRS.slice(0, 1)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        )}
        <NationalDensityComparisonChart
          chartedAttribute={ATTRS.POPULATION_DENSITY_ATTRS[0]}
          designation={outputArea}
          comparativeDesignation={comparableDesignation}
          countryDesignation={countryDesignation}
          countryAttributes={ATTRS.POPULATION_DENSITY_ATTRS.slice(1)}
          colorAccessor={colorAccessor}
        />
        {!sections.populationDensity && (
          <Alert.Info>Population density available for MSOA & LSOA only.</Alert.Info>
        )}
      </AttributeComparisonSummarySection>

      <AttributeComparisonSummarySection
        heading="Population Growth"
        designation={outputArea}
        comparableDesignation={comparableDesignation}
        headlineAttribute={ATTRS.POPULATION_CHANGE_ATTRS[0]}
      >
        <AttributeSummaryTable
          attributes={ATTRS.POPULATION_CHANGE_ATTRS.slice(0, 2)}
          designation={outputArea}
          comparativeDesignation={comparableDesignation}
          colorAccessor={colorAccessor}
        />
        <LinearTrendChart
          dataPoints={POPULATION_GROWTH_DATA_POINTS}
          outputAreas={populationGrowthOutputAreas}
          labelY="Change (%)"
          colorAccessor={colorAccessor}
        />

        <AttributeSummaryTable
          attributes={ATTRS.POPULATION_CHANGE_ATTRS.slice(2)}
          designation={outputArea}
          comparativeDesignation={comparableDesignation}
          colorAccessor={colorAccessor}
        />
        <LinearTrendChart
          dataPoints={POPULATION_GROWTH_65_PLUS_DATA_POINTS}
          outputAreas={populationGrowthOutputAreas}
          labelY="Change (%)"
          colorAccessor={colorAccessor}
        />
        {!sections.populationGrowth && (
          <Alert.Info>Population growth not available for MSOA or LSOA.</Alert.Info>
        )}
      </AttributeComparisonSummarySection>
      {/* No data available for LSOA */}
      {sections.income && (
        <AttributeComparisonSummarySection
          heading="Income"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.INCOME_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.INCOME_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}
      <AttributeComparisonSummarySection
        heading="Affordability"
        designation={outputArea}
        comparableDesignation={comparableDesignation}
        headlineAttribute={ATTRS.AFFORDABILITY_ATTRS[0]}
      >
        {sections.affordability && (
          <AttributeSummaryTable
            attributes={ATTRS.AFFORDABILITY_ATTRS.slice(0, 1)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        )}
        <span className="atlas-text-2xs atlas-text-text-base-tertiary">
          Affordability ratios calculated by dividing house prices by gross annual workplace-based
          earnings.
        </span>
        <LinearTrendChart
          colorAccessor={colorAccessor}
          outputAreas={affordabilityOutputAreas}
          dataPoints={ATTRS.AFFORDABILITY_ATTRS}
          labelY="Affordability ratio"
        />
        {!sections.affordability && <Alert.Info>Affordability not available for LSOA.</Alert.Info>}
      </AttributeComparisonSummarySection>

      <DeprivationAttributeSummary
        allOutputAreas={allOutputAreas}
        outputArea={outputArea}
        comparableOutputArea={comparableDesignation}
      />
      {sections.employment && (
        <AttributeComparisonSummarySection
          heading="Employment"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.EMPLOYMENT_ATTRS[0]}
        >
          <HorizontalBarChart
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            attributes={[...ATTRS.EMPLOYMENT_ATTRS].reverse()}
            colorAccessor={colorAccessor}
          />
          <h2 className="atlas-text-xs atlas-font-semibold atlas-text-neutral-800">
            {"Occupation"}
          </h2>
          <HorizontalBarChart
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            attributes={[...ATTRS.OCCUPATION_ATTRS.slice(2)].reverse()}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      {sections.occupancy && (
        <AttributeComparisonSummarySection
          heading="Occupancy"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.OCCUPANCY_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.OCCUPANCY_ATTRS.slice(0, 1)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
          <HorizontalBarChart
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            attributes={ATTRS.OCCUPANCY_ATTRS.slice(1)}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      <AttributeComparisonSummarySection
        heading="Dwellings"
        designation={outputArea}
        comparableDesignation={comparableDesignation}
        headlineAttribute={ATTRS.DWELLING_DENSITY_ATTRS[1]}
      >
        {sections.dwellingDensity && (
          <AttributeSummaryTable
            attributes={ATTRS.DWELLING_DENSITY_ATTRS.slice(0, 2)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        )}
        <NationalDensityComparisonChart
          chartedAttribute={ATTRS.DWELLING_DENSITY_ATTRS[1]}
          designation={outputArea}
          comparativeDesignation={comparableDesignation}
          countryDesignation={countryDesignation}
          countryAttributes={ATTRS.DWELLING_DENSITY_ATTRS.slice(2)}
          colorAccessor={colorAccessor}
        />
        {!sections.dwellingDensity && (
          <Alert.Info>Dwelling density available for MSOA & LSOA only.</Alert.Info>
        )}
        <h2 className="atlas-text-xs atlas-font-semibold atlas-text-neutral-800">
          {"Dwelling types"}
        </h2>
        <HorizontalBarChart
          designation={outputArea}
          comparativeDesignation={comparableDesignation}
          attributes={[...ATTRS.DWELLING_TYPES_ATTRS].reverse()}
          colorAccessor={colorAccessor}
        />

        {sections.dwellingAge && (
          <AttributeSummaryTable
            attributes={ATTRS.DWELLING_AGE_ATTRS.slice(0, 3)}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        )}
      </AttributeComparisonSummarySection>

      {sections.propertyTenure && (
        <AttributeComparisonSummarySection
          heading="Property Tenure"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.PROPERTY_TENURE_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.PROPERTY_TENURE_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
          <HorizontalBarChart
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            attributes={[...ATTRS.PROPERTY_TENURE_ATTRS].reverse()}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}
    </>
  );
}

interface DeprivationAttributeSummaryProps {
  allOutputAreas: SingleDesignation[];
  outputArea: SingleDesignation;
  comparableOutputArea?: SingleDesignation;
}

const WALES_MAX_DEPRIVATION = 953 * 2;
const ENGLAND_MAX_DEPRIVATION = 16_418 * 2;

/**
 * Renders the summary of deprivation attributes for the output area.
 *
 * In the case of output areas in Wales, they need to be compared to Wales specifically,
 * rather than England and Wales. Output areas in England can be compared to England and Wales.
 *
 * @returns The JSX element representing the deprivation attribute summary.
 */
function DeprivationAttributeSummary({
  allOutputAreas,
  outputArea,
  comparableOutputArea: comparableOutputAreaProp,
}: DeprivationAttributeSummaryProps) {
  const { comparableOutputArea, maxValue } = useMemo(() => {
    const designationWales = allOutputAreas.find(
      (outputArea) =>
        outputArea.display_name === "Wales" &&
        outputArea.sub_category_id === ConstraintsCategory.UK_DEMOGRAPHICS_REGION
    );

    const isWelshOutputArea = outputArea?.key_code.startsWith("W");

    return {
      comparableOutputArea:
        comparableOutputAreaProp?.sub_category_id === ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY &&
        isWelshOutputArea
          ? designationWales
          : comparableOutputAreaProp,
      maxValue: isWelshOutputArea ? WALES_MAX_DEPRIVATION : ENGLAND_MAX_DEPRIVATION,
    };
  }, [allOutputAreas, comparableOutputAreaProp, outputArea?.key_code]);

  const colorAccessor = useDemographicsColourAccessor(outputArea, comparableOutputArea);

  const deprivationOutputAreas = useMemo(
    () => [outputArea, comparableOutputArea].filter(isDefined),
    [outputArea, comparableOutputArea]
  );

  return (
    <AttributeComparisonSummarySection
      heading="Deprivation"
      designation={outputArea}
      comparableDesignation={comparableOutputArea}
      headlineAttribute={ATTRS.DEPRIVATION_ATTRS[0]}
    >
      <AttributeSummaryTable
        attributes={ATTRS.DEPRIVATION_ATTRS.slice(0, 1)}
        designation={outputArea}
        comparativeDesignation={comparableOutputArea}
        colorAccessor={colorAccessor}
      />

      <LinearTrendChart
        colorAccessor={colorAccessor}
        outputAreas={deprivationOutputAreas}
        dataPoints={ATTRS.DEPRIVATION_ATTRS}
        labelY="IMD Rank"
        customRangeY={[1, maxValue]}
      />
      <span className="atlas-text-2xs atlas-text-text-base-tertiary">
        Derived from the Index of Multiple Deprivation - areas are ranked from the most deprived
        area (rank 1) to the least deprived area. A higher IMD Rank indicates lower deprivation.
      </span>
    </AttributeComparisonSummarySection>
  );
}
