import { CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { CollapsibleCheckboxTree } from "./useCollapsibleCheckboxTree";

interface ToggleAllButtonProps {
  disabled?: boolean;
  category: CollapsibleCheckboxTree;
}

export function ToggleAllButton({ disabled, category }: Readonly<ToggleAllButtonProps>) {
  const { t } = useTranslation();

  const allChecked = category.checkboxState === CheckboxState.CHECKED;

  return (
    <button
      className="atlas-text-platform-500 hover:atlas-text-platform-600 disabled:atlas-text-platform-300"
      disabled={disabled}
      data-testid="toggle-all"
      onClick={() => category.handleCheckboxChange(!allChecked)}
    >
      {allChecked
        ? t("components.checkbox_tree.deselect_all")
        : t("components.checkbox_tree.select_all")}
    </button>
  );
}
