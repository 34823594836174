import { useState, useEffect } from "react";
import classNames from "classnames";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import {
  LandTechEndpoints,
  routedClientWithoutTypenames,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { useQuery } from "@apollo/client";
import ACCOUNT from "react-migration/domains/sites/apollo/sites-api/queries/account.gql";
import { Loading } from "react-migration/components/Loading";
import { Banner } from "react-migration/domains/sites/components/Banner";
import { AccountResponseData } from "react-migration/domains/sites/typings/apollo";

interface CurrentBalanceSectionProps {
  onBalanceLoaded?: (balance: number) => void;
}

export const CurrentBalanceSection: React.FC<CurrentBalanceSectionProps> = ({
  onBalanceLoaded,
}) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);

  const [currentBalance, setCurrentBalance] = useState<string>();

  const {
    loading,
    error,
    data: accountData,
  } = useQuery<AccountResponseData>(ACCOUNT, {
    client: routedClientWithoutTypenames,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && accountData && accountData.account.balance >= 0) {
      const balance = accountData.account.balance;

      onBalanceLoaded?.(balance);

      const balanceInPounds = balance / 100;
      const amountWithCurrency = formatToCurrency({
        amount: balanceInPounds,
        currency: "GBP",
      });

      setCurrentBalance(amountWithCurrency);
    } else {
      setCurrentBalance("-");
    }
  }, [loading, accountData, usAccess, onBalanceLoaded]);

  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-flex-col",
        "atlas-p-4",
        "atlas-rounded-t-lg",
        "atlas-border-b",
        "atlas-border-border-action",
        "atlas-bg-background-surface-neutral"
      )}
      data-testid="letter-payment-summary-current-balance-wrapper"
    >
      <div className={classNames("atlas-flex", "atlas-justify-between", "atlas-items-center")}>
        <span
          className={classNames(
            "atlas-flex",
            "atlas-font-normal",
            error
              ? "atlas-text-background-surface-destructive-accent"
              : "atlas-text-text-base-secondary"
          )}
        >
          {t("sites.letters.your_balance")}
        </span>

        <div className="atlas-flex">
          {loading && <Loading />}

          {!loading && currentBalance && (
            <span
              className={
                error
                  ? "atlas-text-background-surface-destructive-accent"
                  : "atlas-text-text-base-primary"
              }
            >
              {currentBalance}
            </span>
          )}
        </div>
      </div>

      {error && (
        <Banner
          showIcon
          variant="error"
          message={t("sites.letters.we_cant_display_your_balance_at_the_moment")}
          className="atlas-flex atlas-mt-2"
        />
      )}
    </div>
  );
};
