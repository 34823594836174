import { useState } from "react";
import { DropdownMenu, DropdownMenuSize } from "react-migration/components/DropdownMenu";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Navbar } from "react-migration/domains/nav/components/Navbar/Navbar";

import { DashboardAnchorContent } from "./components/DashboardAnchorContent/DashboardAnchorContent";

export const DashboardAnchor = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deselect, setDeselect] = useState<boolean>(false);

  return (
    <DropdownMenu.Provider
      size={DropdownMenuSize.AUTO}
      offset={0}
      isOpen={isOpen}
      onMenuClosed={() => {
        setIsOpen(false);
        setDeselect(true);
        setTimeout(() => setDeselect(false), 300);
      }}
    >
      <DropdownMenu.Trigger
        data-testid="dashboard-trigger"
        align={DropdownMenu.TriggerAlignment["BOTTOM_LEFT"]}
        className="atlas-h-full"
        onClick={() => {
          if (!deselect) {
            logEvent("Anchor Opened", { tab: "dashboard" });
            setIsOpen(true);
          }
        }}
      >
        <Navbar.Item
          id="dashboard-navbar-item"
          data-testid="dashboard-navbar-item"
          leadIcon="lt-dashboard-2-line"
          tailIcon="lt-arrow-down-s-line"
          className="atlas-h-full"
          active={window.location.pathname.includes("/dashboard")}
          anchorActive={isOpen}
        >
          {t("nav.nav.dashboard")}
        </Navbar.Item>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="!atlas-rounded-t-none atlas-rounded-b-lg atlas-text-neutral-700 atlas-z-[99999]">
        {isOpen && <DashboardAnchorContent setIsOpen={setIsOpen} />}
      </DropdownMenu.Content>
    </DropdownMenu.Provider>
  );
};
