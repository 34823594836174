import { HTMLProps } from "react";

type FooterType = HTMLProps<HTMLDivElement> & { description: string };
export const Footer = ({ description, children }: FooterType) => {
  return (
    <div className="atlas-flex atlas-gap-1 atlas-text-xs">
      <div className="">{description}</div>
      <div className="atlas-text-text-base-secondary-a atlas-flex atlas-items-center">
        {children}
      </div>
    </div>
  );
};
