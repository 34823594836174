import { FC } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const RecordItemUnavailable: FC<{ source: "epc" | "zoopla" }> = ({ source }) => {
  const { t } = useTranslation();
  return (
    <div className="atlas-bg-background-surface-neutral-subtle atlas-py-5 atlas-m-2 atlas-flex atlas-items-center atlas-justify-center">
      <span className="atlas-text-sm atlas-text-text-base-secondary">
        {t(
          source === "zoopla"
            ? "comparables.map.sidebar.value.record.zoopla.source_entity_not_available"
            : "comparables.map.sidebar.value.record.epc.source_entity_not_available"
        )}
      </span>
    </div>
  );
};
