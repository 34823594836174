import { Shape } from "src/js/types/DrawingItem";

type SpecHeaderProps = {
  shape: Shape;
};
function shapeToIconClass(shape: Shape) {
  switch (shape) {
    case "MultiSelect":
      return "icon-lt-multi-select-line";
    case "Polygon":
      return "icon-lt-polygon-line";
    case "Polyline":
      return "icon-lt-line";
    case "Rectangle":
      return "icon-lt-square-line";
    case "Point":
      return "icon-lt-map-pin-range-line ";
    case "Ruler":
      return "icon-lt-ruler-line";
    case "Circle":
      return "icon-lt-circle-line";
    case "MultiPolygon":
      return "icon-lt-polygon-line";
    case "TextBox":
      return "icon-lt-text";
  }
}

export function SpecHeader({ shape }: SpecHeaderProps) {
  const iconClass = `${shapeToIconClass(shape)} atlas-text-xl`;
  return (
    <div className="atlas-flex">
      <div className="atlas-flex atlas-items-center atlas-gap-2">
        <div>
          <i className={iconClass} />
        </div>
        <div className="atlas-text-text-base-tertiary atlas-uppercase">{shape}</div>
      </div>
    </div>
  );
}
