import { noop } from "lodash";
import { createContext, useContext } from "react";
import { SelectionFeature } from "src/js/stores/map/store";
import { WorkbenchProps } from "./types";

export const defaultWorkbenchProps: WorkbenchProps = {
  selection: {} as SelectionFeature,
  detailSelection: undefined,
  setSelection: noop,
  setDetailSelection: noop,
  onClose: noop,
  onSiteSaved: noop,
};

export const WorkbenchContext = createContext<WorkbenchProps>(defaultWorkbenchProps);

export const useWorkbench = () => useContext(WorkbenchContext);
export const WorkbenchProvider = WorkbenchContext.Provider;
