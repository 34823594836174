import { Button } from "react-migration/components/Button";
import { Tooltip } from "react-migration/components/Tooltip";
import { logEvent } from "react-migration/lib/util/logEvent";

export const USQuickOptions = ({
  handleAmountValueChange,
}: {
  handleAmountValueChange: (v: number) => void;
}) => {
  const quickTopup = (v: number) => {
    logEvent("Top Up Quick", { amount: v });
    handleAmountValueChange(v);
  };
  return (
    <div className="atlas-mt-2 atlas-flex atlas-justify-between atlas-items-center atlas-pt-1">
      <Button variant="secondary" onClick={() => quickTopup(15)}>
        $15
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(30)}>
        $30
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(50)}>
        $50
      </Button>
      <Button variant="secondary" onClick={() => quickTopup(100)}>
        $100
      </Button>
      <Tooltip.Wrapper>
        <Tooltip.Trigger>
          <Button variant="ghost" leadIcon="lt-information-line" />
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="bottom" size="sm">
          <USPriceTable />
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </div>
  );
};

const USPriceTable = () => {
  return (
    <table className="atlas-border-separate atlas-p-2">
      <thead>
        <tr>
          <th>Item</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Letter</td>
          <td>$1.50</td>
        </tr>
      </tbody>
    </table>
  );
};
