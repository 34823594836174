import { useCallback, useMemo } from "react";
import { useSnapshot } from "valtio";
import type { Layer } from "../types";
import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";

export function useLayerAccess(layers: Layer[]) {
  const checkFeatureAccess = useCheckFeatureAccess();

  return useMemo(() => {
    const accessible: Layer[] = [];
    const restrictedByBeta: Layer[] = [];
    const restrictedByFeature: Layer[] = [];

    for (const layer of layers) {
      const [hasAccess, hasBetaAccess] = checkFeatureAccess(layer);

      if (hasAccess) {
        accessible.push(layer);
      } else if (!hasBetaAccess) {
        restrictedByBeta.push(layer);
      } else {
        restrictedByFeature.push(layer);
      }
    }

    return [accessible, restrictedByFeature, restrictedByBeta] as const;
  }, [checkFeatureAccess, layers]);
}

export function useCheckLayerAccess() {
  const checkFeatureAccess = useCheckFeatureAccess();

  return useCallback(
    (layer: Layer) => {
      const [hasAccess] = checkFeatureAccess(layer);
      return hasAccess;
    },
    [checkFeatureAccess]
  );
}

function useCheckFeatureAccess() {
  const {
    user: { beta_features, features },
  } = useSnapshot(userStore);

  return useCallback(
    ({ requiredBetaAccess, requiredFeatureAccess }: Layer) =>
      checkFeatureAccess(features, beta_features, requiredFeatureAccess, requiredBetaAccess),
    [beta_features, features]
  );
}

function checkFeatureAccess(
  userFeatures: Feature[],
  betaFeatures: Feature[],
  requiredUserFeatures: Feature[] = [],
  requiredBetaFeatures: Feature[] = []
) {
  const hasBetaAccess = requiredBetaFeatures.every((feature) => betaFeatures.includes(feature));
  const hasFeatureAccess = requiredUserFeatures.every((feature) => userFeatures.includes(feature));
  const hasAccess = hasBetaAccess && hasFeatureAccess;

  return [hasAccess, hasBetaAccess, hasFeatureAccess] as const;
}
