import { color } from "d3-color";
import { scaleDivergingSqrt } from "d3-scale";
import { AspectCategory, AspectCategoryKey, SlopeCategory } from "./types";

export const MAX_AREA_CONSTRAINT_HECTARES = 500;

export const SELECTED_TOPOGRAPHY_LAYER_LOCAL_STORAGE_KEY = "selected-topography-layer";

export enum TopographyLayer {
  hillshade = "hillshade",
  contour = "contour",
  hillshadeContour = "hillshadeContour",
  slope = "slope",
  aspect = "aspect",
  aspectSlope = "aspectSlope",
}

export const DEFAULT_TOPOGRAPHY_LAYER = TopographyLayer.slope;

// 6.25% buffer around tiles to account for edge artifacts;
// so a 512px tile becomes 544px
export const SLOPE_TILE_BUFFER_SCALE_FACTOR = 1.0625;
export const ASPECT_TILE_BUFFER_SCALE_FACTOR = SLOPE_TILE_BUFFER_SCALE_FACTOR;
export const ASPECT_SLOPE_TILE_BUFFER_SCALE_FACTOR = SLOPE_TILE_BUFFER_SCALE_FACTOR;

export const SLOPE_CATEGORIES: SlopeCategory[] = [
  {
    categoryKey: "1: < 2",
    name: "Flat",
    color: "#fde725",
    description: "< 2°",
    colorMapRange: ["0%", 2],
  },
  {
    categoryKey: "2: 2-5",
    name: "Gentle",
    color: "#fca636",
    description: "2°-5°",
    colorMapRange: [2, 5],
  },
  {
    categoryKey: "3: 5-10",
    name: "Moderate",
    color: "#e16462",
    description: "5°-10°",
    colorMapRange: [5, 10],
  },
  {
    categoryKey: "4: 10-15",
    name: "Steep",
    color: "#b12a90",
    description: "10°-15°",
    colorMapRange: [10, 15],
  },
  {
    categoryKey: "5: 15-20",
    name: "Very steep",
    color: "#6a00a8",
    description: "15°-20°",
    colorMapRange: [15, 20],
  },
  {
    categoryKey: "6: > 20",
    name: "Prohibitively Steep",
    color: "#0d0887",
    description: "> 20°",
    colorMapRange: [20, "100%"],
  },
];

export const SLOPE_COLOR_MAP = SLOPE_CATEGORIES.slice()
  .reverse()
  .flatMap((category) =>
    category.colorMapRange
      .slice()
      .reverse()
      .map((rangeValue) => {
        const rgb = color(category.color)!.rgb();
        return `${rangeValue} ${rgb.r} ${rgb.g} ${rgb.b} 255`;
      })
  )
  .concat("nv 0 0 0 0")
  .join("\n");

export const ASPECT_CATEGORIES: AspectCategory[] = [
  {
    categoryKey: AspectCategoryKey.Flat,
    label: "Flat",
    color: "#DBDBDB",
    colorMapRanges: [],
  },
  {
    categoryKey: AspectCategoryKey.N,
    label: "North",
    color: "#91509A",
    colorMapRanges: [
      [0, 22.5],
      [337.5, 359.5],
    ],
  },
  {
    categoryKey: AspectCategoryKey.NE,
    label: "North East",
    color: "#411E9B",
    colorMapRanges: [[22.5, 67.5]],
  },
  {
    categoryKey: AspectCategoryKey.E,
    label: "East",
    color: "#5D62BA",
    colorMapRanges: [[67.5, 112.5]],
  },
  {
    categoryKey: AspectCategoryKey.SE,
    label: "South East",
    color: "#72A957",
    colorMapRanges: [[112.5, 157.5]],
  },
  {
    categoryKey: AspectCategoryKey.S,
    label: "South",
    color: "#A5DB54",
    colorMapRanges: [[157.5, 202.5]],
  },
  {
    categoryKey: AspectCategoryKey.SW,
    label: "South west",
    color: "#F7DB29",
    colorMapRanges: [[202.5, 247.5]],
  },
  {
    categoryKey: AspectCategoryKey.W,
    label: "West",
    color: "#F2AA22",
    colorMapRanges: [[247.5, 292.5]],
  },
  {
    categoryKey: AspectCategoryKey.NW,
    label: "North west",
    color: "#D35463",
    colorMapRanges: [[292.5, 337.5]],
  },
];

const ASPECT_FLAT_COLOR_RGB = color(ASPECT_CATEGORIES[0].color)!.rgb();
export const ASPECT_COLOR_MAP = ASPECT_CATEGORIES.slice()
  .reverse()
  .flatMap((category) =>
    category.colorMapRanges
      .slice()
      .reverse()
      .flatMap((ranges) =>
        ranges.map((rangeValue) => {
          const rgb = color(category.color)!.rgb();
          return `${rangeValue} ${rgb.r} ${rgb.g} ${rgb.b} 255`;
        })
      )
  )
  .concat(`nv ${ASPECT_FLAT_COLOR_RGB.r} ${ASPECT_FLAT_COLOR_RGB.g} ${ASPECT_FLAT_COLOR_RGB.b} 255`)
  .join("\n");

const ASPECT_SLOPE_RANGES = ASPECT_CATEGORIES.slice(1).map((aspect) => {
  const aspectRootColor = aspect.color;

  const baseColor = ASPECT_CATEGORIES[0].color;

  const directionColorIntensityScale = scaleDivergingSqrt<string>()
    .domain([0, SLOPE_CATEGORIES.length])
    .range([baseColor, aspectRootColor])
    .clamp(true);

  return SLOPE_CATEGORIES.map((_, slopeIndex) => {
    const directionColor = directionColorIntensityScale(slopeIndex);
    const rgb = color(directionColor)?.rgb();

    if (!rgb) {
      console.warn("Unable to map from directional color to rgb value");
      return color("#fff")!.rgb();
    }

    return rgb;
  });
});

export const ASPECT_SLOPE_COLOR_MAP = ASPECT_CATEGORIES.slice(1)
  .flatMap((aspect, aspectIndex) => {
    return SLOPE_CATEGORIES.map((slope, slopeIndex) => {
      const slopeRange = (slopeIndex + 1) * 10;
      const aspectRange = aspectIndex + 1;

      return {
        combinedIndex: slopeRange + aspectRange,
        aspect: aspect.label,
        aspectIndex: aspectIndex,
        slopeIndex: slopeIndex,
        slope: slope.name,
        color: ASPECT_SLOPE_RANGES[aspectIndex][slopeIndex].rgb(),
      };
    });
  })
  .sort((a, b) => b.combinedIndex - a.combinedIndex)
  .map(({ color, combinedIndex }) => `${combinedIndex} ${color.r} ${color.g} ${color.b} 255`)
  .concat("nv 0 0 0 0")
  .join("\n");
