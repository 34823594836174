import { feature } from "@turf/helpers";
import { useCallback, useEffect, useMemo } from "react";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { Nullable } from "src/js/types/Nullable";
import { SiteReportContent } from "./SiteReportContent";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { useReportLoadingState } from "./useReportLoadingState";
import { clearSiteSiteReportConfiguration } from "src/js/stores/site/actions/siteReportConfiguration";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { useSiteReportLogo } from "./useSiteReportLogo";
import { useSiteReportConfiguration } from "react-migration/lib/util/useSiteReportConfiguration";
import { area } from "@turf/turf";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Loading } from "react-migration/components/Loading";
import { Button } from "react-migration/components/Button";

interface SiteReportPrintWrapperProps {
  siteId: string;
  activeAssessmentLayers: string[];
  siteOptions: string[];
}

function usePrintLogging(siteId: string, assessmentState: string[]) {
  const { site } = useSiteById(siteId);
  const { logo } = useSiteReportLogo(siteId!);
  const [siteReportConfiguration] = useSiteReportConfiguration(siteId!);

  return useCallback(() => {
    logEventV2({
      name: "Site report downloaded",
      properties: {
        addedLogo: !!logo,
        compsRemoved: !!siteReportConfiguration?.excludeComparableIds?.length,
        planningRemoved: !!siteReportConfiguration?.excludePlanningApplicationIds?.length,
        sectionsToggled: assessmentState,
        siteAreaInMeters: site?.geometry ? +area(site?.geometry).toFixed(2) : 0,
        siteId,
      },
    });
  }, [
    assessmentState,
    logo,
    site?.geometry,
    siteId,
    siteReportConfiguration?.excludeComparableIds?.length,
    siteReportConfiguration?.excludePlanningApplicationIds?.length,
  ]);
}

export function SiteReportPrintWrapper({
  siteId,
  activeAssessmentLayers,
  siteOptions,
}: SiteReportPrintWrapperProps) {
  const { t } = useTranslation();
  const { hasLoaded, setHasLoaded } = useReportLoadingState();
  const { site } = useSiteById(siteId);
  const printLog = usePrintLogging(siteId, activeAssessmentLayers);

  const siteAsSelectionFeature = useMemo<Nullable<SelectionFeature>>(() => {
    if (!site?.geometry || !site?._id) return null;

    return { type: SelectionType.SITE, id: site._id, feature: feature(site?.geometry) };
  }, [site]);

  useEffect(() => {
    if (hasLoaded) window.print();
  }, [hasLoaded]);

  const closePrintWrapper = useCallback(() => {
    // TODO: This needs to be dynamic based on where site report has been open
    // from. i.e. Map or Site Card
    router.push({
      name: ROUTES.MAP,
    });
  }, []);

  useEffect(() => {
    function handleAfterPrint() {
      printLog();
      clearSiteSiteReportConfiguration(siteId);
      closePrintWrapper();
    }

    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, [closePrintWrapper, printLog, siteId]);

  if (!siteAsSelectionFeature) return null;

  return (
    <div className="atlas-h-full atlas-w-full">
      <div className="print:atlas-hidden atlas-z-50 atlas-flex atlas-flex-col atlas-gap-10 atlas-items-center atlas-justify-center atlas-fixed atlas-h-full atlas-w-full atlas-top-0 atlas-left-0 atlas-right-0 atlas-bottom-0 atlas-bg-blueGrey-50">
        <h1 className="atlas-text-xl atlas-font-normal">
          {t("sites.card.site_report.please_wait_while_the_report_is_being_prepared")}
        </h1>
        <Loading />
        <Button onClick={closePrintWrapper} variant="secondary">
          {t("sites.card.site_report.cancel")}
        </Button>
      </div>
      <div className="atlas-invisible print:atlas-visible print:atlas-w-full atlas-pr-0.5">
        <SiteReportContent
          selection={siteAsSelectionFeature}
          activeAssessmentLayers={activeAssessmentLayers}
          siteOptions={siteOptions}
          onReportLoaded={setHasLoaded}
        />
      </div>
    </div>
  );
}
