import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

export type DeprecatedModalProps = {
  title?: string;
  description?: string;
  children: ReactNode;
  titleClasses?: string;
  contentClasses?: string;
  onBackdropClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onContentClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export function DeprecatedModal({
  title,
  description,
  children,
  titleClasses,
  contentClasses,
  onContentClick,
  onBackdropClick,
}: DeprecatedModalProps) {
  return createPortal(
    <div data-testid="modal">
      <div
        className="atlas-data-[state=open]:animate-overlayShow atlas-fixed atlas-inset-0 atlas-z-50"
        onClick={onBackdropClick}
      />
      <div
        className={`atlas-data-[state=open]:animate-contentShow atlas-fixed atlas-top-[50%] atlas-left-[50%] atlas-max-h-[85vh] atlas-w-[90vw] atlas-max-w-[450px] atlas-translate-x-[-50%] atlas-translate-y-[-50%] atlas-rounded-[6px] atlas-bg-white atlas-p-[25px] atlas-shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] atlas-focus:outline-none atlas-z-[51] ${contentClasses}`}
        onClick={onContentClick}
      >
        {title && (
          <div className={`atlas-m-0 atlas-text-[17px] atlas-font-medium ${titleClasses}`}>
            {title}
          </div>
        )}
        {description && (
          <div className="atlas-pt-[10px] atlas-mb-5 atlas-text-[15px] atlas-leading-normal">
            {description}
          </div>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
}
