import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";

import { useOnClickOutside, useEventListener } from "usehooks-ts";

export type DropdownProps<T extends string = string> = {
  id?: string;
  label?: string;
  size?: keyof typeof DropdownSize;
  placeholder: string;
  value?: T;
  options: DropdownItem<T>[];
  disabled?: boolean;
  errorMessage?: string;
  dataTestId?: string;
  handleValueChange: (value: DropdownItem<T>) => void;
  name?: string;
  classNameContainer?: string;
};

export enum DropdownSize {
  small = "atlas-py-[7px]",
  medium = "atlas-py-[9px]",
  large = "atlas-py-[11px]",
}

export type DropdownItem<T extends string = string> = {
  label: string;
  value: T;
};

export const Dropdown = <T extends string = string>({
  id = "select-id",
  label,
  value,
  options,
  placeholder,
  size = "small",
  disabled,
  errorMessage,
  dataTestId = "li-react-dropdown",
  handleValueChange,
  name,
  classNameContainer,
}: DropdownProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<T | null>(value ?? null);
  useEffect(() => {
    setSelectedValue(value ?? null);
  }, [value]);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsDropdownOpen(false);
  });

  const documentRef = useRef(document);
  useEventListener(
    "keyup",
    (e) => {
      if (e.key === "Escape") setIsDropdownOpen(false);
    },
    documentRef
  );

  const dropdownClasses = clsx(
    "focus:atlas-outline atlas-bg-white focus:atlas-border-platform-600 active:atlas-outline active:atlas-border-platform-600 atlas-outline-1 atlas-outline-platform-600/[.10] atlas-px-3 atlas-border atlas-border-neutral-300 atlas-rounded atlas-border-solid atlas-flex atlas-justify-between atlas-text-sm atlas-leading-4",
    DropdownSize[size],
    {
      "atlas-bg-neutral-300 atlas-cursor-not-allowed": disabled,
      "atlas-border-red-700": errorMessage,
      "hover:atlas-border-neutral-500 atlas-cursor-pointer": !disabled,
    }
  );

  const iconClass = clsx({
    "icon-lt-arrow-up-s-line": isDropdownOpen,
    "icon-lt-arrow-down-s-line": !isDropdownOpen,
    "atlas-text-neutral-400": disabled,
  });

  const valueTextClass = clsx({
    "atlas-text-neutral-400": disabled || !selectedValue,
  });

  const handleOpenDropdown = () => {
    !disabled && setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (item: DropdownItem<T>) => {
    if (disabled) return;
    setIsDropdownOpen(false);
    if (!item) return;
    if (item?.value) {
      setSelectedValue(item.value);
    }
    handleValueChange(item);
  };

  const getLabelByValue = (val: string) => {
    return options.find((opt) => opt.value === val)?.label;
  };
  return (
    <div
      className={clsx("atlas-flex atlas-flex-col atlas-relative", classNameContainer)}
      data-testid={dataTestId}
    >
      {label && (
        <label
          htmlFor={id}
          className="atlas-text-xs atlas-leading-5 atlas-pb-2 atlas-m-0 atlas-font-semibold"
        >
          {label}
        </label>
      )}
      <div ref={ref}>
        <div
          onClick={handleOpenDropdown}
          data-testid={`${dataTestId}-trigger`}
          aria-disabled={disabled}
          className={dropdownClasses}
        >
          <input
            type="text"
            className="atlas-hidden"
            id={id}
            value={selectedValue ?? ""}
            name={name}
            readOnly
          />
          <div className={valueTextClass}>
            <div className="atlas-text-neutral-800">
              {selectedValue ? getLabelByValue(selectedValue) : placeholder}
            </div>
          </div>
          <div>
            <i className={iconClass}></i>
          </div>
        </div>
        {isDropdownOpen && (
          <div className="atlas-absolute atlas-right-0 atlas-left-0 atlas-z-50 atlas-max-h-[400px] atlas-overflow-y-auto atlas-bg-white atlas-animate-slide-down atlas-origin-top-right atlas-mt-[6px] atlas-p-2 atlas-border atlas-border-solid atlas-rounded atlas-border-neutral-300">
            <div>
              <div className="atlas-flex atlas-gap-1 atlas-flex-col">
                {options.map((item) => (
                  <div
                    data-value={item?.value || ""}
                    onClick={() => handleOptionClick(item)}
                    key={item?.value || ""}
                    className={clsx(
                      "atlas-rounded-[6px] atlas-p-2 atlas-text-neutral-800 focus-visible:atlas-outline-none atlas-cursor-pointer hover:atlas-bg-neutral-100 atlas-text-sm ",
                      { "atlas-bg-blue-100": item?.value === selectedValue }
                    )}
                  >
                    <div>{item.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {errorMessage && (
        <p className="atlas-text-xs atlas-leading-5 atlas-pt-1 atlas-m-0 atlas-font-normal atlas-text-red-700">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
