import { useMemo } from "react";
import clsx from "clsx";

export enum SizeClassName {
  XSMALL = "atlas-h-px",
  SMALL = "atlas-h-1",
  NORMAL = "atlas-h-1.5",
  LARGE = "atlas-h-2",
  MLARGE = "atlas-h-3",
  XLARGE = "atlas-h-4",
}

interface ProgressBarProps {
  className?: string;
  size?: SizeClassName;
  percentage?: number;
  rounded?: boolean;
}

export const ProgressBar = ({
  className,
  size = SizeClassName.SMALL,
  percentage,
  rounded,
}: ProgressBarProps) => {
  const progressBarWidth = useMemo(
    () => `${!!percentage && percentage > 0 && percentage <= 100 ? percentage : 0}%`,
    [percentage]
  );
  return (
    <div
      className={clsx("atlas-w-full", "atlas-bg-background-surface-neutral-subtle", size, {
        "atlas-rounded-[5px]": rounded,
      })}
    >
      <div
        className={clsx(className || "atlas-bg-positive-500", size)}
        style={{ width: progressBarWidth }}
        data-testid="progress-bar"
      />
    </div>
  );
};
