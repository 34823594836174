import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const US_SITE_PLAN_APPLICATIONS: Layer = {
  id: ConstraintsCategory.US_SITE_PLAN_APPLICATIONS,
  layerType: ConstraintsLayerType,
  requiredBetaAccess: [Feature.usSitePlanApplicationsLayer],
  hideByDefault: true,
  description: "Site plan applications in the local area",
  title: "Site Plan Applications",
  toggleable: true,
  label: { color: "BLUE", translationKey: "map.explore.label.trial" },
  layerConfig: {
    rootCategory: ConstraintsCategory.US_SITE_PLAN_APPLICATIONS,
    categorySchema: [
      { key: "us_spa_submitted" },
      { key: "us_spa_in_review" },
      { key: "us_spa_approved" },
      { key: "us_spa_approved_conditions" },
      { key: "us_spa_resubmit" },
      { key: "us_spa_insufficient" },
    ],
  },
};
