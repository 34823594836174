import { forwardRef, HTMLAttributes, HTMLProps, PropsWithChildren, ReactNode } from "react";
import cn from "clsx";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";
import { ClipboardCopyButton } from "../ClipboardCopy";

export interface GridProps extends PropsWithChildren {
  columns?: number;
}

function Grid({ children, columns = 2 }: GridProps) {
  return (
    <div
      className={cn("atlas-grid atlas-gap-2", {
        "atlas-grid-cols-1": columns === 1,
        "atlas-grid-cols-2": columns === 2,
        "atlas-grid-cols-3": columns === 3,
      })}
    >
      {children}
    </div>
  );
}

export const CellVariants = ["default", "ghost", "light"];
type CellVariant = (typeof CellVariants)[number];

interface CellProps extends HTMLProps<HTMLDivElement> {
  variant?: CellVariant;
  title?: string;
  titleTail?: ReactNode;
  tooltip?: string;
  copyString?: string;
}

function Cell({
  variant = "default",
  title,
  titleTail,
  children,
  copyString,
  tooltip,
  className,
}: CellProps) {
  return (
    <div
      className={cn(
        "atlas-relative atlas-group",
        variant === "default" && "atlas-bg-background-surface-neutral",
        variant === "light" && "atlas-bg-background-surface",
        variant !== "ghost" &&
          "atlas-px-2 atlas-py-1 atlas-overflow-hidden atlas-rounded atlas-border atlas-border-border-action",
        className
      )}
    >
      {title && (
        <div className="atlas-flex atlas-gap-1 atlas-justify-between atlas-items-center atlas-text-left">
          <div className="atlas-flex atlas-gap-1">
            <div className="atlas-text-text-base-secondary-a atlas-text-xs">{title}</div>
            {titleTail}
          </div>

          {tooltip && (
            <Tooltip.Wrapper>
              <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
                <Tooltip.Description>{tooltip}</Tooltip.Description>
              </Tooltip.Content>
              <Tooltip.Trigger>
                <i className="icon-lt-information-line print:atlas-hidden" />
              </Tooltip.Trigger>
            </Tooltip.Wrapper>
          )}
        </div>
      )}
      <div
        className={cn("atlas-text-left atlas-w-full atlas-text-sm atlas-font-medium", {
          "atlas-pr-10": !!copyString,
        })}
      >
        {children}
      </div>
      {copyString && (
        <div className="atlas-hidden group-hover:atlas-block atlas-absolute atlas-right-2 atlas-top-1/2 -atlas-translate-y-1/2">
          <ClipboardCopyButton text={copyString} roundedLeft roundedRight />
        </div>
      )}
    </div>
  );
}

function Badge({ children }: PropsWithChildren) {
  return (
    <div className="atlas-bg-blueGrey-200 atlas-text-blueGrey-950/50 atlas-text-xs atlas-rounded-sm atlas-px-1">
      {children}
    </div>
  );
}

const Button = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(
        "atlas-w-full atlas-text-left atlas-cursor-pointer atlas-text-platformBlue-600 hover:atlas-text-platformBlue-700 active:atlas-text-platformBlue-800",
        className
      )}
      {...props}
    />
  )
);

Button.displayName = "DataPanel.Button";

export const DataPanel = { Grid, Cell, Button, Tails: { Badge } };
