import { ParcelSizeRange } from "react-migration/layouts/map/Multilayer/layer_types/OwnershipLayerType/Context";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { area } from "src/js/util/units";

// ParcelSize Component
type ParcelSizeProps = {
  parcelSizeRange: ParcelSizeRange;
  unitType: string;
};

// ParcelSize Component Helper Functions
export function useParcelSizeText(parcelSizeRange: ParcelSizeRange, unitType: string) {
  const { t } = useTranslation();

  const rangeInMeters = {
    from: parcelSizeRange.from ? parcelSizeRange.from : undefined,
    to: parcelSizeRange.to && parcelSizeRange.to !== Infinity ? parcelSizeRange.to : undefined,
  };

  const formattedRange = area.format(rangeInMeters, {
    unitType: unitType,
    small: false,
    unit: true,
    range: true,
    rangeOpts: { noneText: t("map.toolbar.ownership_filters.no_filter_applied") },
  });

  return formattedRange;
}

// Parcel sizes should always be provided in metric units.
export function ParcelSize({ parcelSizeRange, unitType }: ParcelSizeProps) {
  const parcelSizeText = useParcelSizeText(parcelSizeRange, unitType);

  return (parcelSizeRange?.to && parcelSizeRange.to !== Infinity) || parcelSizeRange?.from ? (
    <span className="sub-title atlas-text-positive-600 atlas-font-medium atlas-select-none">
      {parcelSizeText}
    </span>
  ) : (
    <span className="atlas-mx-3 atlas-border-solid sub-title atlas-text-neutral-500 atlas-font-medium atlas-select-none">
      {parcelSizeText}
    </span>
  );
}
