import Feature from "src/js/stores/user/Feature";
import {
  ComparablesLayerSummaryType,
  ComparablesLayerType,
} from "../layer_types/ComparablesLayerType";
import { Layer } from "../types";

export const COMPARABLES_LAYER: Layer = {
  id: "COMPARABLES",
  layerType: ComparablesLayerType,
  title: "Comparables",
  requiredFeatureAccess: [Feature.pricepaidLayer],
  toggleable: true,
  description: "Explore sales prices",
};

export const COMPARABLES_LAYER_SUMMARY_UK: Layer = {
  id: "COMPARABLES_SUMMARY",
  layerType: ComparablesLayerSummaryType,
  title: "Latest transaction",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.pricepaidLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description: "The latest transaction in the selected area",
  hideByDefault: true,
};

export const COMPARABLES_LAYER_SUMMARY_US: Layer = {
  id: "COMPARABLES_SUMMARY",
  layerType: ComparablesLayerSummaryType,
  title: "Latest transaction",
  requiredFeatureAccess: [Feature.insightsSummary],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description: "The latest transaction in the selected area",
  hideByDefault: true,
};
