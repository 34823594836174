import { DesignationArea } from "../../FloodingLayerType/FloodingLayerSummary/useFloodingSummaryQuery";
import { GREY_BELT_CATEGORIES } from "./constants";
import * as lodash from "lodash";
import { Belts } from "./types";

const MINIMUM_OVERLAP_PERCENTAGE = 0.01; // 0.01% of 100%

export function getBeltClassifications(designations: DesignationArea[] | undefined): Belts | null {
  if (!designations) {
    return null;
  }

  const filteredDesignations = designations.filter(
    (d) => d.category_coverage_percentage > MINIMUM_OVERLAP_PERCENTAGE
  );

  const declassifiedGreenBelts = filteredDesignations.filter(
    (d) => d.sub_category === "declassified_green_belt"
  );

  const declassifiedGreenBeltPercentage = Math.round(
    lodash.sumBy(declassifiedGreenBelts, (greenBelt) => greenBelt.category_coverage_percentage)
  );

  const greenBelts = filteredDesignations.filter((d) => d.sub_category === "green_belt");
  const greenBeltPercentage = Math.round(
    lodash.sumBy(greenBelts, (greenBelt) => greenBelt.category_coverage_percentage)
  );

  const greyBeltDesignations = filteredDesignations.filter((d) =>
    GREY_BELT_CATEGORIES.includes(d.sub_category)
  );

  if (greyBeltDesignations.length === 0) {
    return {
      greenBeltPercentage,
      declassifiedGreenBeltPercentage,
      greyBeltPercentage: 0,
    };
  }

  const totalGreyBeltPercentage = Math.round(
    lodash.sumBy(greyBeltDesignations, (greyBeltDesignation) => {
      return greyBeltDesignation.category_coverage_percentage;
    })
  );

  return {
    greenBeltPercentage,
    declassifiedGreenBeltPercentage,
    greyBeltPercentage: totalGreyBeltPercentage,
  };
}
