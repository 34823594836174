import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";

export function RadioGroupItem({
  children,
  disabled,
  className,
  ...rest
}: RadixRadioGroup.RadioGroupItemProps) {
  return (
    <RadixRadioGroup.Item
      {...rest}
      className={clsx(
        className,
        "atlas-bg-background-input",
        "atlas-rounded-full",
        "atlas-w-7",
        "atlas-h-7",
        "atlas-flex",
        "atlas-items-stretch"
      )}
      disabled={disabled}
    >
      <div
        className={clsx(
          "atlas-flex",
          "atlas-rounded-full",
          "atlas-grow",
          "atlas-bg-white",
          "atlas-m-px"
        )}
      >
        {children}
      </div>
    </RadixRadioGroup.Item>
  );
}
