import { chain } from "lodash";
import { useMemo } from "react";
import { SingleDesignationDesignationAttribute } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import cn from "classnames";
import { useAttributeTranslation } from "react-migration/domains/constraints/hooks/useAttributeTranslation";
import { CATEGORY_ATTRIBUTES } from "react-migration/domains/constraints/designation/style/StyleMap";
import { AttributeDataTypeRender } from "react-migration/layouts/designations/Designation/Attributes/AttributeDataTypeRender";
import { DetailsList } from "react-migration/components/DetailsList";

interface AttributesProps {
  attributes: SingleDesignationDesignationAttribute[];
  categoryId: string;
}

export function Attributes({ attributes, categoryId }: AttributesProps) {
  const attributeGroups = useMemo(() => {
    const attributeGroups = chain(attributes)
      .filter((attribute) => !!attribute.value || !!attribute.child_designation_attributes?.length)
      .sortBy((attribute) => attribute.key)
      .groupBy((attribute) => attribute.designation_attribute_group?.id || "ungrouped")
      .map((attributes) => ({
        title: attributes[0].designation_attribute_group?.display_name || null,
        id: attributes[0].designation_attribute_group?.id || -1,
        attributes: attributes,
      }))
      .sortBy((group) => Number(group.id))
      .value();

    return attributeGroups;
  }, [attributes]);

  return (
    <>
      {attributeGroups.map(({ title, attributes, id }) => {
        const hasTitle = !!title;

        return (
          <div className="atlas-flex atlas-flex-col atlas-gap-2" key={id}>
            {hasTitle && (
              <h2 className="atlas-text-base atlas-font-semibold atlas-text-text-base-tertiary">
                {title}
              </h2>
            )}
            <div
              className={cn("atlas-flex atlas-flex-col atlas-gap-2", {
                "atlas-ml-3": hasTitle,
              })}
            >
              <DetailsList>
                {attributes.map((attribute) => (
                  <AttributeRecursive
                    key={attribute.id}
                    attribute={attribute}
                    categoryId={categoryId}
                  />
                ))}
              </DetailsList>
            </div>
          </div>
        );
      })}
    </>
  );
}

interface AttributeRecursiveProps {
  attribute: SingleDesignationDesignationAttribute;
  categoryId: string;
}

function AttributeRecursive({ attribute, categoryId }: AttributeRecursiveProps) {
  const translateKey = useAttributeTranslation();
  const attributeSchema = CATEGORY_ATTRIBUTES.find(
    (x) => x.id === attribute.key && x.categoryId === categoryId
  );

  return (
    <>
      <DetailsList.Item
        key={attribute.id}
        label={attributeSchema ? attributeSchema.displayName : translateKey(attribute.key)}
      >
        <AttributeDataTypeRender attribute={attribute} schema={attributeSchema} />
      </DetailsList.Item>
      {!!attribute.child_designation_attributes?.length && (
        <li>
          <DetailsList className="!atlas-ml-3">
            {attribute.child_designation_attributes.map((attribute) => (
              <AttributeRecursive
                key={attribute.id}
                attribute={attribute}
                categoryId={categoryId}
              />
            ))}
          </DetailsList>
        </li>
      )}
    </>
  );
}
