import clsx from "clsx";

interface TippyProps {
  variant: "ERROR";
}

export function Tippy({ variant }: TippyProps) {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1" clipPath="url(#clip0_2_2)">
        <g id="Tippy 2" clipPath="url(#clip1_2_2)">
          <path
            className={clsx({
              "atlas-fill-red-100 atlas-stroke-red-200": variant === "ERROR",
            })}
            d="M9.43755 4.33101L9.05112 4.76849L8.67835 4.31968L8.67707 4.31815L8.67121 4.31119L8.64553 4.28109C8.62229 4.25406 8.58698 4.21349 8.54034 4.16129C8.44704 4.05685 8.30865 3.90614 8.13125 3.7246C7.77586 3.3609 7.26695 2.87655 6.6531 2.3933C5.4065 1.41193 3.8091 0.50067 2.2236 0.50037L2.0112 0.50027L2.0034 0.50028L1.9954 0.50027C1.9864 0.50011 1.5 -0.00710986 1.9922 -0.49965L1.9983 -0.49971L2.0014 -0.49972L2.0104 -0.49973L2.0461 -0.49974L2.1883 -0.49978L3.5709 -0.49989L8.75093 -0.499999L14.0869 -0.499919L15.6644 -0.499799L15.9797 -0.499719L16.0006 -0.499699L16.0026 -0.499689L16.0157 -0.499449C16.5 0.00157126 16.0089 0.500231 16.0045 0.500291L16.0014 0.500311L15.9939 0.500321L15.9021 0.500371C14.4244 0.500891 12.8287 1.4085 11.5475 2.39655C10.9188 2.88141 10.3889 3.3674 10.0162 3.73245C9.83009 3.91469 9.6839 4.06605 9.58491 4.1711C9.53543 4.2236 9.49779 4.26448 9.47292 4.29179L9.4453 4.32232L9.4389 4.32949L9.43755 4.33101Z"
            fill="#FEE2E2"
            stroke="#FECACA"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_2">
          <rect width="18.5" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_2_2">
          <rect width="16" height="6" fill="white" transform="translate(17 6) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
}
