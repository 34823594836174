import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { InsightSummaryLearnMoreTooltip } from "react-migration/components/InsightSummaryLearnMoreTooltip";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps } from "../../../types";
import { formatHousingDeliverTest } from "./utils/formatHousingDeliverTest";
import { formatHousingLandSupply } from "./utils/formatHousingLandSupply";
import { presumptionInFavourTag } from "./utils/presumptionInFavourTag";
import { formatEmergingPlan } from "./utils/formatEmergingPlan";
import { formatAdoptedPlan } from "./utils/formatAdoptedPlan";
import { PlanningAuthoritySummarySection } from "./PlanningAuthoritySummarySection";

export function PlanningAuthorityLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const { designations, loading, error } = useDesignationsByGeometry({
    geometry: selection.feature?.geometry || null,
    bufferMeters: 0,
    rootCategories: ["local_planning_authority_outer"],
    attributeKeys: [
      "adpt_dat",
      "em_status",
      "em_pub",
      "5yhs",
      "5yhs_dat",
      "hdt21_meas",
      "new_dwelling_percentage_change_from_previous_calculation",
    ],
  });

  useSelectionSidebarLoading(onLoadingChange, loading);

  const filteredDesignations = designations.filter((d) => d.designation_attributes.length);

  const designationAttributes = filteredDesignations[0]?.designation_attributes || [];

  const adoptedPlan = formatAdoptedPlan({
    date: designationAttributes.find((attr) => attr.key === "adpt_dat")?.value,
  });
  const emergingPlan = formatEmergingPlan({
    emStatus: designationAttributes.find((attr) => attr.key === "em_status")?.value,
    date: designationAttributes.find((attr) => attr.key === "em_pub")?.value,
  });
  const housingLandSupply = formatHousingLandSupply({
    hsStatus: designationAttributes.find((attr) => attr.key === "5yhs")?.value,
    date: designationAttributes.find((attr) => attr.key === "5yhs_dat")?.value,
  });
  const housingDeliverTest = formatHousingDeliverTest({
    measure: designationAttributes.find((attr) => attr.key === "hdt21_meas")?.value,
    change: designationAttributes.find(
      (attr) => attr.key === "new_dwelling_percentage_change_from_previous_calculation"
    )?.value,
  });

  useKeyIndicatorTags(
    () => [
      presumptionInFavourTag({
        adoptedPlan: adoptedPlan.status,
        housingLandSupply: housingLandSupply.status,
        housingDeliverTest: housingDeliverTest.status,
      }),
    ],
    [adoptedPlan.status, housingDeliverTest.status, housingLandSupply.status]
  );

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <>
      {filteredDesignations.length > 1 && (
        <div className="atlas-flex">
          <h3 className="atlas-pb-4 atlas-pr-2 ">{filteredDesignations[0].display_name}</h3>
          <span className="atlas-text-text-base-tertiary">
            (Selection intersects {filteredDesignations.length} authorities)
          </span>
        </div>
      )}
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        <PlanningAuthoritySummarySection
          heading={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.adopted_plan.heading"
          )}
          tooltip={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.adopted_plan.tooltip"
          )}
          status={adoptedPlan.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.adopted_plan.footer_desc"
          )}
          footerValue={adoptedPlan.footerValue}
        >
          {adoptedPlan.body}
        </PlanningAuthoritySummarySection>
        <PlanningAuthoritySummarySection
          heading={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.emerging_plan.heading"
          )}
          footerDescription={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.emerging_plan.footer_desc"
          )}
          footerValue={emergingPlan.footerValue}
        >
          {emergingPlan.body}
        </PlanningAuthoritySummarySection>
        <PlanningAuthoritySummarySection
          heading={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_land_supply.heading"
          )}
          tooltip={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_land_supply.tooltip"
          )}
          status={housingLandSupply.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_land_supply.footer_desc"
          )}
          footerValue={housingLandSupply.footerValue}
        >
          {housingLandSupply.body}
        </PlanningAuthoritySummarySection>
        <PlanningAuthoritySummarySection
          heading={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_delivery_test.heading"
          )}
          tooltip={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_delivery_test.tooltip"
          )}
          status={housingDeliverTest.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.presumption_in_favour.housing_delivery_test.footer_desc"
          )}
          footerValue={housingDeliverTest.footerValue}
          footerArrowUp={housingDeliverTest.footerValue.charAt(0) !== "-"}
        >
          {housingDeliverTest.body}
        </PlanningAuthoritySummarySection>
      </div>
      <InsightSummaryLearnMoreTooltip>
        {t("workbench.summary.local_planning_authority.presumption_in_favour.tooltip")}
      </InsightSummaryLearnMoreTooltip>
    </>
  );
}

PlanningAuthorityLayerSummary.icon = "icon-lt-list-check";
