import { useTitleOwnerQuery } from "react-migration/domains/ownership/apollo/useTitleOwnerQuery";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LayerTypeSelectionHeaderProps } from "../../types";
import { GeometryIcon } from "react-migration/layouts/map/Ownership/icons";
import { CardTitle } from "react-migration/components/CardTitle";

export function OwnershipTitleSelectionHeader({
  selection,
}: Readonly<LayerTypeSelectionHeaderProps>) {
  const { t } = useTranslation();
  const { titleOwner } = useTitleOwnerQuery(selection?.id);

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2">
      <div>
        <GeometryIcon />
      </div>
      <div className="atlas-flex atlas-flex-col atlas-items-start atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary">
          {t("selection.ownership_title")}
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
          {titleOwner?.parcel_identifier}
        </div>
      </div>
    </div>
  );
}

export function OwnershipParcelSelectionHeader({
  selection,
}: Readonly<LayerTypeSelectionHeaderProps>) {
  const { t } = useTranslation();
  const { titleOwner, loading } = useTitleOwnerQuery(selection?.titleNumber);

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2">
      <div>
        <GeometryIcon />
      </div>
      <div className="atlas-flex atlas-flex-col atlas-items-start atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary">
          {t("selection.ownership_parcel_title")}
        </div>
        {!loading && (
          <div className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
            {titleOwner?.parcel_identifier
              ? t("selection.ownership_parcel_identifier", {
                  id: titleOwner?.parcel_identifier,
                })
              : t("selection.ownership_parcel_unknown")}
          </div>
        )}
      </div>
    </div>
  );
}

export function OwnerSelectionHeader({ selection }: Readonly<LayerTypeSelectionHeaderProps>) {
  const { t } = useTranslation();

  return (
    <div className="atlas-flex atlas-flex-col atlas-items-start">
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary">
          {t("selection.owner_title")}
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
          {selection?.id}
        </div>
      </div>
    </div>
  );
}

export function OwnerSelectionTitle() {
  return <CardTitle>Ownership</CardTitle>;
}
