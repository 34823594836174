import clsx from "clsx";
import * as React from "react";
import { ReactNode, useState } from "react";
import {
  AriaButtonProps,
  AriaNumberFieldProps,
  useButton,
  useLocale,
  useNumberField,
} from "react-aria";
import { useNumberFieldState } from "react-stately";
import { useOnClickOutside } from "usehooks-ts";
import { Theme } from "react-migration/lib/theme/Theme";

export type NumericInputSize = "small" | "medium" | "large";
enum DisabledTextContentColor {
  Light = "atlas-text-text-base-primary-disabled",
  Dark = "atlas-text-text-base-primary-disabled",
}

export type NumericInputProps = {
  size?: NumericInputSize;
  length?: number;
  className?: string;
  units?: string | ReactNode;
  unitsPrefix?: boolean;
  alwaysShowUnits?: boolean;
  theme?: Theme;
  hideSteppers?: boolean;
  errorMessage?: string;
} & AriaNumberFieldProps;

type StepperButtonProps = {
  className?: string;
} & AriaButtonProps;

const borderInput = "atlas-border-border-action";
const borderInputFocus = "atlas-border-border-action-focus";

const StepperButton: React.FC<StepperButtonProps> = (props) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={clsx(
        "atlas-flex atlas-items-center atlas-border-none atlas-h-1/2",
        props.className
      )}
    >
      {props.children}
    </button>
  );
};

export const NumericInput: React.FC<NumericInputProps> = (props) => {
  const theme = props.theme || Theme.Light;
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { labelProps, groupProps, inputProps, incrementButtonProps, decrementButtonProps } =
    useNumberField({ ...props, "aria-label": String(props.label) }, state, inputRef);
  const size = props.size || "medium";

  const divRef = React.useRef<HTMLDivElement>(null);
  const [borderColor, setBorderColor] = useState(borderInput);
  useOnClickOutside(divRef, () => setBorderColor(borderInput));

  const inputBaseClasses =
    "atlas-w-full atlas-peer atlas-font-sans atlas-text-sm atlas-leading-none atlas-p-1 atlas-outline-none placeholder:atlas-text-text-base-tertiary";

  const sizeClasses = {
    small: "atlas-py-1",
    medium: "atlas-py-2",
    large: "atlas-py-[10px]",
  };
  const inputSizeClass = sizeClasses[size] || "";
  const inputDisabledClass = props.isDisabled
    ? `atlas-text-text-base-primary-disabled-${theme === Theme.Light ? "light" : "dark"}`
    : "";
  const inputTextClass = theme === Theme.Light ? "atlas-text-black" : "atlas-text-white";

  const divBaseClasses =
    "atlas-rounded atlas-cursor-text atlas-flex atlas-relative peer-focus:atlas-shadow-inputFocus atlas-border atlas-border-solid focus:atlas-outline-none";
  const divDisabledClass = props.isDisabled
    ? `atlas-border-border-button-disabled-${theme === Theme.Light ? "light" : "dark"}`
    : borderColor;
  const divErrorClass = props.errorMessage ? "atlas-border atlas-border-red-700" : "";
  const divBackgroundClass = theme === Theme.Light ? "atlas-bg-white" : "atlas-bg-transparent";

  return (
    <div className={clsx("atlas-flex atlas-flex-col", props.className)}>
      <label
        className={`atlas-w-full ${
          props.isDisabled
            ? `atlas-text-text-base-primary-disabled-${theme === Theme.Light ? "light" : "dark"}`
            : ""
        }`}
        {...labelProps}
      >
        {props.label && (
          <div className="atlas-text-xs atlas-leading-5 atlas-mb-2 atlas-font-semibold">
            {props.label}
          </div>
        )}

        <div
          ref={divRef}
          {...groupProps}
          className={clsx(divBaseClasses, divDisabledClass, divErrorClass, divBackgroundClass)}
        >
          {props.units && props.unitsPrefix && (
            <div
              onClick={() => inputRef.current?.focus()}
              className={`atlas-inset-0 atlas-min-w-fit atlas-justify-end atlas-overflow-hidden atlas-flex atlas-items-center atlas-text-sm atlas-leading-none peer-disabled:${DisabledTextContentColor[theme]}`}
            >
              <span className="flex-grow flex-shrink-0 atlas-pl-3 atlas-text-text-base-tertiary">
                {`${props.units} `}
              </span>
            </div>
          )}
          <input
            {...inputProps}
            ref={inputRef}
            size={props.length}
            min={0}
            onFocus={() => setBorderColor(borderInputFocus)}
            className={clsx(inputBaseClasses, inputSizeClass, inputDisabledClass, inputTextClass)}
          />
          {props.units && !props.unitsPrefix && (
            <div
              onClick={() => inputRef.current?.focus()}
              className={`atlas-inset-0 atlas-min-w-fit atlas-justify-end atlas-pr-1.5 atlas-overflow-hidden atlas-flex atlas-items-center atlas-text-sm atlas-leading-none peer-disabled:${DisabledTextContentColor[theme]}`}
            >
              <span
                className={clsx(
                  "flex-shrink-0 flex-grow text-right atlas-text-text-base-tertiary",
                  {
                    ["atlas-invisible"]: !state.inputValue && !props.alwaysShowUnits,
                  }
                )}
              >
                {` ${props.units}`}
              </span>
            </div>
          )}
          {!props.hideSteppers && (
            <span
              className={clsx(
                "atlas-flex atlas-min-w-[30px] atlas-flex-col atlas-ml-[-1px] atlas-border-l atlas-border-0 atlas-border-solid atlas-border-l-border-action atlas-h-[30px]",
                {
                  ["atlas-h-[30px]"]: size === "small",
                  ["atlas-h-[34px]"]: size === "medium",
                  ["atlas-h-[38px]"]: size === "large",
                }
              )}
            >
              <StepperButton
                {...incrementButtonProps}
                isDisabled={props.isDisabled || inputProps.value === props.maxValue ? true : false}
                className={clsx(
                  "atlas-justify-center atlas-border-t-0 atlas-rounded-br atlas-cursor-not-allowed atlas-backdrop-brightness-95",
                  {
                    ["hover:atlas-backdrop-brightness-90 atlas-cursor-pointer"]:
                      !incrementButtonProps.isDisabled,
                  }
                )}
              >
                <i className="atlas-text-xs atlas-leading-none icon-lt-arrow-up-s-line" />
              </StepperButton>
              <StepperButton
                {...decrementButtonProps}
                isDisabled={props.isDisabled || inputProps.value === props.minValue ? true : false}
                className={clsx(
                  "atlas-justify-center atlas-border-t-0 atlas-rounded-br atlas-cursor-not-allowed atlas-backdrop-brightness-95",
                  {
                    ["hover:atlas-backdrop-brightness-90 atlas-cursor-pointer"]:
                      !decrementButtonProps.isDisabled,
                  }
                )}
              >
                <i className="atlas-text-xs atlas-leading-none icon-lt-arrow-down-s-line" />
              </StepperButton>
            </span>
          )}
        </div>
      </label>
      {props.errorMessage && (
        <p className="atlas-text-xs atlas-leading-5 atlas-pt-1 atlas-m-0 atlas-font-normal atlas-text-red-700">
          {props.errorMessage}
        </p>
      )}
    </div>
  );
};

export default NumericInput;
