import { countBy } from "lodash";
import { useMemo } from "react";
import { PlanningKeyStats } from "react-migration/domains/planning/components/PlanningKeyStats";
import {
  PlanningApplication,
  PlanningClassification,
  StatusDerived,
} from "react-migration/domains/planning/types";

interface KeyStatsSectionProps {
  applications: PlanningApplication[];
}

export function KeyStatsSection({ applications }: KeyStatsSectionProps) {
  const stats = useMemo(
    () => ({
      classificationStats: countBy(applications, "classification") as Record<
        PlanningClassification,
        number
      >,
      statusStats: countBy(applications, "status_derived") as Record<StatusDerived, number>,
    }),
    [applications]
  );

  return <PlanningKeyStats {...stats} />;
}
