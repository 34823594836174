import { capitalize } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TagColor, Tag } from "react-migration/components/Tag";
import { PlanningColor, getAppealColor } from "../../utils/colors";
import { getAppealStatus } from "../../utils/getAppealStatus";
import { AppealDecision } from "../../types";

interface AppealTagProps {
  appealDecision: AppealDecision;
}

function getTagColor(appealColor?: PlanningColor) {
  if (appealColor === PlanningColor.RED) return TagColor.RED;
  if (appealColor === PlanningColor.ORANGE) return TagColor.ORANGE;
  if (appealColor === PlanningColor.GRAY) return TagColor.GRAY;
  if (appealColor === PlanningColor.GREEN) return TagColor.GREEN;

  return TagColor.DEFAULT;
}

export function AppealTag({ appealDecision }: AppealTagProps) {
  const { t } = useTranslation();

  return (
    <Tag
      tag={t("planning.appeal_decision", {
        appealDecision: capitalize(getAppealStatus(appealDecision)),
      })}
      color={getTagColor(getAppealColor(appealDecision))}
    />
  );
}
