import { useEffect, useMemo } from "react";
import { LayerTypePrintableProps } from "../../types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Table } from "react-migration/components/Table";
import meanBy from "lodash/meanBy";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import {
  measurementString,
  measurementValue,
  unitString,
} from "react-migration/lib/util/conversion";
import {
  Comparable,
  useComparablePointSearch,
} from "react-migration/domains/comparables/hooks/useComparablePointSearch";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { MeasurementSystem } from "src/js/stores/user/store";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import {
  EMPTY_IDS,
  useSiteReportConfigurationState,
} from "react-migration/lib/util/useSiteReportConfiguration";
import clsx from "clsx";
import { Checkbox } from "react-migration/components/Checkbox";

type PricedAndEstimatedAndFloorAreaComp = Omit<
  Comparable,
  "total_floor_area" | "price" | "est_price"
> & {
  total_floor_area: number;
  price: number;
  est_price: number;
};

const kFormat = (value: number) => {
  return Math.abs(value) > 999
    ? ((Math.sign(value) * Math.abs(value)) / 1000).toFixed(1) + "k"
    : (Math.sign(value) * Math.abs(value)).toFixed(1);
};

/** Comparables are identified via their address. */
function comparableIdentifier<T extends { address: string }>(comparable: T) {
  return comparable.address;
}

export function ComparablesPrintable({
  layer,
  selection,
  onLoaded,
  onUnmount,
}: LayerTypePrintableProps) {
  const { t } = useTranslation();
  const { site, loading: siteDataLoading } = useSiteById(selection.id);
  const { comparables, loading } = useComparablePointSearch(site?.location);
  const comparableIds = useMemo(
    () => comparables?.map(comparableIdentifier) ?? EMPTY_IDS,
    [comparables]
  );

  const { hiddenIds, allHidden, allVisible, toggleId, toggleAll } = useSiteReportConfigurationState(
    "excludeComparableIds",
    selection.id as string,
    comparableIds
  );

  useEffect(() => {
    if (!siteDataLoading && !loading) onLoaded();
  }, [loading, onLoaded, siteDataLoading]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  const isImperial = getMeasurementSystemPreference() === MeasurementSystem.IMPERIAL;
  const isUSUser = hasFeature(Feature.usAccess); // Fudge for £ vs $, ideally have a unitType on the row data itself.

  const avgSoldPrice = meanBy(
    comparables?.filter(
      (comparable) => comparable.price && comparable.total_floor_area
    ) as PricedAndEstimatedAndFloorAreaComp[],
    (comparable) =>
      comparable.price / measurementValue(comparable.total_floor_area, "m2", isImperial)
  );
  const avgMarketValue = meanBy(
    comparables?.filter(
      (comparable) => comparable.est_price && comparable.total_floor_area
    ) as PricedAndEstimatedAndFloorAreaComp[],
    (comparable) =>
      comparable.est_price / measurementValue(comparable.total_floor_area, "m2", isImperial)
  );

  if (!comparables?.length) return null;

  return (
    <div
      data-testid="comparables-report"
      className={clsx("atlas-mb-4", { "print:atlas-hidden": allHidden })}
    >
      <h2 className="atlas-text-base">{layer.title}</h2>
      <div className="atlas-mb-4">
        <div>
          {t("sites.card.site_report.comparables.avg_sold_price")} ({isUSUser ? "$" : "£"}/
          {unitString("m2", isImperial)}): {kFormat(avgSoldPrice)}
        </div>
        <div>
          {t("sites.card.site_report.comparables.avg_market_value")} ({isUSUser ? "$" : "£"}/
          {unitString("m2", isImperial)}): {kFormat(avgMarketValue)}
        </div>
      </div>
      <div className="atlas-mb-1 atlas-italic atlas-text-neutral-500">
        {t("sites.card.site_report.comparables.comparables_with_nr_of_site_boundary", {
          distance: measurementString(500, "m", isImperial),
        })}
      </div>

      <Table<Comparable, "address">
        size="small"
        primaryKey="address"
        rowClassName={(comparable) =>
          clsx({
            "!atlas-bg-red-50 print:atlas-hidden":
              !!comparable && hiddenIds.includes(comparableIdentifier(comparable)),
          })
        }
        columns={[
          {
            grow: true,
            label: (
              <div className="print:atlas-hidden">
                <Checkbox checked={allVisible} onCheckedChange={toggleAll} />
              </div>
            ),
            render: (comparable) => (
              <div className="print:atlas-hidden">
                <Checkbox
                  checked={!hiddenIds.includes(comparableIdentifier(comparable))}
                  onCheckedChange={() => toggleId(comparableIdentifier(comparable))}
                />
              </div>
            ),
          },
          {
            label: t("sites.card.site_report.comparables.address"),
            render: ({ address }) => (
              <span className="print:atlas-whitespace-pre-line print:!atlas-w-[250px]">
                {address}
              </span>
            ),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.date_sold"),
            render: (row) => new Date(row.date_of_transfer).toLocaleDateString(),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.sold_price", {
              price: isUSUser ? "$" : "£",
            }),
            render: ({ price }) => kFormat(price),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.est_market_value", {
              currency: isUSUser ? "$" : "£",
            }),
            render: ({ est_price }) => (est_price ? kFormat(est_price) : undefined),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.new_build"),
            render: ({ is_new_property }) => (is_new_property ? "Yes" : "No"),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.property_type"),
            rowKey: "category",
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.est_bedrooms"),
            render: ({ est_bedrooms }) => est_bedrooms?.join("-"),
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.floor_area", {
              area: unitString("m2", isImperial),
            }),
            render: ({ total_floor_area }) =>
              total_floor_area
                ? measurementString(total_floor_area, "m2", isImperial, false)
                : undefined,
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.sold_price", {
              price: `${isUSUser ? "$" : "£"}/${unitString("m2", isImperial)}`,
            }),
            render: ({ price, total_floor_area }) =>
              price && total_floor_area
                ? kFormat(price / measurementValue(total_floor_area, "m2", isImperial))
                : undefined,
            grow: true,
          },
          {
            label: t("sites.card.site_report.comparables.market_value", {
              value: `${isUSUser ? "$" : "£"}/${unitString("m2", isImperial)}`,
            }),
            render: ({ est_price, total_floor_area }) =>
              est_price && total_floor_area
                ? kFormat(est_price / measurementValue(total_floor_area, "m2", isImperial))
                : undefined,
            grow: true,
          },
          { label: t("sites.card.site_report.comparables.tenure"), rowKey: "tenure", grow: true },
        ]}
        rows={comparables}
        tableClassName="atlas-block atlas-overflow-x-scroll print:atlas-table print:atlas-table-fixed print:atlas-overflow-hidden"
      />
    </div>
  );
}
