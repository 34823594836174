import { useState, useEffect } from "react";
import classNames from "classnames";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Loading } from "react-migration/components/Loading";
import { Banner } from "react-migration/domains/sites/components/Banner";
import { useLetterCreditsQuery } from "@gql/li-api/graphql";

interface CurrentBalanceSectionProps {
  onBalanceLoaded?: (balance: number) => void;
}

export const CurrentBalanceCreditsSection: React.FC<CurrentBalanceSectionProps> = ({
  onBalanceLoaded,
}) => {
  const { t } = useTranslation();

  const [currentBalance, setCurrentBalance] = useState<string>();

  const {
    data: creditsResponse,
    loading,
    error,
  } = useLetterCreditsQuery({
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });

  useEffect(() => {
    if (loading) {
      setCurrentBalance("-");
    } else {
      const balance =
        (creditsResponse?.accountWithViews.included_letter_send?.cap ?? 0) -
        (creditsResponse?.accountWithViews.included_letter_send?.used ?? 0);
      onBalanceLoaded?.(balance);

      setCurrentBalance(
        t("sites.letters.nr_of_credits", {
          credit: balance,
        })
      );
    }
  }, [
    loading,
    onBalanceLoaded,
    t,
    creditsResponse?.accountWithViews.included_letter_send?.cap,
    creditsResponse?.accountWithViews.included_letter_send?.used,
  ]);

  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-flex-col",
        "atlas-p-4",
        "atlas-rounded-t-lg",
        "atlas-border-b",
        "atlas-border-border-action",
        "atlas-bg-background-surface-neutral"
      )}
      data-testid="letter-payment-summary-current-balance-wrapper"
    >
      <div className={classNames("atlas-flex", "atlas-justify-between", "atlas-items-center")}>
        <span
          className={classNames(
            "atlas-flex",
            "atlas-font-normal",
            error
              ? "atlas-text-background-surface-destructive-accent"
              : "atlas-text-text-base-secondary"
          )}
        >
          {t("sites.letters.your_balance")}
        </span>

        <div className="atlas-flex">
          {loading && <Loading />}

          {!loading && currentBalance && (
            <span
              data-testid="current-balance"
              className={
                error
                  ? "atlas-text-background-surface-destructive-accent"
                  : "atlas-text-text-base-primary"
              }
            >
              {currentBalance}
            </span>
          )}
        </div>
      </div>

      {error && (
        <Banner
          showIcon
          variant="error"
          message={t("sites.letters.we_cant_display_your_balance_at_the_moment")}
          className="atlas-flex atlas-mt-2"
        />
      )}
    </div>
  );
};
