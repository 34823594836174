import { ChangeEvent, useCallback } from "react";

import clsx from "clsx";

import { Switch } from "react-migration/components/Switch";

import { LayerTypeControlPageProps } from "../../types";
import { useControlTracking } from "../../ControlTrackingContext";

import { usePlanningApplicationsContext } from "./Context";
import { PlanningApplicationsFilters } from "./components/PlanningApplicationsFilters";
import { ClassificationToggles } from "./components/ClassificationToggles";

export function PlanningApplicationsControlPage({ disabled }: LayerTypeControlPageProps) {
  const { debouncedTrackControlChange } = useControlTracking();
  const { state, stateDispatch } = usePlanningApplicationsContext();

  const { showBoundaries } = state;

  const handleShowBoundariesChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      debouncedTrackControlChange({ filterName: "showBoundaries", filterState: ev.target.checked });
      stateDispatch({ type: "SET_SHOW_BOUNDARIES", value: ev.currentTarget.checked });
    },
    [stateDispatch, debouncedTrackControlChange]
  );

  return (
    <div className="atlas-space-y-4">
      <div className="atlas-space-y-2">
        <div className="atlas-flex atlas-justify-between atlas-items-center atlas-pb-4 atlas-border-b atlas-border-neutral-200">
          <label
            htmlFor="planning-show-boundaries"
            className={clsx("atlas-font-semibold", { "atlas-opacity-50": disabled })}
          >
            Show available boundaries
          </label>
          <div className="atlas-flex atlas-justify-end atlas-items-center">
            <Switch
              disabled={disabled}
              id="planning-show-boundaries"
              label="Show available boundaries"
              onToggle={handleShowBoundariesChange}
              value={showBoundaries}
            />
          </div>
        </div>
        <div className="atlas-space-y-2 atlas-pb-4 atlas-pt-2 atlas-border-b atlas-border-neutral-200">
          <div className="atlas-space-y-2">
            <ClassificationToggles
              disabled={disabled}
              prefix="planning"
              state={state}
              stateDispatch={stateDispatch}
              debouncedTrackControlChange={debouncedTrackControlChange}
            />
          </div>
        </div>
      </div>

      <PlanningApplicationsFilters
        disabled={disabled}
        state={state}
        stateDispatch={stateDispatch}
        debouncedTrackControlChange={debouncedTrackControlChange}
      />
    </div>
  );
}
