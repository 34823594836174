import * as React from "react";

interface LabelTagProps {
  text?: string;
  className?: string;
  onClose?: React.MouseEventHandler<HTMLDivElement>;
  customCloseButton?: React.ReactNode;
  style?: React.CSSProperties;
}

export const LabelTag = ({
  text = "",
  className: classNameExt = "",
  onClose,
  customCloseButton,
  style = {},
}: LabelTagProps) => {
  let className =
    "atlas-bg-background-surface-neutral-subtle atlas-leading-none atlas-text-xs atlas-font-regular atlas-rounded-full atlas-px-2 atlas-py-1 atlas-inline-block";
  if (classNameExt) {
    className = `${className} ${classNameExt}`;
  }
  return (
    <div style={style} className={className} data-testid="li-react-label">
      {onClose ? (
        <div className="atlas-flex">
          <span>{text}</span>
          <div onClick={onClose} className="atlas-cursor-pointer" data-testid="remove-label">
            {customCloseButton ? (
              customCloseButton
            ) : (
              <i className="icon-lt-close-line atlas-ml-1" />
            )}
          </div>
        </div>
      ) : (
        text
      )}
    </div>
  );
};
