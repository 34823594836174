import React, { useEffect, useState } from "react";
import clsx from "clsx";

enum InputSize {
  small = "atlas-py-[7px]",
  medium = "atlas-py-[9px]",
  large = "atlas-py-[11px]",
}

export type TextInputV2Props = {
  label?: string;
  readOnly?: boolean;
  id?: string;
  value?: string;
  disabled?: boolean;
  name?: string;
  errorMessage?: string;
  dataTestId?: string;
  placeholder?: string;
  size?: keyof typeof InputSize;
  IconLeft?: React.ReactNode;
  IconRight?: React.ReactNode;
  iconContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  type?: "text" | "password";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  autoComplete?: string;
};

export const TextInputV2 = React.forwardRef(
  (
    {
      label,
      readOnly,
      id = "text-field",
      disabled,
      errorMessage,
      name,
      dataTestId = "li-text-field-input",
      placeholder,
      size = "small",
      value,
      IconLeft,
      IconRight,
      iconContainerProps,
      type = "text",
      onChange,
      onInput,
      onKeyDown,
      onBlur,
      onFocus,
      autoComplete,
    }: TextInputV2Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [currentValue, setCurrentValue] = useState(value);
    const inputContainerClasses = clsx(
      "atlas-group",
      "atlas-relative atlas-overflow-hidden",
      "atlas-flex atlas-items-center",
      "atlas-py-0",
      "atlas-bg-white atlas-border atlas-border-solid atlas-rounded atlas-border-neutral-300 atlas-outline-1 ",
      "atlas-text-sm",
      {
        "atlas-bg-neutral-300 atlas-cursor-not-allowed atlas-text-neutral-400": disabled,
        "hover:atlas-border-neutral-500": !disabled,
        "hover:focus-within:atlas-border-platform-600": !disabled,
        "atlas-border-red-700": errorMessage,
        "focus-within:atlas-outline-none focus-within:atlas-border-platform-600": !errorMessage,
      }
    );
    const inputClasses = clsx(
      "atlas-px-3 atlas-leading-4 atlas-flex-grow atlas-min-w-0",
      InputSize[size],
      {
        "atlas-cursor-not-allowed placeholder:atlas-text-neutral-400": disabled,
        "placeholder:atlas-text-neutral-300": !disabled,
      }
    );

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.target.value);
      onChange?.(e);
    };

    const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.target.value);
      onBlur?.(e);
    };

    return (
      <>
        {label && (
          <label
            htmlFor={id}
            className="atlas-text-xs atlas-leading-5 atlas-pb-2 atlas-m-0 atlas-font-semibold"
          >
            {label}
          </label>
        )}
        <div>
          <div className={inputContainerClasses}>
            {IconLeft && (
              <div className={"atlas-ml-3 atlas-flex"} {...iconContainerProps}>
                {IconLeft}
              </div>
            )}
            <input
              readOnly={readOnly}
              name={name}
              data-testid={dataTestId}
              id={id}
              disabled={disabled}
              type={type}
              className={inputClasses}
              placeholder={placeholder}
              value={currentValue}
              onChange={handleChange}
              onBlur={handleOnBlur}
              onInput={onInput}
              onKeyDown={onKeyDown}
              ref={ref}
              autoComplete={autoComplete}
              onFocus={onFocus}
            />
            {IconRight && (
              <div className={"atlas-mr-3 atlas-ml-1.5 atlas-flex"} {...iconContainerProps}>
                {IconRight}
              </div>
            )}
          </div>
          {errorMessage && (
            <p
              data-testid={`${dataTestId}-error`}
              className="atlas-text-xs atlas-leading-5 atlas-pt-1 atlas-m-0 atlas-font-normal atlas-text-red-700"
            >
              {errorMessage}
            </p>
          )}
        </div>
      </>
    );
  }
);

TextInputV2.displayName = "TextInputV2";
