import { createContext, Dispatch, PropsWithChildren, useContext, useEffect, useState } from "react";
import type { TagItem } from "react-migration/components/Tags";

export type KeyIndicatorTag = Omit<TagItem, "onClose" | "className" | "size">;

interface KeyIndicatorTagsContextProps {
  tags: KeyIndicatorTag[];
  setTags: Dispatch<React.SetStateAction<KeyIndicatorTag[]>>;
}

export const KeyIndicatorTagsContext = createContext<KeyIndicatorTagsContextProps | null>(null);

export const KeyIndicatorTagsProvider = ({ children }: PropsWithChildren) => {
  const [tags, setTags] = useState<KeyIndicatorTag[]>([]);

  return (
    <KeyIndicatorTagsContext.Provider value={{ tags, setTags }}>
      {children}
    </KeyIndicatorTagsContext.Provider>
  );
};

export const useKeyIndicatorContext = () => {
  const keyIndicatorContext = useContext(KeyIndicatorTagsContext);

  if (!keyIndicatorContext) {
    throw new Error("useKeyIndicatorTags must be used within a KeyIndicatorTagsProvider.");
  }

  return keyIndicatorContext;
};

export const useKeyIndicatorTags = (
  createTags: (tags: KeyIndicatorTag[]) => KeyIndicatorTag[],
  deps: unknown[] = []
) => {
  const { setTags } = useKeyIndicatorContext();

  useEffect(() => {
    setTags((tags) => createTags(tags) || []);
    return () => setTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
