import { useMemo } from "react";
import * as React from "react";
import classNames from "classnames";

type BannerVariant = "info" | "success" | "warning" | "error" | "light" | "dark";

interface BannerProps {
  className?: string;
  variant?: BannerVariant;
  showIcon?: boolean;
  title?: string;
  message?: string | React.ReactElement;
  onClose?: () => void;
}

export const Banner: React.FC<BannerProps> = ({
  variant = "light",
  showIcon,
  title,
  message,
  onClose,
  className: containerClassName,
}) => {
  const iconClassName = useMemo(() => {
    switch (variant) {
      case "info":
      case "error":
        return "icon-lt-information-line";
      case "success":
        return "icon-lt-check-line";
      case "warning":
        return "icon-lt-alert-line";
      case "light":
      case "dark":
        return "icon-lt-question-line";
    }
  }, [variant]);
  const iconColorClassName = useMemo(() => {
    switch (variant) {
      case "info":
        return "atlas-text-text-base-active";
      case "success":
        return "atlas-text-background-surface-success-accent";
      case "warning":
        return "atlas-text-background-surface-warning-accent";
      case "error":
        return "atlas-text-background-surface-destructive-accent";
      case "light":
        return "atlas-text-text-base-primary";
      case "dark":
        return "atlas-text-white";
    }
  }, [variant]);
  const textColorClassName = useMemo(() => {
    return variant === "dark" ? "atlas-text-white" : "atlas-text-text-base-primary";
  }, [variant]);
  const borderColorClassName = useMemo(() => {
    switch (variant) {
      case "info":
        return "atlas-border-border-action-info";
      case "success":
        return "atlas-border-border-action-success";
      case "warning":
        return "atlas-border-border-action-warning";
      case "error":
        return "atlas-border-border-action-destructive";
      case "light":
        return "atlas-border-border-action";
      case "dark":
        return "atlas-border-neutral-800-action";
    }
  }, [variant]);
  const backgroundColorClassName = useMemo(() => {
    switch (variant) {
      case "info":
        return "atlas-bg-platform-100";
      case "success":
        return "atlas-bg-positive-50";
      case "warning":
        return "atlas-bg-warning-50";
      case "error":
        return "atlas-bg-negative-50";
      case "light":
        return "atlas-bg-background-surface-neutral";
      case "dark":
        return "atlas-bg-neutral-500";
    }
  }, [variant]);
  return (
    <div
      data-testid="banner-container"
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-items-center",
        "atlas-rounded-lg",
        "atlas-border",
        "atlas-border-solid",
        "atlas-px-3",
        "atlas-py-2",
        "atlas-gap-x-3",
        backgroundColorClassName,
        borderColorClassName,
        containerClassName
      )}
    >
      {showIcon && (
        <i
          data-testid="banner-icon"
          className={classNames(
            iconClassName,
            "atlas-text-lg",
            "atlas-self-start",
            iconColorClassName
          )}
        />
      )}
      <div
        className={classNames(
          "atlas-grow",
          "atlas-flex",
          "atlas-flex-col",
          "atlas-gap-y-2",
          textColorClassName
        )}
        data-testid="banner-content"
      >
        {title && (
          <div
            data-testid="banner-title"
            className={classNames("atlas-font-semibold", "atlas-leading-4", "atlas-text-sm")}
          >
            {title}
          </div>
        )}
        {message && (
          <div
            data-testid="banner-message"
            className={classNames("atlas-font-regular", "atlas-leading-4", "atlas-text-sm")}
          >
            {message}
          </div>
        )}
      </div>
      {onClose && (
        <i
          data-testid="banner-close"
          className={classNames(
            "icon-lt-close-line",
            "atlas-text-lg",
            "atlas-self-start",
            "hover:atlas-cursor-pointer",
            textColorClassName
          )}
          onClick={onClose}
        />
      )}
    </div>
  );
};
