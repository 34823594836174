import { Icon } from "react-migration/components/Icon";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const SummaryError = () => {
  const { t } = useTranslation();
  return (
    <div className="atlas-flex atlas-px-3 atlas-gap-x-2 atlas-items-center">
      <Icon size="sm" icon="icon-lt-error-warning-line atlas-text-negative-800" />
      <div className="atlas-text-negative-800 atlas-text-xs">{t("planning.ai.failed")}</div>
    </div>
  );
};
