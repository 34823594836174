import { useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TypesDerived } from "../types";

export function usePlanningTypesDisplay(types: TypesDerived[]) {
  const { t, checkKey } = useTranslation();

  return useMemo(() => {
    function getTypeTranslation(type: TypesDerived) {
      const key = `planning.planning_application_list.planning_application_detail_view.type.${type}`;
      return checkKey(key) ? t(key) : type;
    }

    return types?.length ? types.map(getTypeTranslation).join(", ") : "N/A";
  }, [types, t, checkKey]);
}
