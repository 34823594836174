import axios from "axios";
import { Position } from "geojson";
import { getDeviceSpecifications } from "src/js/util/device_specifications";
import { ENVIRONMENT, PRIVATE_APP } from "src/js/util/environment";
import { v1 as uuid } from "uuid";

const deviceUuid = () => {
  if (!localStorage.getItem("device-uuid")) {
    localStorage.setItem("device-uuid", uuid());
  }

  return localStorage.getItem("device-uuid")!;
};

const LOG_PRODUCT_EVENTS_TO_CONSOLE =
  ENVIRONMENT.LOG_PRODUCT_EVENTS_TO_CONSOLE === "true" || ENVIRONMENT.APP_ENV === "local";

type LogEventValue =
  | string
  | boolean
  | number
  | undefined
  | null
  | Array<string>
  | Record<string, unknown>
  | Position;
export type LogEventProps = Record<string, LogEventValue>;

async function logEvent(name: string, properties: LogEventProps = {}) {
  // WARNING: caller should NOT await this function as it might take a while to finish,
  // and it's better to let it fail silently.
  if (!PRIVATE_APP) {
    return;
  }

  const gpuSettings = Object.fromEntries(
    Object.entries(getDeviceSpecifications() || {}).map(([k, v]) => [
      `gpu_${k == "gpu" ? "name" : k}`,
      v,
    ])
  );

  properties = {
    ...properties,
    deviceUuid: deviceUuid(),
    gpu: {
      ...gpuSettings,
    },
    url: {
      href: window.location.href,
      origin: window.location.origin,
      pathname: window.location.pathname,
      search: window.location.search,
    },
    device: {
      screen: {
        width: screen.width || null,
        height: screen.height || null,
        size: screen.width ? `${screen.width}x${screen.height}` : null,
        orientation: screen.orientation?.type || null,
        touch: "ontouchstart" in window || navigator.maxTouchPoints > 0,
      },
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
        size: `${window.innerWidth}x${window.innerHeight}`,
      },
    },
  };

  // log to accounts hub, and thence to various places (at time of writing: Mixpanel,
  // Intercom, Planhat, and the Business Intelligence warehouse/dashboards)
  LOG_PRODUCT_EVENTS_TO_CONSOLE && console.log("track", name, properties);
  await axios.post(`${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/event`, {
    source: "LandInsight",
    name,
    properties,
  });
}

export { deviceUuid, logEvent };
