import clsx from "clsx";
import { TextInputV2 } from "react-migration/components/TextInputV2";
import { Loading } from "react-migration/components/Loading";
import { Table } from "react-migration/components/Table";
import {
  Pagination,
  PaginationContextProvider,
  PaginationInfo,
} from "react-migration/components/Pagination";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useRef, useState } from "react";
import { router } from "src/js/router/react-router";
import { useEventListener } from "usehooks-ts";
import { usePagedTransactionsQuery } from "./hooks/usePagedTransactionsQuery";
import { useDocumentColumns } from "./hooks/useDocumentColumns";
import { SelectFilter } from "react-migration/components/SelectFilter";
import { NoSearchResults } from "./components/NoSearchResults";

const PER_PAGE = 25;

const transactionTypes = [
  { id: "Other", name: "Top-up" },
  { id: "TitleRegisterDocument", name: "Title Registration" },
  { id: "TitlePlanDocument", name: "Title Plan" },
  { id: "OSMapPrint", name: "OS Map Print" },
  { id: "LetterSending", name: "Letter Sending" },
  { id: "Atkins", name: "Atkins" },
];

export const Transactions = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const pageNrQuery = searchParams.get("page");
  const [searchValue, setSearchValue] = useState(searchParams.get("search") || "");
  const [searchValueQuery, setSearchValueQuery] = useState(searchParams.get("search") || "");
  const [kindValueQuery, setKindValueQuery] = useState<string[]>([]);

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(() => {
    if (Number(pageNrQuery) - 1 > 0) {
      return Number(pageNrQuery) - 1;
    }
    return 0;
  });

  const { data, error, loading } = usePagedTransactionsQuery({
    page: currentPage,
    perPage: PER_PAGE,
    search: searchValueQuery,
    kind: kindValueQuery,
  });

  type queryParamsType = { page: string; search?: string };

  const pagination = data?.pagedTransactions.pagination;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const updateKindValueQuery = async (kindValues: string[]) => {
    setKindValueQuery(kindValues);
  };

  const documentRef = useRef(document);
  useEventListener(
    "keyup",
    (e) => {
      if (e.key === "Enter") {
        setSearchValueQuery(searchValue);
        const queryParams = { page: String(currentPage + 1) } as queryParamsType;
        if (searchValue) queryParams.search = searchValue;
        router.push({ query: queryParams });
      }
    },
    documentRef
  );

  const columns = useDocumentColumns();
  return (
    <div className="documents-tab atlas-flex-grow atlas-min-w-[1024px] ">
      <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-2 atlas-pb-[10px]">
        {t("dashboard.transactions.transaction_history")}
      </h2>
      <div
        data-testid="documents-table"
        className={clsx("atlas-h-full", "atlas-w-full", "atlas-bg-background-surface")}
      >
        <div className="atlas-flex atlas-justify-end">
          <SelectFilter
            label={t("dashboard.transactions.filter_transactions_type")}
            contentTitle={t("dashboard.transactions.select_transactions_type")}
            options={transactionTypes}
            selectedOptionIds={kindValueQuery}
            onSelectedOptionsUpdate={updateKindValueQuery}
          />
          <TextInputV2
            value={searchValue}
            IconLeft={
              <span className="atlas-flex atlas-text-neutral-400 atlas-w-4">
                <SearchIcon />
              </span>
            }
            placeholder={t("dashboard.transactions.search_for_transaction_by_description")}
            onChange={handleSearch}
          />
        </div>
        {loading && <Loading />}
        {error ? (
          <div>{t("dashboard.transactions.error")}</div>
        ) : (
          <>
            {((data?.pagedTransactions?.nodes?.length && !!pagination) ||
              (searchValueQuery && !!pagination)) && (
              <PaginationContextProvider
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.perPage}
                total={pagination.totalCount}
                pageCount={pagination.totalPages}
                onPageChange={handlePageChange}
              >
                <div className="atlas-mb-4 atlas-flex atlas-justify-between">
                  <PaginationInfo />
                </div>
                {data?.pagedTransactions?.nodes?.length > 0 && (
                  <div className={pagination.totalPages < 2 ? "atlas-pb-[40px]" : ""}>
                    <Table
                      primaryKey="created"
                      columns={columns}
                      rows={data.pagedTransactions.nodes}
                    />
                  </div>
                )}

                {pagination.totalPages > 1 && (
                  <div className="atlas-flex atlas-justify-center atlas-py-6">
                    <Pagination />
                  </div>
                )}
              </PaginationContextProvider>
            )}
          </>
        )}
        {searchValueQuery
          ? data?.pagedTransactions?.nodes?.length === 0 && <NoSearchResults />
          : data?.pagedTransactions?.nodes?.length === 0 && (
              <div>{t("dashboard.transactions.no_transactions")}</div>
            )}
      </div>
    </div>
  );
};

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3421_2488)">
        <path
          fill="currentColor"
          d="M12.021 11.078l2.856 2.854-.944.944-2.854-2.856a6.002 6.002 0 01-9.745-4.687c0-3.312 2.688-6 6-6a6.002 6.002 0 014.687 9.745zm-1.337-.495a4.665 4.665 0 00-3.35-7.917 4.665 4.665 0 00-4.667 4.667 4.665 4.665 0 007.917 3.35l.1-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3421_2488">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
