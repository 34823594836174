import clsx from "clsx";
import { formatClassificationValue } from "../../utils/textFormatting";
import { PlanningClassification } from "../../types";

export interface ClassificationBadgeProps {
  classification: PlanningClassification;
}

export const ClassificationBadge = ({ classification }: ClassificationBadgeProps) => (
  <div
    title={formatClassificationValue(classification)}
    className={clsx(
      "atlas-w-5 atlas-h-5 atlas-rounded-full atlas-text-sm atlas-font-medium atlas-text-white atlas-flex atlas-items-center atlas-justify-center atlas-cursor-default atlas-select-none",
      {
        "atlas-bg-[#DC3964]": classification === PlanningClassification.RESIDENTIAL,
        "atlas-bg-marketingPurple-600": classification === PlanningClassification.COMMERCIAL,
        "atlas-bg-gradient-to-tr atlas-from-[#862BDE] atlas-to-[#DC2626]":
          classification === PlanningClassification.MIXED_USE,
        "atlas-bg-platformBlue-400": classification === PlanningClassification.OTHER,
        "atlas-bg-blueGrey-400": classification === PlanningClassification.UNKNOWN,
      }
    )}
  >
    {classification.charAt(0).toUpperCase()}
  </div>
);
