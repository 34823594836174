import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { RECENT_APPLICATIONS_YEAR_FILTER } from "./constants";

export const useRecentPlanningKeyInsights = (
  applications: PlanningApplication[],
  loading = false
) => {
  useKeyIndicatorTags(
    () =>
      loading
        ? []
        : [
            {
              tag: `${applications?.length ?? 0} (last ${RECENT_APPLICATIONS_YEAR_FILTER} years)`,
              color: KeyIndicatorColor.DEFAULT,
            },
          ],
    [applications, loading]
  );
};
