import { GeometryIcon } from "react-migration/layouts/map/Ownership/icons";
import { LayerTypeSelectionHeaderProps } from "../../../types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";

export function OwnershipDesignationSelectionHeader({
  selection,
}: Readonly<LayerTypeSelectionHeaderProps>) {
  const { t } = useTranslation();
  const { designation } = useDesignationById(selection.id);

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2">
      <div>
        <GeometryIcon />
      </div>
      <div className="atlas-flex atlas-flex-col atlas-items-start atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary">
          {t("selection.ownership_title")}
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
          {designation?.display_name}
        </div>
      </div>
    </div>
  );
}
