import { useMemo } from "react";

import isEqual from "lodash/isEqual";

import { planningTypes } from "react-migration/domains/planning/constants";
import { PlanningStatus, planningStatuses } from "react-migration/domains/planning/types";
import { useLocalStorageReducer } from "react-migration/lib/persistence/state/useLocalStorageReducer";

interface PlanningFilters {
  showResidential: boolean;
  showCommercial: boolean;
  showOther: boolean;
  showBoundaries: boolean;
  maxYear: number;
  minSize: number;
  enabledTypes: string[];
  enabledStatuses: PlanningStatus[];
}

export const defaultPlanningFilterState: PlanningFilters = {
  showResidential: true,
  showCommercial: true,
  showOther: true,
  showBoundaries: true,
  maxYear: 5,
  minSize: 1,
  enabledTypes: planningTypes.map((planningType) => planningType.id),
  enabledStatuses: planningStatuses.map((x) => x.id),
};

type Action =
  | { type: "SET_RESIDENTIAL"; value: boolean }
  | { type: "SET_COMMERCIAL"; value: boolean }
  | { type: "SET_SHOW_BOUNDARIES"; value: boolean }
  | { type: "SET_OTHER"; value: boolean }
  | { type: "SET_YEARS"; value: number }
  | { type: "SET_HOMES"; value: number }
  | { type: "SET_ENABLED_TYPES"; value: string[] }
  | { type: "SET_ENABLED_STATUSES"; value: PlanningStatus[] }
  | { type: "RESET_STATE" };

function createReducer(initialState: PlanningFilters) {
  return function reducer(state: PlanningFilters, action: Action): PlanningFilters {
    switch (action.type) {
      case "SET_RESIDENTIAL":
        return { ...state, showResidential: action.value };
      case "SET_COMMERCIAL":
        return { ...state, showCommercial: action.value };
      case "SET_OTHER":
        return { ...state, showOther: action.value };
      case "SET_HOMES":
        return { ...state, minSize: action.value };
      case "SET_YEARS":
        return { ...state, maxYear: action.value };
      case "SET_ENABLED_TYPES":
        return { ...state, enabledTypes: action.value };
      case "SET_ENABLED_STATUSES":
        return { ...state, enabledStatuses: action.value };
      case "SET_SHOW_BOUNDARIES":
        return { ...state, showBoundaries: action.value };
      case "RESET_STATE":
        return { ...initialState };
    }
  };
}

export type PlanningState = ReturnType<typeof usePlanningFilterState>[0];
export type PlanningStateDispatch = ReturnType<typeof usePlanningFilterState>[1];

export function usePlanningFilterState(
  localStorageKey: string,
  initialStatePartial: Partial<PlanningFilters> = {}
) {
  const initialState = useMemo(
    () => ({
      ...defaultPlanningFilterState,
      ...initialStatePartial,
    }),
    [initialStatePartial]
  );

  const [state, dispatch] = useLocalStorageReducer(
    localStorageKey,
    createReducer(initialState),
    initialState
  );

  const hasFilters = useMemo(() => !isEqual(initialState, state), [initialState, state]);

  return [state, dispatch, { hasFilters }] as const;
}
