import { useEffect, useMemo } from "react";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { Insight } from "react-migration/components/Insight/Insight";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps } from "../../../types";
import { useGreenGreyBeltInsights } from "./useGreenGreyBeltKeyInsights";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { getBeltClassifications } from "./getBeltClassifications";
import { Belts, InsightStatus } from "./types";
import { GREY_BELT_CATEGORIES } from "./constants";

const defaultIndicatorState = {
  description: "Coverage:",
  indicator: `0%`,
};

function getGreyBeltStatus({ greyBeltPercentage }: Belts): InsightStatus {
  const defaultState = {
    value: "Unlikely",
    ...defaultIndicatorState,
    color: "grey",
  } as InsightStatus;

  const hasGreyBelt = greyBeltPercentage > 0;

  if (!hasGreyBelt) {
    return defaultState;
  }

  return {
    value: "Likely",
    description: "Coverage:",
    indicator: `${greyBeltPercentage}%`,
    color: "green",
  };
}

function getDeclassifiedStatus({ declassifiedGreenBeltPercentage }: Belts): InsightStatus {
  if (declassifiedGreenBeltPercentage > 0) {
    return {
      value: "Yes",
      description: "Coverage",
      indicator: `${declassifiedGreenBeltPercentage}%`,
      color: "green",
    };
  }

  return {
    value: "No",
    ...defaultIndicatorState,
    color: "grey",
  };
}

function getGreenBeltStatus({ greenBeltPercentage }: Belts): InsightStatus {
  if (greenBeltPercentage > 0) {
    return {
      value: "Yes",
      description: `Coverage`,
      indicator: `${greenBeltPercentage}%`,
      color: "red",
    };
  }

  return {
    value: "No",
    ...defaultIndicatorState,
    color: "green",
  };
}

export function GreenGreyBeltLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const geometry = selection.feature?.geometry ?? null;

  const rootCategories = [...GREY_BELT_CATEGORIES, "green_belt", "declassified_green_belt"];

  const {
    data: designations,
    loading,
    error,
  } = useDesignationAreaByGeometry(geometry, rootCategories);

  const belts = useMemo(() => getBeltClassifications(designations), [designations]);
  useSelectionSidebarLoading(onLoadingChange, loading);
  useGreenGreyBeltInsights(belts);

  useEffect(() => {
    onLoadingChange(loading);
  }, [onLoadingChange, loading]);

  if (loading || !belts) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  const greenBeltStatus = getGreenBeltStatus(belts);
  const greyBelt = getGreyBeltStatus(belts);
  const declassified = getDeclassifiedStatus(belts);

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <div className="atlas-grid atlas-grid-cols-3 atlas-gap-2">
      <Insight.Wrapper title="Green belt">
        <Insight.Body status={greenBeltStatus.color}>{greenBeltStatus.value}</Insight.Body>
        <Insight.Footer description={greenBeltStatus.description}>
          {greenBeltStatus.indicator}
        </Insight.Footer>
      </Insight.Wrapper>
      <Insight.Wrapper title="Declassified">
        <Insight.Body status={declassified.color}>{declassified.value}</Insight.Body>
        <Insight.Footer description={declassified.description}>
          {declassified.indicator}
        </Insight.Footer>
      </Insight.Wrapper>
      <Insight.Wrapper title="Grey belt">
        <Insight.Body status={greyBelt.color}>{greyBelt.value}</Insight.Body>
        <Insight.Footer description={greyBelt.description}>{greyBelt.indicator}</Insight.Footer>
      </Insight.Wrapper>
    </div>
  );
}

GreenGreyBeltLayerSummary.icon = "icon-lt-belt";
