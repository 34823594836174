import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps } from "../../../types";
import { useFloodingKeyIndicators } from "./useFloodingKeyIndicators";
import { useFloodingSummaryQuery } from "./useFloodingSummaryQuery";
import { FloodingSummarySection } from "./FloodingSummarySection";
import { Alert } from "react-migration/components/Alert";

export function FloodingLayerSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const { floodRiskCoverage, floodZoneCoverage, error, loading } = useFloodingSummaryQuery(
    selection?.feature?.geometry
  );

  useSelectionSidebarLoading(onLoadingChange, loading);
  useFloodingKeyIndicators(floodRiskCoverage);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  if (!floodRiskCoverage?.length && !floodZoneCoverage?.length) {
    return <Alert.Info>Selection is not impacted by risk of flooding</Alert.Info>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <FloodingSummarySection
        areas={floodRiskCoverage}
        heading={t("workbench.summary.flooding.flood_risk_heading")}
      />
      <FloodingSummarySection
        areas={floodZoneCoverage}
        heading={t("workbench.summary.flooding.flood_zone_heading")}
      />
    </div>
  );
}

FloodingLayerSummary.icon = "icon-lt-drop-line";
