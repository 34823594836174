import { MVTLayerProps } from "@deck.gl/geo-layers/dist/mvt-layer/mvt-layer";
import { MVTLayer, Color } from "deck.gl";
import { Feature, Geometry } from "geojson";
import { DesignationFeature } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { Colour, TRANSPARENT } from "react-migration/domains/constraints/designation/style/colours";
import { LPA_DISPLAY_BY } from "./PlanningAuthorityContext";
import { DataFilterExtension } from "@deck.gl/extensions";

interface PlanningAuthorityLayerProps extends MVTLayerProps<PlanningAuthorityFeatureProperties> {
  displayByFilter: LPA_DISPLAY_BY;
  localPlanAgeFilter: number | undefined;
  housingLandSupplyRemainingFilter: number | undefined;
  housingDeliveryTestFilter: number | undefined;
  visibleCategories: string[];
}

interface PlanningAuthorityFeatureProperties extends DesignationFeature {
  designation_attributes: string;
  sub_category_id: string;
  display_name: string;
}

type PlanningAuthorityFeature = GeoJSON.Feature<
  GeoJSON.Geometry,
  PlanningAuthorityFeatureProperties
>;

type KeyValue = { key: string; value: string };
type PlanningAuthorityDesignationAttributes = {
  "5yhs": string;
  adpt_dat: string;
  hdt21_meas: string;
  pif: string;
  call_for_sites_status?: string;
  "appeal-derived_position_-_years": string;
};

const noColour = Colour.ORANGE;
const yesColour = Colour.TEAL;
const unknownColour = Colour.GREY;
const fillOpacity = 0.6 * 255;

const filterIsNotSet = (filterValue: number | undefined) =>
  filterValue === undefined || Number.isNaN(filterValue);

const getDesignationAttributes = (
  data: PlanningAuthorityFeature
): PlanningAuthorityDesignationAttributes | null => {
  if (!data.properties.designation_attributes) {
    return null;
  }
  return JSON.parse(data.properties.designation_attributes).reduce(
    (acc: Record<string, string>, curr: KeyValue) => {
      acc[curr.key] = curr.value;
      return acc;
    },
    {}
  );
};

export class PlanningAuthorityLayer extends MVTLayer<
  PlanningAuthorityFeatureProperties,
  PlanningAuthorityLayerProps
> {
  constructor(props: PlanningAuthorityLayerProps) {
    super(
      {
        binary: true,
        refinementStrategy: "no-overlap",
        getLineColor: (data: PlanningAuthorityFeature) => {
          switch (data.properties.sub_category_id) {
            case "local_planning_authority_former":
              return Colour.BROWN;
            case "local_planning_authority_national_park":
              return Colour.GREEN;
            default:
              return Colour.BLUE;
          }
        },
        getFillColor: (data) => this._getFillColor(data),
        minZoom: 6,
        maxZoom: 16,
        lineWidthUnits: "pixels",
        getLineWidth: 2,
        extensions: [...(props.extensions ?? []), new DataFilterExtension({ filterSize: 4 })],
        // @ts-expect-error DataFilterExtension uses this props
        getFilterValue: (data) => this._getFilterValue(data),
        filterRange: [
          [0, 1],
          filterIsNotSet(props.localPlanAgeFilter)
            ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
            : [props.localPlanAgeFilter, Number.MAX_SAFE_INTEGER],
          filterIsNotSet(props.housingLandSupplyRemainingFilter)
            ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
            : [0, props.housingLandSupplyRemainingFilter],
          filterIsNotSet(props.housingDeliveryTestFilter)
            ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
            : [0, props.housingDeliveryTestFilter],
        ],
        updateTriggers: {
          getFilterValue: [
            props.visibleCategories,
            props.localPlanAgeFilter,
            props.housingLandSupplyRemainingFilter,
            props.housingDeliveryTestFilter,
          ],
          getFillColor: props.displayByFilter,
        },
      },
      // // @ts-expect-error prop type fails after deck.gl update
      props as MVTLayerProps<PlanningAuthorityFeatureProperties>
    );
  }

  _getFilterValue(data: PlanningAuthorityFeature) {
    const visibilityFilter = this.props.visibleCategories.includes(data.properties.sub_category_id)
      ? 1
      : -1;

    const designationAttributes = getDesignationAttributes(data);

    if (!designationAttributes) {
      return [visibilityFilter, -1, -1, -1];
    }

    try {
      const localPlanDate = new Date(designationAttributes.adpt_dat);
      const unixEpochConversion = 1000 * 60 * 60 * 24 * 365;

      const localPlanAge =
        (new Date().valueOf() - localPlanDate.valueOf()) / unixEpochConversion || -1;

      const housingLandSupply =
        Number(designationAttributes["appeal-derived_position_-_years"]) ||
        Number(designationAttributes["5yhs"]) ||
        -1;

      const housingDeliveryTest = Number(designationAttributes.hdt21_meas) * 100 || -1;

      return [visibilityFilter, localPlanAge, housingLandSupply, housingDeliveryTest];
    } catch (err) {
      console.error(err);
      return [visibilityFilter, -1, -1, -1];
    }
  }

  // @ts-expect-error prop type fails after deck.gl update
  async getTileData(props: PlanningAuthorityLayerProps) {
    // @ts-expect-error prop type fails after deck.gl update
    const r = await super.getTileData(props);
    return r;
  }

  _getFillColor(data: Feature<Geometry, PlanningAuthorityFeatureProperties>): Color {
    // getFillColour receives data, gets attrs, parses them to get pif and callForSites values
    // and sets 1st 3 elements of colour array according to them and displayByFilter
    const designationAttributes = getDesignationAttributes(data);

    if (!designationAttributes) {
      return TRANSPARENT;
    }

    const pif = designationAttributes.pif;
    const pifYes = pif == "Yes" || pif.startsWith("Presumption applies");

    const callForSites = designationAttributes.call_for_sites_status;

    let colour;
    switch (this.props.displayByFilter) {
      case LPA_DISPLAY_BY.Boundaries:
        return TRANSPARENT;
      case LPA_DISPLAY_BY.CallForSites:
        if (callForSites !== "Active") {
          return TRANSPARENT;
        }
        colour = yesColour;
        break;
      case LPA_DISPLAY_BY.PIF:
        colour = pifYes ? yesColour : pif == "No" ? noColour : unknownColour;
        break;
      default:
        colour = unknownColour;
    }
    return [colour[0], colour[1], colour[2], fillOpacity];
  }
}
