import { LayerTypeSelectionSidebarProps } from "../../../types";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { GreenGreyBeltLayerSummary } from "./GreenGreyBeltLayerSummary";

export const GreenGreyBeltSummarySidebar = ({
  cardExpanded,
  onLoadingChange,
  layer,
  selection,
}: LayerTypeSelectionSidebarProps) => {
  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      defaultExpanded={cardExpanded}
      contentPadding={4}
    >
      <GreenGreyBeltLayerSummary
        layer={layer}
        selection={selection}
        onLoadingChange={onLoadingChange}
      />
    </CollapsibleConsiderationsCard>
  );
};
