/*
Temporary Modal serves as a stop gap until 
decision reached on React UI Library: 
https://www.notion.so/landtech/React-UI-Library-9362776376de40c19f37f441068e3afe
*/

import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import classNames from "classnames";

export interface TemporaryModalProps {
  isOpen: boolean;
  title: ReactNode;
  titleClass?: string;
  width?: string;
  headerClass?: string;
  handleClose?: () => void;
}

export function TemporaryModal({
  isOpen,
  children,
  title,
  titleClass,
  width,
  headerClass,
  handleClose,
}: PropsWithChildren<TemporaryModalProps>) {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      // if a user clicks on an autocomplete suggestion from the browsers autocomplete suggestion
      // a keypress gets fired but e.code is undefined
      if (e.code?.toLocaleUpperCase() === "ESCAPE" && handleClose) {
        handleClose();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className={classNames(
            "atlas-fixed",
            "atlas-inset-0",
            "atlas-bg-[#000]/50",
            "atlas-z-[99999]",
            "atlas-h-full",
            "atlas-w-full",
            "atlas-flex",
            "atlas-items-center",
            "atlas-justify-center"
          )}
          data-testid="modal-wrapper"
        >
          <button
            className={classNames(
              "atlas-block",
              "atlas-absolute",
              "atlas-inset-0",
              "atlas-cursor-default"
            )}
            onClick={handleClose}
          />

          <motion.div
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            transition={{ opacity: { duration: 0.05 }, duration: 0.1 }}
            className={classNames(
              width ?? "atlas-w-1/3 atlas-min-w-[500px]",
              "atlas-relative",
              "atlas-bg-white",
              "atlas-rounded-lg",
              "atlas-overflow-hidden"
            )}
          >
            <div
              className={classNames(
                "atlas-border-b",
                "atlas-border-neutral-300",
                "atlas-bg-neutral-100",
                headerClass ?? "atlas-py-4 atlas-px-5",
                "atlas-flex",
                "atlas-items-center",
                "atlas-justify-between"
              )}
              data-testid="modal-title"
            >
              <h1 className={classNames(titleClass ?? "atlas-text-lg atlas-font-normal")}>
                {title}
              </h1>
              <i
                className={classNames(
                  "icon-lt-close-line",
                  "atlas-text-2xl",
                  "atlas-text-text-base-primary",
                  "hover:atlas-cursor-pointer",
                  "hover:atlas-bg-neutral-200",
                  "hover:atlas-rounded-full",
                  "hover:atlas-w-8",
                  "hover:atlas-text-center",
                  "hover:-atlas-mr-1"
                )}
                onClick={handleClose}
              />
            </div>
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
