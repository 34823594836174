import { FC, useCallback } from "react";
import { DropdownMenu } from "react-migration/components/DropdownMenu";

import { Intercom } from "src/js/util/intercom";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

export const HelpMenu: FC = () => {
  const { t } = useTranslation();
  const toggleSupport = useCallback(() => {
    if (Intercom.visible) {
      Intercom("hide");
    } else {
      Intercom("show");
      logEvent("Open Intercom Widget", {
        from: "nav-bar",
        route: window.location.pathname.match(RegExp(/^\/([^/?]+)/))?.[1],
      });
    }
  }, []);
  return (
    <DropdownMenu.Provider>
      <DropdownMenu.Trigger
        align={DropdownMenu.TriggerAlignment.BOTTOM}
        className="!atlas-cursor-pointer !atlas-outline-none atlas-hidden md:atlas-block"
      >
        <Button variant="ghost" data-testid="help-button" leadIcon="lt-question-line">
          Support
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="atlas-w-60 atlas-bg-background-surface atlas-border atlas-border-border-action atlas-text-neutral-8">
        {hasFeature(Feature.usAccess) && (
          <>
            <DropdownMenu.ItemLink
              href={t("nav.nav.help.visit_help_centre_link")}
              target="_blank"
              rel="noopener referrer"
              onClick={() => logEvent("Click US Visit Help Centre Link", {})}
            >
              <i className="icon-lt-question-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.visit_help_centre")} </span>
            </DropdownMenu.ItemLink>
            <DropdownMenu.Item onClick={toggleSupport} className="atlas-flex atlas-items-center">
              <i className="icon-lt-chat-question-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.start_a_support_chat")} </span>
            </DropdownMenu.Item>
          </>
        )}
        {!hasFeature(Feature.usAccess) && (
          <>
            <DropdownMenu.Item onClick={toggleSupport} className="atlas-flex atlas-items-center">
              <i className="icon-lt-chat-question-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.start_a_support_chat")} </span>
            </DropdownMenu.Item>
            <DropdownMenu.ItemLink
              href={t("nav.nav.help.visit_help_centre_link")}
              target="_blank"
              rel="noopener referrer"
              onClick={() => logEvent("Click UK Visit Help Centre Link", {})}
            >
              <i className="icon-lt-question-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.visit_help_centre")} </span>
            </DropdownMenu.ItemLink>
            <DropdownMenu.ItemLink
              href={t("nav.nav.help.video_tutorial_link")}
              target="_blank"
              rel="noopener referrer"
              onClick={() => logEvent("Click UK Tutorials Link", {})}
            >
              <i className="icon-lt-video-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.watch_video_tutorials")} </span>
            </DropdownMenu.ItemLink>
            <DropdownMenu.ItemLink
              href={t("nav.nav.help.landtech_learn_link")}
              target="_blank"
              rel="noopener referrer"
              onClick={() => logEvent("Click UK LandTech Learn Link", {})}
            >
              <i className="icon-lt-book-open-line atlas-mr-2 atlas-flex atlas-text-xl" />
              <span className="atlas-text-sm"> {t("nav.nav.landtech_learn")} </span>
            </DropdownMenu.ItemLink>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Provider>
  );
};
