import { i18n } from "src/js/i18n";
type Currency = "GBP" | "USD";

export const formatToCurrency = (
  amount: number,
  currency: Currency,
  locale: string = i18n.locale, // this should use the Locale type as soon as the user object has the property locale, currently it's being pulled from browser locale which could be anything
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string | undefined => {
  let formattedNumber;

  try {
    formattedNumber = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(amount);
  } catch (e) {
    console.error(e);
  }

  return formattedNumber;
};
