import { LayerType } from "../../types";
import { RecentApplicaitonsLayerSelectionSidebar } from "./RecentApplicationsSummary/RecentApplicaitonsLayerSelectionSidebar";
import { RecentApplicationsLayerSummary } from "./RecentApplicationsSummary/RecentApplicationsLayerSummary";
import { PLANNING_APPS_LAYER_TYPE_ID, PLANNING_APPS_RECENT_SUMMARY_LAYER_TYPE_ID } from "./types";
import { PlanningApplicationsMapLayerConnected } from "./MapLayer";
import { PlanningApplicationSelectionSidebar } from "./SelectionSidebar";
import { PlanningApplicationRightClickMenu } from "./PlanningApplicationRightClickMenu";
import { planningApplicationsClickResolver } from "./planningClickResolver";
import { PlanningApplicationsControlPage } from "./ControlPage";
import { PlanningApplicationPrintable } from "./PlanningApplicationPrintable";
import { PlanningApplicationsProvider } from "./Context";

export const PlanningApplicationsLayerType: LayerType = {
  id: PLANNING_APPS_LAYER_TYPE_ID,
  ControlPage: PlanningApplicationsControlPage,
  MapLayer: PlanningApplicationsMapLayerConnected,
  clickResolver: planningApplicationsClickResolver,
  Provider: PlanningApplicationsProvider,
  RightClickMenu: PlanningApplicationRightClickMenu,
  SelectionSidebar: PlanningApplicationSelectionSidebar,
  Printable: PlanningApplicationPrintable,
};

export const PlanningApplicationsRecentSummaryLayerType: LayerType = {
  id: PLANNING_APPS_RECENT_SUMMARY_LAYER_TYPE_ID,
  LayerSummary: RecentApplicationsLayerSummary,
  SelectionSidebar: RecentApplicaitonsLayerSelectionSidebar,
};
