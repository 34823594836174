import { FC, ReactNode } from "react";
import { Theme } from "react-migration/lib/theme/Theme";
import { DatePicker, DatePickerInput } from "react-migration/components/DatePicker";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import clsx from "clsx";
import { YearMonthOrDayPicker } from "react-migration/components/YearMonthOrDayPicker";

type FilterDatesModes = "MONTH" | "YEAR";

export interface FilterDatesProps {
  startDate?: Date | null;
  setStartDate: (date?: Date) => void;
  endDate?: Date | null;
  setEndDate: (date?: Date) => void;
  minDate?: Date;
  mode?: FilterDatesModes;
  disabled?: boolean;
  clearable?: boolean;
  theme?: Theme;
  label: ReactNode;
  additionalClassNames?: string;
}

export const FilterDates: FC<FilterDatesProps> = ({
  disabled,
  clearable = false,
  label,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  mode = "MONTH",
  theme = Theme.Light,
  additionalClassNames,
}) => {
  const { t } = useTranslation();
  const disabledTextColor = `atlas-text-text-base-primary-disabled-${
    theme === Theme.Light ? "light" : "dark"
  }`;

  return (
    <div
      className={clsx(
        "atlas-flex atlas-gap-2 atlas-justify-between",
        additionalClassNames ?? "atlas-flex-col"
      )}
      data-testid="filter-dates"
    >
      <div>
        <h4
          className={clsx(
            "atlas-text-sm atlas-font-medium atlas-my-auto",
            disabled && disabledTextColor
          )}
        >
          {label}
        </h4>
      </div>
      <div className="atlas-flex atlas-flex-row atlas-items-center">
        <div data-testid="comps-filter-date-start">
          <DatePicker
            wrapperClassName="atlas-w-24 atlas-text-xs atlas-text-neutral-400 atlas-justify-around"
            selected={startDate}
            disabled={disabled}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            popperClassName="!atlas-z-[2000]"
            dateFormat={mode === "MONTH" ? "MM/yyyy" : "yyyy"}
            showMonthYearPicker={mode === "MONTH"}
            showYearPicker={mode === "YEAR"}
            customInput={<DatePickerInput theme={theme} disabled={disabled} />}
            minDate={minDate}
            isClearable={clearable}
            clearButtonClassName="atlas-mr-5"
            portalId="map-modals"
          />
        </div>
        <span className={`atlas-px-2 ${disabled ? disabledTextColor : ""}`}>
          {t("comparables.map.sidebar.filters.filters.date.to")}
        </span>
        <div data-testid="comps-filter-date-end">
          <DatePicker
            wrapperClassName="atlas-w-24 atlas-text-xs atlas-text-neutral-400 atlas-justify-around"
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            disabled={disabled}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat={mode === "MONTH" ? "MM/yyyy" : "yyyy"}
            showMonthYearPicker={mode === "MONTH"}
            showYearPicker={mode === "YEAR"}
            popperClassName="!atlas-z-[2000]"
            customInput={<DatePickerInput theme={theme} disabled={disabled} />}
            isClearable={clearable}
            clearButtonClassName="atlas-mr-5"
            portalId="map-modals"
          />
        </div>
      </div>
    </div>
  );
};

export interface FilterDateRangeProps {
  startDate?: Date | null;
  setStartDate: (date?: Date | null) => void;
  endDate?: Date | null;
  setEndDate: (date?: Date | null) => void;
  disabled?: boolean;
  mode?: "year" | "month" | "day";
  label: string;
  minDate?: Date;
  maxDate?: Date;
}

export function FilterDateRange({
  label,
  disabled,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  mode = "month",
  minDate = new Date("1995/01/01"),
  maxDate = new Date(),
}: Readonly<FilterDateRangeProps>) {
  return (
    <div className="atlas-flex atlas-flex-col atlas-py-2">
      <h4 className="atlas-mb-2 atlas-flex atlas-items-center atlas-justify-between">{label}</h4>
      <div className="atlas-flex atlas-justify-between">
        <div data-testid="comps-filter-date-range-start">
          <YearMonthOrDayPicker
            disabled={disabled}
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => setStartDate(date)}
            granularity={mode}
            renderInPortal
          />
        </div>
        <span className="atlas-mx-2"> - </span>
        <div data-testid="comps-filter-date-range-end">
          <YearMonthOrDayPicker
            disabled={disabled}
            selected={endDate}
            minDate={startDate}
            maxDate={maxDate}
            onChange={(date) => setEndDate(date)}
            granularity={mode}
            renderInPortal
          />
        </div>
      </div>
    </div>
  );
}
