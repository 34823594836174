import { SelectionFeature } from "src/js/stores/map/store";
import { LayerTypeSelectionSidebarProps } from "../../types";
import { KitKat, KitKatStat } from "react-migration/components/KitKat";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Snapshot, useDesignationsSnapshotByGeometry } from "./useDesignationsSnapshotByGeometry";
import { DataPanel } from "react-migration/components/DataPanel";
import { Nullable } from "src/js/types/Nullable";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export function SummarySelectionSidebar({ selection, layer }: LayerTypeSelectionSidebarProps) {
  const hasInsightsSummaryBeta = useBetaFeatureFlag(Feature.insightsSummaryBeta);
  const trackKitKatToggle = useCallback((id: string, expanded: boolean) => {
    logEventV2({
      name: "Selection Summary - KitKat Toggle",
      properties: { fingerId: id, expanded },
    });
  }, []);

  // TODO being superseded by the insights summary card
  if (hasFeature(Feature.insightsSummary) && hasInsightsSummaryBeta) return null;

  return (
    <CollapsibleConsiderationsCard heading={layer.title} name={layer.title}>
      <div className="atlas-px-2 atlas-pb-2">
        <KitKat.Wrapper>
          <DesignationsSummaryFingers selection={selection} trackKitKatToggle={trackKitKatToggle} />
        </KitKat.Wrapper>
      </div>
    </CollapsibleConsiderationsCard>
  );
}

interface DesignationsSummaryFingersProps {
  selection: SelectionFeature;
  trackKitKatToggle(id: string, expanded: boolean): void;
}

function DesignationsSummaryFingers({
  selection,
  trackKitKatToggle,
}: DesignationsSummaryFingersProps) {
  const [snapshot, loading] = useDesignationsSnapshotByGeometry(
    selection.feature?.geometry || null
  );

  if (loading)
    return (
      <>
        <KitKat.FingerLoading />
        <KitKat.FingerLoading />
        <KitKat.FingerLoading />
      </>
    );

  return (
    <>
      <ProtectedLandFinger
        snapshot={snapshot.protectedLand}
        trackKitKatToggle={trackKitKatToggle}
      />
      <FloodRiskFinger snapshot={snapshot.floodRisk} trackKitKatToggle={trackKitKatToggle} />
      <ListedBuildingFinger
        snapshot={snapshot.listedBuildings}
        trackKitKatToggle={trackKitKatToggle}
      />
    </>
  );
}

interface ListedBuildingFingerProps {
  snapshot: Snapshot["listedBuildings"];
  trackKitKatToggle(id: string, expanded: boolean): void;
}

function ListedBuildingFinger({ snapshot, trackKitKatToggle }: ListedBuildingFingerProps) {
  const { t } = useTranslation();

  const listedBuildingStat = useMemo<Nullable<KitKatStat>>(() => {
    if (!snapshot) return null;

    return {
      type: "TAG",
      value: `${snapshot.listed_buildings.length}`,
      color: snapshot.listed_buildings.length > 0 ? "YELLOW" : "GREEN",
    };
  }, [snapshot]);

  if (!listedBuildingStat) return null;

  return (
    <KitKat.Finger
      id="listed-buildings"
      trackExpandedChange={trackKitKatToggle}
      heading={t("sidebar.property_information_panel.nearby_listed_buildings")}
      icon="lt-community-line"
      tooltip="Listed building (within 100m)"
      miniStats={[listedBuildingStat]}
    >
      <SummaryContent>
        <DataPanel.Grid columns={1}>
          <DataPanel.Cell
            title={`${t("sidebar.property_information_panel.nearby_listed_buildings")}:`}
          >
            {listedBuildingStat.value}
          </DataPanel.Cell>
        </DataPanel.Grid>
      </SummaryContent>
    </KitKat.Finger>
  );
}

interface FloodRiskFingerProps {
  snapshot: Snapshot["floodRisk"];
  trackKitKatToggle(id: string, expanded: boolean): void;
}

function FloodRiskFinger({ snapshot, trackKitKatToggle }: FloodRiskFingerProps) {
  const { t } = useTranslation();

  const floodRiskStat = useMemo<Nullable<KitKatStat>>(() => {
    if (!snapshot) return null;

    if (snapshot.high_flood_risk.length > 0) {
      return { type: "TAG", value: "High risk", color: "RED" };
    } else if (snapshot.medium_flood_risk.length > 0) {
      return { type: "TAG", value: "Medium risk", color: "YELLOW" };
    } else if (snapshot.low_flood_risk.length > 0) {
      return { type: "TAG", value: "Low risk", color: "YELLOW" };
    } else if (snapshot.very_low_flood_risk.length > 0) {
      return { type: "TAG", value: "Very low risk", color: "GREEN" };
    } else {
      return { type: "TAG", value: "No risk", color: "GREEN" };
    }
  }, [snapshot]);

  if (!floodRiskStat) return null;

  return (
    <KitKat.Finger
      trackExpandedChange={trackKitKatToggle}
      id="flood-risk"
      heading={t("sidebar.property_information_panel.flood_risk")}
      icon="lt-drop-line"
      tooltip="Flood risk from rivers and seas, scored from high to very low (within 100m if point selection)"
      miniStats={[floodRiskStat]}
    >
      <SummaryContent>
        <DataPanel.Grid columns={1}>
          <DataPanel.Cell title={t("sidebar.property_information_panel.probability")}>
            {floodRiskStat.value}
          </DataPanel.Cell>
        </DataPanel.Grid>
      </SummaryContent>
    </KitKat.Finger>
  );
}

interface ProtectedLandFingerProps {
  snapshot: Snapshot["protectedLand"];
  trackKitKatToggle(id: string, expanded: boolean): void;
}

function ProtectedLandFinger({ snapshot, trackKitKatToggle }: ProtectedLandFingerProps) {
  const { t } = useTranslation();

  const protectedLandStat = useMemo<Nullable<KitKatStat>>(() => {
    if (!snapshot) return null;

    const hasRisk = !![snapshot.greenbelt, snapshot.aonb, snapshot.common_land].flat().length;

    return { color: hasRisk ? "RED" : "GREEN", type: "TAG", value: hasRisk ? "Risk" : "No Risk" };
  }, [snapshot]);

  if (!protectedLandStat) return null;

  return (
    <KitKat.Finger
      id="protected-land"
      trackExpandedChange={trackKitKatToggle}
      heading={t("sidebar.property_information_panel.protected_land")}
      icon="lt-flag-2-line"
      tooltip="Greenbelt, common land or a national landscape (within 100m if point selection)"
      miniStats={[protectedLandStat]}
    >
      <SummaryContent>
        <DataPanel.Grid>
          <DataPanel.Cell title={t("sidebar.property_information_panel.aonb")}>
            {snapshot.aonb.length
              ? t("sidebar.property_information_panel.yes")
              : t("sidebar.property_information_panel.no")}
          </DataPanel.Cell>
          <DataPanel.Cell title={t("sidebar.property_information_panel.common_land")}>
            {snapshot.common_land.length
              ? t("sidebar.property_information_panel.yes")
              : t("sidebar.property_information_panel.no")}
          </DataPanel.Cell>
          <DataPanel.Cell title={t("sidebar.property_information_panel.greenbelt")}>
            {snapshot.greenbelt.length
              ? t("sidebar.property_information_panel.yes")
              : t("sidebar.property_information_panel.no")}
          </DataPanel.Cell>
        </DataPanel.Grid>
      </SummaryContent>
    </KitKat.Finger>
  );
}

function SummaryContent({ children }: PropsWithChildren) {
  return <div className="atlas-flex atlas-flex-col atlas-gap-2 atlas-py-2">{children}</div>;
}
