import { Loading } from "react-migration/components/Loading";
import { Tooltip } from "react-migration/components/Tooltip";

import { Theme } from "react-migration/lib/theme/Theme";
import { ReactNode } from "react";
import { Button } from "react-migration/components/Button";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import cn from "clsx";
import * as Popover from "@radix-ui/react-popover";

export enum SaveButtonState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SAVED = "SAVED",
  ERROR = "ERROR",
}

interface SaveButtonProps {
  buttonState: SaveButtonState;
  copy?: {
    save?: ReactNode;
    saved?: ReactNode;
  };
  onSave: () => void;
  onExpand?: () => void;
}

export function SaveButton({ buttonState, copy, onSave, onExpand }: SaveButtonProps) {
  const { t } = useTranslation();

  const {
    save: saveCopy = t("sites.save_site.save_site_dropdown.save"),
    saved: savedCopy = t("sites.save_site.save_site_dropdown.saved"),
  } = copy || {};

  const isDisabled = [
    SaveButtonState.LOADING,
    SaveButtonState.SAVED,
    SaveButtonState.ERROR,
  ].includes(buttonState);
  const isExpandable =
    onExpand && ![SaveButtonState.SAVED, SaveButtonState.ERROR].includes(buttonState);

  return (
    <Tooltip.Wrapper>
      <Tooltip.Trigger>
        <div
          data-testid="tooltip-trigger"
          className={cn("atlas-flex atlas-items-stretch ", {
            "*:atlas-px-2 first:*:atlas-pl-3 last:*:atlas-pr-3 first:*:atlas-rounded-r-none last:*:atlas-rounded-l-none":
              isExpandable,
          })}
        >
          <Button
            onClick={onSave}
            disabled={isDisabled}
            data-testid="save-site-outer"
            className="!atlas-pr-1 md:!atlas-pr-3"
          >
            {buttonState === SaveButtonState.ERROR ? (
              saveCopy
            ) : buttonState === SaveButtonState.LOADING ? (
              <Loading relativeSizing startColour="neutral-100" finalColour="neutral-300" />
            ) : buttonState === SaveButtonState.SAVED ? (
              savedCopy
            ) : (
              saveCopy
            )}
          </Button>

          {isExpandable && (
            <Popover.Trigger asChild>
              <Button disabled={isDisabled} onClick={onExpand} data-testid="select-stage-button">
                <i className="atlas-flex atlas-text-xl atlas-h-5 atlas-text-white icon-lt-arrow-down-s-line" />
              </Button>
            </Popover.Trigger>
          )}
        </div>
      </Tooltip.Trigger>

      {buttonState === SaveButtonState.ERROR && (
        <Tooltip.Content
          sideOffset={0}
          showArrow={true}
          theme={Theme.Dark}
          align="start"
          side="bottom"
        >
          {t("sites.save_site.error_body")}
        </Tooltip.Content>
      )}
    </Tooltip.Wrapper>
  );
}
