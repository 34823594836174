import { useCallback } from "react";
import lodash from "lodash";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { TopographySelectionSidebarInnerProps } from "./types";
import { SlopeSummary } from "./SlopeSummary";
import { AspectSummary } from "./AspectSummary";
import { Tabs } from "./Tabs";

const cardName = "Topography";

export function TopographyBreakdown({ selection, onLoaded }: TopographySelectionSidebarInnerProps) {
  const onTopographyTabClicked = useCallback((tabValue: string) => {
    const tabName = lodash.capitalize(tabValue);

    logEventV2({
      name: "Workbench card click",
      properties: {
        cardName,
        action: "goTo",
        clickDetail: tabName,
      },
    });
  }, []);

  return (
    <Tabs defaultValue="slope" className="atlas-w-full">
      <Tabs.List>
        <Tabs.Trigger value="slope" subtitle="Change of elevation" onClick={onTopographyTabClicked}>
          Slope
        </Tabs.Trigger>
        <Tabs.Trigger value="aspect" subtitle="Direction of slope" onClick={onTopographyTabClicked}>
          Aspect
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="slope">
        <div className="atlas-flex atlas-flex-col atlas-gap-y-6 atlas-pt-3">
          <SlopeSummary selection={selection} onLoaded={onLoaded} />
        </div>
      </Tabs.Content>

      <Tabs.Content value="aspect">
        <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-pt-2 atlas-items-center">
          <AspectSummary selection={selection} onLoaded={onLoaded} />
        </div>
      </Tabs.Content>
    </Tabs>
  );
}
