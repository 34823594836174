import buffer from "@turf/buffer";
import { useQuery } from "@apollo/client";
import { SelectionGeometry } from "src/js/stores/map/store";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { FLOOD_ZONE_CATEGORIES, RISK_OF_FLOODING_CATEGORIES } from "../constants";
import FLOODING_SUMMARY_QUERY from "./FloodingSummaryQuery.gql";
import { sortByCategoryList } from "./sortByCategoryList";
import { useMemo } from "react";

export interface DesignationArea {
  category_coverage_percentage: number;
  sub_category: string;
  display_name: string;
}

interface FloodingSummaryQueryResult {
  floodRiskCoverage: DesignationArea[];
  floodZoneCoverage: DesignationArea[];
}

interface FloodingSummaryQueryVariables {
  geometry: SelectionGeometry;
  floodRiskCategories: string[];
  floodZoneCategories: string[];
}

export function useFloodingSummaryQuery(geometry: SelectionGeometry | null | undefined) {
  const bufferedGeometry = geometry && bufferGeometry(geometry);

  const { data, ...rest } = useQuery<FloodingSummaryQueryResult, FloodingSummaryQueryVariables>(
    FLOODING_SUMMARY_QUERY,
    {
      variables: {
        geometry: bufferedGeometry!,
        floodRiskCategories: RISK_OF_FLOODING_CATEGORIES,
        floodZoneCategories: FLOOD_ZONE_CATEGORIES,
      },
      skip: !bufferedGeometry,
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Constraints,
      },
    }
  );

  const { floodZoneCoverage, floodRiskCoverage } = useMemo(
    () => ({
      floodZoneCoverage: sortByCategoryList(data?.floodZoneCoverage, FLOOD_ZONE_CATEGORIES),
      floodRiskCoverage: sortByCategoryList(data?.floodRiskCoverage, RISK_OF_FLOODING_CATEGORIES),
    }),
    [data]
  );

  return { floodRiskCoverage, floodZoneCoverage, ...rest };
}

/**
 * Want to ensure that point/line selections result in a flooding overlap
 */
function bufferGeometry(geometry: SelectionGeometry): SelectionGeometry {
  if (["Polygon", "MultiPolygon"].includes(geometry.type)) return geometry;

  return buffer(geometry, 1, { units: "meters" }).geometry;
}
