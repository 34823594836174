import classNames from "classnames";
import { PropsWithChildren } from "react";

import { useDropdownMenuContext } from "../../DropdownMenuProvider";

export type DropdownMenuItemProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  "data-testid"?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const DropdownMenuItem = ({
  children,
  onClick,
  className: additionalClasses,
  "data-testid": dataTestId,
  disabled,
  style: additionalStyles,
}: PropsWithChildren<DropdownMenuItemProps>) => {
  const { closeMenu } = useDropdownMenuContext();

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(event);
    closeMenu();
  };

  return (
    <div
      data-testid={dataTestId ?? "dropdown-menu-item"}
      onClick={handleOnClick}
      className={classNames(
        "atlas-rounded",
        "atlas-p-2",
        "atlas-m-2",
        !disabled && "atlas-cursor-pointer",
        !disabled && "hover:atlas-bg-neutral-100",
        !disabled && "focus:atlas-bg-neutral-100",
        disabled && "atlas-text-text-base-primary-disabled",
        additionalClasses
      )}
      style={{
        // this logic is here and not in className because jest doesn't apply tailwind classes when running tests
        pointerEvents: disabled ? "none" : undefined,
        ...additionalStyles,
      }}
    >
      {children}
    </div>
  );
};
