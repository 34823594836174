import clsx from "clsx";
import { StatusDerived } from "../../types";

interface StatusBadgeProps {
  status: StatusDerived;
}

export const StatusBadge = ({ status }: StatusBadgeProps) => (
  <div
    className={clsx("atlas-w-3 atlas-h-3 atlas-rounded-full", {
      "atlas-bg-amber-400": status === StatusDerived.PENDING,
      "atlas-bg-green-500": status === StatusDerived.APPROVED,
      "atlas-bg-red-500": status === StatusDerived.REJECTED,
      "atlas-bg-platform-400": status === StatusDerived.WITHDRAWN,
      "atlas-bg-blueGrey-400": status === StatusDerived.UNKNOWN,
    })}
  />
);
