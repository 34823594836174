import { siteFiltersMapStore } from "react-migration/domains/sites/pipeline/filters/SiteFiltersStore/store";
import { useCallback, useEffect, useRef } from "react";
import type { LayerTypeControlPageProps } from "../../../types";
import { SiteFiltersView } from "src/js/view/sites/logSiteCardEvent";
import { Switch } from "react-migration/components/Switch";
import cn from "clsx";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";
import { refreshSiteGeometryStore } from "src/js/stores/siteGeometry/actions/refreshSiteGeometryStore";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import {
  SiteFilters,
  SiteFiltersLayout,
} from "react-migration/domains/sites/pipeline/filters/SiteFilters";
import { useControlTracking } from "../../../ControlTrackingContext";
import { logEvent } from "react-migration/lib/util/logEvent";

export function SitesControlPage({ disabled }: LayerTypeControlPageProps) {
  const isInitialRender = useRef(true);
  const { debouncedTrackControlChange } = useControlTracking();

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  const {
    googleMap,
    settings: { showHiddenSites, mapType, state, activeLayer, zoom },
  } = useSnapshot(mapStore);

  const handleShowHiddenSitesChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showHiddenSites", filterState: !showHiddenSites });
    updateMapSettings("showHiddenSites", !showHiddenSites);
    logEvent("Toggle Site Visibility Map", {
      show: !showHiddenSites,
      mapType: mapType,
      state: state,
      layer: activeLayer,
      zoom: zoom,
    });
  }, [debouncedTrackControlChange, showHiddenSites, mapType, state, activeLayer, zoom]);

  // here we subscribe to any changes in the site filters store, triggering an update whenever any filters change
  // Note these values are currently reset everytime we enter the map page while the users adjust to site filters
  const siteFiltersStoreValues = useSnapshot(siteFiltersMapStore);

  useEffect(() => {
    // we only need to refetch sites if the filters change
    // this was getting triggered on first render
    if (!isInitialRender.current) {
      googleMap && refreshSiteGeometryStore(googleMap);
    }
  }, [siteFiltersStoreValues, googleMap]);

  return (
    <>
      <div className="atlas-flex atlas-justify-between atlas-items-center atlas-rounded atlas-relative atlas-border-b atlas-border-neutral-200 atlas-pb-4">
        <label
          htmlFor="hidden-sites"
          className={cn("atlas-block atlas-text-sm atlas-font-medium", {
            "atlas-opacity-50": disabled,
          })}
        >
          Show hidden sites
        </label>
        <Switch
          label="Show hidden sites"
          id="show-hidden-sites"
          disabled={disabled}
          onToggle={handleShowHiddenSitesChange}
          value={showHiddenSites}
        />
      </div>
      <div className="atlas-px-2 atlas-py-3 atlas-rounded">
        <SiteFilters
          layout={SiteFiltersLayout.Grid}
          eventMetadata={{
            view: SiteFiltersView.MAP,
          }}
          store={siteFiltersMapStore}
        />
      </div>
    </>
  );
}
