export const RISK_OF_FLOODING_CATEGORIES = [
  "high_risk_of_flooding_from_rivers_and_seas",
  "medium_risk_of_flooding_from_rivers_and_seas",
  "low_risk_of_flooding_from_rivers_and_seas",
  "very_low_risk_of_flooding_from_rivers_and_seas",
];

export const FLOOD_ZONE_CATEGORIES = [
  "flood_zone_3_env_agency",
  "flood_zone_3b",
  "flood_zone_3a",
  "flood_zone_2_env_agency",
  "flood_zone_2",
  "flood_zone",
];
