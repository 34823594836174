import { useState } from "react";
import { reverse } from "ramda";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { isDefined } from "react-migration/lib/util/isDefined";
import { Select } from "react-migration/components/Select/Select";
import { LayerTypeSelectionDetailViewProps } from "../../../types";
import { AttributeComparisonSummaryProps } from "./types";
import { useDesignationIntersections } from "./useDesignationIntersectionsQuery";
import { AttributeComparisonSummaryUS } from "./AttributeComparisonSummaryUS";
import { AttributeComparisonSummaryUK } from "./AttributeComparisonSummaryUK";
import { DemographicsDialog } from "../DemographicsDialog";
import { DemographicsRegion } from "../types";

export type AttributeComparisonSummarySpec = {
  outputAreaCategoryHierarchy: ConstraintsCategory[];
  AttributeComparisonSummary: React.ComponentType<AttributeComparisonSummaryProps>;
};

export const attributeComparisonSpecMap: Record<
  DemographicsRegion,
  AttributeComparisonSummarySpec
> = {
  UK: {
    outputAreaCategoryHierarchy: [
      ConstraintsCategory.UK_DEMOGRAPHICS_LSOA,
      ConstraintsCategory.UK_DEMOGRAPHICS_MSOA,
      ConstraintsCategory.UK_DEMOGRAPHICS_LAD,
      ConstraintsCategory.UK_DEMOGRAPHICS_REGION,
      ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY,
    ],
    AttributeComparisonSummary: AttributeComparisonSummaryUK,
  },
  US: {
    outputAreaCategoryHierarchy: [
      ConstraintsCategory.US_TRACT_DEMOGRAPHICS,
      ConstraintsCategory.US_COUNTY_DEMOGRAPHICS,
      ConstraintsCategory.US_STATE_DEMOGRAPHICS,
    ],
    AttributeComparisonSummary: AttributeComparisonSummaryUS,
  },
};

export function OutputAreaDetailView(props: LayerTypeSelectionDetailViewProps) {
  //can this be hook?
  const { selection } = props;
  const { designation: selectedDesignation, loading: designationLoading } = useDesignationById(
    selection.id
  );

  const [region, attributeComparisonSpec] =
    (
      Object.entries(attributeComparisonSpecMap) as [
        DemographicsRegion,
        AttributeComparisonSummarySpec
      ][]
    ).find(([, { outputAreaCategoryHierarchy }]) =>
      outputAreaCategoryHierarchy.includes(
        selectedDesignation?.sub_category_id as ConstraintsCategory
      )
    ) || [];

  const superiorOutputAreaCategories = attributeComparisonSpec?.outputAreaCategoryHierarchy.slice(
    attributeComparisonSpec.outputAreaCategoryHierarchy.indexOf(
      selectedDesignation?.sub_category_id as ConstraintsCategory
    ) + 1
  );

  const { designations: intersectingDesignations, loading: intersectionsLoading } =
    useDesignationIntersections({
      id: selectedDesignation?.id,
      bufferMeters: -5,
      rootCategories: superiorOutputAreaCategories || [],
      skip: !selectedDesignation?.id || designationLoading,
    });

  if (designationLoading || intersectionsLoading || !selectedDesignation) {
    return <Loading />;
  }

  if (!attributeComparisonSpec) return null;

  return (
    <OutputAreaSummaryComparison
      region={region}
      outputArea={selectedDesignation}
      superiorOutputAreas={intersectingDesignations}
      attributeComparisonSummarySpec={attributeComparisonSpec}
    />
  );
}

interface OutputAreaSummaryComparisonProps {
  region?: DemographicsRegion;
  outputArea: SingleDesignation;
  superiorOutputAreas: SingleDesignation[];
  attributeComparisonSummarySpec: AttributeComparisonSummarySpec;
}

export function OutputAreaSummaryComparison({
  region,
  outputArea,
  superiorOutputAreas,
  attributeComparisonSummarySpec,
}: OutputAreaSummaryComparisonProps) {
  const { outputAreaCategoryHierarchy, AttributeComparisonSummary } =
    attributeComparisonSummarySpec;

  const comparableDesignations = reverse(outputAreaCategoryHierarchy)
    .flatMap((category) => superiorOutputAreas.filter((oa) => oa.sub_category?.id === category))
    .filter(isDefined);

  const [selectedId, setSelectedId] = useState<string>(comparableDesignations[0]?.id);

  const comparableDesignation = comparableDesignations.find((oa) => oa.id === selectedId);

  return (
    <div className="atlas-flex atlas-flex-col atlas-p-2 atlas-bg-neutral-50">
      {!!superiorOutputAreas.length && (
        <div className="atlas-sticky atlas-top-0 atlas-left-0 atlas-z-10 atlas-bg-neutral-50 atlas-border-b atlas-border-neutral-200 atlas-mx-[-8px] atlas-mt-[-8px] atlas-p-2 atlas-flex atlas-flex-row atlas-gap-x-2 atlas-items-center">
          <label className="atlas-shrink-0">Compare to</label>
          <div className="atlas-shrink atlas-grow atlas-overflow-hidden">
            <OutputAreaSelect
              value={selectedId}
              setValue={setSelectedId}
              disabled={superiorOutputAreas.length <= 1}
              superiorOutputAreas={superiorOutputAreas}
              outputAreaCategoryHierarchy={outputAreaCategoryHierarchy}
            />
          </div>
          {region && <DemographicsDialog region={region} />}
        </div>
      )}

      <div className="atlas-flex atlas-flex-col atlas-divide-y atlas-divide-solid atlas-divide-neutral-200">
        <AttributeComparisonSummary
          outputArea={outputArea}
          comparableDesignation={comparableDesignation}
          allOutputAreas={comparableDesignations}
        />
      </div>
    </div>
  );
}

interface OutputAreaSelectProps {
  value: string;
  setValue: (v: string) => void;
  disabled: boolean;
  superiorOutputAreas: SingleDesignation[];
  outputAreaCategoryHierarchy: ConstraintsCategory[];
}

function OutputAreaSelect({
  value,
  setValue,
  disabled,
  superiorOutputAreas,
  outputAreaCategoryHierarchy,
}: OutputAreaSelectProps) {
  return (
    <Select value={value} onValueChange={setValue} disabled={disabled}>
      {outputAreaCategoryHierarchy
        .flatMap((c) => superiorOutputAreas.filter((oa) => oa.sub_category?.id === c))
        .filter(isDefined)
        .reverse()
        .map((outputArea) => (
          <Select.Option key={outputArea.id} value={outputArea.id}>
            {`${outputArea.display_name || outputArea.key_code} (${
              outputArea.sub_category?.display_name
            })`}
          </Select.Option>
        ))}
    </Select>
  );
}

const Loading = () => (
  <div className="atlas-overflow-auto atlas-bg-neutral-50">
    <div className="atlas-relative atlas-min-h-[250px] atlas-m-2 atlas-rounded">
      <SkeletonLoading rows={3} showPadding={false} bgClassName="atlas-bg-neutral-50" />
    </div>
  </div>
);
