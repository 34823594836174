import { LayerType } from "../../types";
import { ConstraintsLayerType } from "../ConstraintsLayerType";
import { FloodingLayerSummary } from "./FloodingLayerSummary";

export const FloodingLayerType: LayerType = {
  ...ConstraintsLayerType,
  // todo, have own id? Right now the constraints provider which this needs uses
  // this id to filter the layers to those it cares about
  id: ConstraintsLayerType.id,
  LayerSummary: FloodingLayerSummary,
};
