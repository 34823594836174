import { InsightSummaryLearnMoreTooltip } from "react-migration/components/InsightSummaryLearnMoreTooltip";

export const PlanningSummaryLearnMore = () => (
  <InsightSummaryLearnMoreTooltip>
    <p className="atlas-my-2">
      The <span className="atlas-font-bold">Planning Application History Insight</span> provides an
      overview of the planning activity on a site over the past five years. It includes:
    </p>
    <ul className="atlas-list-disc atlas-pl-9 atlas-my-2">
      <li>
        <span className="atlas-font-bold">Total Applications:</span> Displays the total number of
        planning applications submitted during this period.
      </li>
      <li>
        <span className="atlas-font-bold">Application Classification Mix:</span> Highlights the
        proportions of different application classification (Residential, Commercial, Mixed, Other)
        to show dominant trends.
      </li>
      <li>
        <span className="atlas-font-bold">Application Outcome Mix:</span> Summarises the outcomes of
        applications (Approved, Rejected, Withdrawn, Pending, Unknown) to help assess approval rates
        and barriers.
      </li>
    </ul>
    <p className="atlas-my-2">
      This insight offers valuable context for evaluating a site&#39;s planning potential and
      history.
    </p>
  </InsightSummaryLearnMoreTooltip>
);
