import { DownloadOption } from "./DownloadOption";
import { RadioGroup } from "react-migration/components/RadioGroup";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PaymentSummary, PaymentSummaryUS } from "../components/PaymentSummary";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export enum SendOption {
  Send = "send",
  Download = "download",
}

export type SendOptionsProps = {
  sendOption: SendOption;
  onSendOptionChange: (option: SendOption) => void;
  setBalance: (balance: number) => void;
  setLetterCost: (totalCost: number) => void;
};

export const SendOptions = ({
  sendOption,
  onSendOptionChange,
  setBalance,
  setLetterCost,
}: SendOptionsProps) => {
  const { t } = useTranslation();

  const isUsUser = hasFeature(Feature.usAccess);
  const PaymentSummaryComponent = isUsUser ? PaymentSummaryUS : PaymentSummary;

  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-grow",
        "atlas-overflow-hidden",
        "atlas-bg-background-surface-neutral",
        "atlas-justify-center",
        "atlas-p-4"
      )}
      data-testid="confirm-and-send-content-container"
    >
      <div
        data-testid="confirm-and-send-content"
        className={classNames("atlas-flex", "atlas-max-w-[1144px]", "atlas-flex-col", "atlas-grow")}
      >
        <div
          className={classNames("atlas-flex", "atlas-flex-col")}
          data-testid="confirm-and-send-header-section"
        >
          <span
            className={classNames(
              "atlas-text-2xl",
              "atlas-font-semibold",
              "atlas-text-text-base-primary"
            )}
          >
            {t("sites.letters.confirm_and_send")}
          </span>
          <span
            className={classNames(
              "atlas-text-base",
              "atlas-font-semibold",
              "atlas-text-text-base-secondary",
              "atlas-mt-7",
              "atlas-mb-3"
            )}
          >
            {t("sites.letters.how_would_you_like_to_send_your_letter")}
          </span>
        </div>
        <div className={classNames("atlas-flex", "atlas-flex-row", "atlas-gap-5")}>
          <div className={classNames("atlas-flex", "atlas-flex-col", "atlas-gap-5", "atlas-grow")}>
            <div
              data-testid="confirm-and-send-select-section"
              className={classNames(
                "atlas-bg-background-surface",
                "atlas-border",
                "atlas-border-border-action",
                "atlas-rounded-lg"
              )}
            >
              <RadioGroup value={sendOption} onValueChange={onSendOptionChange}>
                <div
                  className={classNames(
                    "atlas-flex",
                    "atlas-flex-row",
                    "atlas-items-center",
                    "atlas-gap-3",
                    "atlas-p-4",
                    "atlas-border-b",
                    "atlas-border-border-action",
                    "atlas-cursor-pointer"
                  )}
                  data-testid="send-option-send"
                  onClick={() => {
                    onSendOptionChange(SendOption.Send);
                  }}
                >
                  <RadioGroup.Item value={SendOption.Send} className="!atlas-h-4 !atlas-w-4">
                    <RadioGroup.Indicator />
                  </RadioGroup.Item>
                  <div
                    className={classNames(
                      "atlas-h-10",
                      "atlas-w-10",
                      "atlas-flex",
                      "atlas-items-center",
                      "atlas-justify-center",
                      "atlas-border-platform-50",
                      "atlas-bg-platform-100",
                      "atlas-bg-platform-100",
                      "atlas-border-4",
                      "atlas-rounded-full"
                    )}
                  >
                    <i
                      className={classNames(
                        "icon-lt-mail-send-line",
                        "atlas-text-base",
                        "atlas-text-platform-600"
                      )}
                    />
                  </div>
                  <div className={classNames("atlas-flex", "atlas-flex-col")}>
                    <span
                      className={classNames(
                        "atlas-text-text-base-primary",
                        "atlas-text-sm",
                        "atlas-font-semibold",
                        "atlas-leading-5"
                      )}
                    >
                      {t("sites.letters.send_via_company_name")}
                    </span>
                    <span
                      className={classNames(
                        "atlas-text-text-base-secondary",
                        "atlas-text-sm",
                        "atlas-font-normal",
                        "atlas-leading-5"
                      )}
                    >
                      {t("sites.letters.save_time_by_letting_us_post_the_letter_for_you")}
                    </span>
                  </div>
                </div>
                <DownloadOption
                  onClick={() => {
                    onSendOptionChange(SendOption.Download);
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <div data-testid="confirm-and-send-letter-summary-section">
            <PaymentSummaryComponent
              onBalanceLoaded={setBalance}
              onTotalCostCalculated={setLetterCost}
              sendOption={sendOption}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
