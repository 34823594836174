import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { comparablesStore } from "src/js/stores/comparables";
import { SelectionType } from "src/js/stores/map/store";
import { useSelectionSidebarLoading } from "react-migration/layouts/map/Multilayer/Bundle/useSelectionSidebarLoadingState";
import { logEvent } from "react-migration/lib/util/logEvent";
import { LayerTypeSelectionSidebarProps } from "../../types";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { ComparablesSelectionSidebarHeader } from "./ComparablesSelectionSidebarHeader";
import { useTransactionsAtSelection } from "./useTransactionsAtSelection";
import { TransactionsAccordion } from "./TransactionsAccordion";

function ComparablesSelectionSidebarInner({
  selection,
  setDetailSelection,
  cardExpanded,
  onLoadingChange,
}: LayerTypeSelectionSidebarProps) {
  const { includedResults, loading, priceMode, selectRecord, distribution } =
    useTransactionsAtSelection(selection, setDetailSelection);

  const { filters } = useSnapshot(comparablesStore);

  useEffect(() => {
    logEvent("Comparables Filters on point select", Object.assign({ ...filters, priceMode }));
  }, [filters, priceMode]);

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (includedResults.length === 0) return null;

  return (
    <CollapsibleConsiderationsCard
      heading={<ComparablesSelectionSidebarHeader count={includedResults.length} />}
      name="Comparables"
      defaultExpanded={cardExpanded}
    >
      {includedResults.length > 0 && (
        <TransactionsAccordion
          includedResults={includedResults}
          loading={loading}
          setSelectedRecord={selectRecord}
          priceMode={priceMode}
          distribution={distribution.data}
        />
      )}
    </CollapsibleConsiderationsCard>
  );
}

export function ComparablesSelectionSidebar(props: LayerTypeSelectionSidebarProps) {
  if (
    [
      SelectionType.LANDTECH_COMPARABLE_TRANSACTION,
      SelectionType.HOMETRACK_COMPARABLE_TRANSACTION,
    ].includes(props.selection.type)
  ) {
    return null;
  }

  return <ComparablesSelectionSidebarInner {...props} />;
}
