import { useCallback, useMemo, useState } from "react";
import { SiteReportLoadingState } from "./SiteReportContent";
import { AssessmentTab } from "./useAssessmentTabs";

export enum TabLoadingState {
  LOADING = "loading",
  ERROR = "error",
  READY = "ready",
}

export function useReportLoadingState() {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [loadingState, setReportLoadingState] = useState<SiteReportLoadingState>({});

  const getTabLoadingState = useCallback(
    ({ layers }: AssessmentTab) => {
      let hasLoadingLayers = false;
      let hasLayersWithErrors = false;

      for (const layer of layers) {
        const layerState = loadingState[layer.id];

        if (layerState?.loading) {
          hasLoadingLayers = true;
        }

        if (layerState?.error) {
          hasLayersWithErrors = true;
        }
      }

      if (hasLoadingLayers) {
        return TabLoadingState.LOADING;
      }
      if (hasLayersWithErrors) {
        return TabLoadingState.ERROR;
      }
      return TabLoadingState.READY;
    },
    [loadingState]
  );

  const getLayerLoadingState = useCallback(
    (id: string) => {
      return loadingState[id];
    },
    [loadingState]
  );

  const isReadyToDownload = useMemo(() => {
    return Object.values(loadingState).every(
      (layerLoadingState) => !layerLoadingState.mounted || !layerLoadingState.loading
    );
  }, [loadingState]);

  return {
    hasLoaded,
    isReadyToDownload,
    setHasLoaded,
    getTabLoadingState,
    getLayerLoadingState,
    setReportLoadingState,
  };
}
