import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";

export const NoDataSitesAlerts = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    router.push({
      name: ROUTES.SITES,
    });
  };
  return (
    <span className="atlas-mt-2 atlas-text-base">
      {t("dashboard.planning_alerts.create_new_alert", {
        savedSiteLink: (
          <span
            onClick={handleClick}
            className="atlas-text-platform-600 atlas-cursor-pointer hover:atlas-underline"
          >
            {t("dashboard.planning_alerts.saved_sites_pipeline")}
          </span>
        ),
      })}
    </span>
  );
};
