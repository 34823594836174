import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const SkipTraceValidationError = ({ errorMessage }: { errorMessage: string | null }) => {
  const { t } = useTranslation();

  if (!errorMessage) {
    return null;
  }

  return (
    <div
      className={classNames("atlas-flex", "atlas-flex-row", "atlas-gap-2", "atlas-items-center")}
      data-testid="create-skip-trace-validation-error"
    >
      <i
        className={classNames(
          "icon-lt-error-warning-line",
          "atlas-text-background-surface-destructive-accent",
          "atlas-text-2xl"
        )}
        data-testid="error-icon"
      />
      <span
        className={classNames(
          "atlas-text-background-surface-destructive-accent",
          "atlas-font-semibold"
        )}
      >
        {t(errorMessage)}
      </span>
    </div>
  );
};
