import { useCallback, useMemo, useRef, useState } from "react";
import { PageControls } from "react-migration/components/PageControls";
import { PlanningApplicationDetailMiniCard } from "react-migration/domains/planning/components/PlanningApplicationDetailMiniCard";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { useRefElementSize } from "react-migration/lib/util/useElementSize";

const CAROUSEL_GAP = 8;

interface MostRecentSectionProps {
  applications: PlanningApplication[];
  onTitleClick: (application: PlanningApplication) => void;
}

export const MostRecentSection = ({ applications, onTitleClick }: MostRecentSectionProps) => {
  const applicationsOrderedByDateReceived = useMemo(
    () =>
      applications.slice().sort((a, b) => +new Date(b.date_received) - +new Date(a.date_received)),
    [applications]
  );

  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollEndTimeoutRef = useRef<number | null>(null);
  const { width: cardWidth } = useRefElementSize(scrollableRef);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const scrollToCardIndex = useCallback(
    (index: number) => {
      if (!scrollableRef.current) return;

      const scrollOffset = index * (cardWidth + CAROUSEL_GAP);

      setCurrentCardIndex(index);
      scrollableRef.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    },
    [cardWidth]
  );

  const onScrollHandler = useCallback(() => {
    function snapToClosestCard() {
      if (!scrollableRef.current) return;

      const { scrollLeft } = scrollableRef.current;
      const closestCardIndex = Math.round(scrollLeft / (cardWidth + CAROUSEL_GAP));

      scrollToCardIndex(closestCardIndex);
    }

    scrollEndTimeoutRef.current && clearTimeout(scrollEndTimeoutRef.current);
    scrollEndTimeoutRef.current = setTimeout(snapToClosestCard, 100) as unknown as number;
  }, [cardWidth, scrollToCardIndex]);

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      {applicationsOrderedByDateReceived.length > 1 && (
        // negative margin used to pull pagination controls into section header
        <div className="atlas-self-end -atlas-mt-11">
          <PageControls
            onNextPage={() => scrollToCardIndex(currentCardIndex + 1)}
            onPreviousPage={() => scrollToCardIndex(currentCardIndex - 1)}
            page={currentCardIndex + 1}
            totalPages={applications.length}
          />
        </div>
      )}
      <div
        className="atlas-flex atlas-flex-row atlas-gap-x-2 atlas-overflow-x-auto atlas-overflow-y-hidden"
        style={{ columnGap: `${CAROUSEL_GAP}px` }}
        onScroll={onScrollHandler}
        ref={scrollableRef}
      >
        {applicationsOrderedByDateReceived.map((application) => (
          <div
            key={application.id}
            className="atlas-grow-0 atlas-shrink-0"
            style={{ width: `${cardWidth}px` }}
          >
            <PlanningApplicationDetailMiniCard
              application={application}
              onTitleClick={() => onTitleClick(application)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
