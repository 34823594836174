import { Select } from "react-migration/components/Select";
import { LayerTypeControlPageProps } from "../../../types";
import { useTrackedTopographyLayerFilter } from "../useTrackedTopographyLayerFilter";
import { TopographyLayerDialog } from "../TopographyLayerDialog";
import { ResolutionBadge } from "../ResolutionBadge";
import { TopographyLayer } from "../constants";
import { TopographySlopeMapLegend } from "./TopographySlopeMapLegend";
import { TopographyAspectMapLegend } from "./TopographyAspectMapLegend";

export function UKTopographyControlPage({ disabled }: LayerTypeControlPageProps) {
  const { handleLayerChange, selectedLayer } = useTrackedTopographyLayerFilter();

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <TopographyLayerDialog region="UK" disabled={disabled} label="Display by" />
      <Select value={selectedLayer} disabled={disabled} onValueChange={handleLayerChange}>
        <Select.Option badge={<ResolutionBadge resolution="1m" />} value={TopographyLayer.slope}>
          Slope
        </Select.Option>

        <Select.Option badge={<ResolutionBadge resolution="1m" />} value={TopographyLayer.aspect}>
          Aspect
        </Select.Option>
        <Select.Separator />
        <Select.Option
          badge={<ResolutionBadge resolution="1m" />}
          value={TopographyLayer.hillshade}
        >
          Hillshade
        </Select.Option>
        <Select.Option badge={<ResolutionBadge resolution="10m" />} value={TopographyLayer.contour}>
          Contour
        </Select.Option>
        <Select.Option
          badge={<ResolutionBadge resolution="1m & 10m" />}
          value={TopographyLayer.hillshadeContour}
        >
          Hillshade &amp; Contour
        </Select.Option>
      </Select>
      {selectedLayer === TopographyLayer.slope && <TopographySlopeMapLegend />}
      {selectedLayer === TopographyLayer.aspect && <TopographyAspectMapLegend />}
    </div>
  );
}
