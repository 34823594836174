import { SiteFeature } from "src/js/stores/siteGeometry/store";
import { SiteHatch } from "./SiteHatch";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSiteStagesById } from "react-migration/domains/sites/hooks/useSiteStages";

interface SiteButtonProps {
  site: SiteFeature;
  onClick(): void;
}

export function SiteButton({ site, onClick }: SiteButtonProps) {
  const { t } = useTranslation();
  const stagesById = useSiteStagesById();
  const stage = stagesById[site.properties._stage._id];
  const stageName = stage?.name || "…";
  const stageHidden = stage?.hidden ?? false;

  return (
    <div className="atlas-flex">
      <button
        key={site.id}
        data-testid="site-button"
        title={site.properties.title}
        className="atlas-w-full atlas-p-1.5 atlas-gap-x-2 atlas-border-solid hover:atlas-bg-neutral-100 atlas-flex atlas-justify-start atlas-text-left atlas-text-text-base-active hover:atlas-text-text-base-active-hover atlas-items-start"
        onClick={onClick}
      >
        <SiteHatch stageColor={site.properties.color} />
        <div className="atlas-flex atlas-flex-col atlas-overflow-hidden atlas-grow">
          <span className="atlas-truncate">{site.properties.title}</span>
          <span className="atlas-items-center atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary">
            <span className="atlas-truncate">{stageName}</span>
            <span>{stageHidden && ` ${t("sites.save_site.save_site_dropdown.hidden")}`}</span>
          </span>
        </div>
      </button>
    </div>
  );
}
