import { Icon } from "react-migration/components/Icon";
import { LayerTypeSelectionHeaderProps } from "../../../types";
import { usePropertyInformationByUPRN } from "./usePropertyInformationByUPRN";

export function UKPropertyInformationSelectionHeader({ selection }: LayerTypeSelectionHeaderProps) {
  const { data } = usePropertyInformationByUPRN(selection?.id);

  const { uprn, full_address } = data?.propertyInformationByUPRN.address || {};

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2">
      <Icon icon="icon-lt-community-line" size="lg" extraClasses="atlas-align-text-top" />
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-text-base-tertiary atlas-uppercase">
          Property
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
          {full_address || uprn}
        </div>
      </div>
    </div>
  );
}
