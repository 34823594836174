import type { LayerTypeSelectionSidebarProps } from "../../types";
import { mapStore, SelectionType } from "src/js/stores/map/store";
import { SiteButton } from "./SiteButton";
import { intersectionSignificanceOrdering } from "../../geometry_utilities";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useIntersectingSitesInViewport } from "./useIntersectingSitesInViewport";
import { useMemo } from "react";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { useSiteStagesById } from "react-migration/domains/sites/hooks/useSiteStages";
import { useSnapshot } from "valtio";
import { SaveSiteLayer } from "react-migration/domains/sites/typings/SaveSiteLayer";

export function SitesSelectionSidebar({
  layer,
  cardExpanded,
  selection,
  onSelection,
}: LayerTypeSelectionSidebarProps) {
  const sites = useIntersectingSitesInViewport(selection?.feature?.geometry);

  const stagesById = useSiteStagesById();
  const { settings } = useSnapshot(mapStore);
  const { showHiddenSites } = settings;

  const filteredSites = useMemo(() => {
    if (!selection.feature?.geometry) return [];
    const selectedSiteIDs = [selection, ...(selection.subselections || [])]
      .filter((s) => s.type === SelectionType.SITE)
      .map((s) => s.id);

    const filtered = sites
      .filter((site) => !selectedSiteIDs.includes(site.id))
      .filter((site) => {
        const stage = stagesById[site.properties._stage._id];
        const siteHidden = site.properties.hidden || stage?.hidden;
        const showSite = showHiddenSites || !siteHidden;

        return showSite;
      });

    return intersectionSignificanceOrdering(
      selection.feature.geometry,
      filtered,
      (site) => site.geometry
    );
  }, [selection, sites, stagesById, showHiddenSites]);

  if (!filteredSites?.length) return null;

  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      count={filteredSites.length}
      defaultExpanded={cardExpanded}
    >
      <div className="atlas-bg-white atlas-m-2 atlas-mt-1 atlas-rounded atlas-overflow-hidden atlas-border atlas-border-border-action atlas-divide-y atlas-divide-border-base-divider">
        {filteredSites.map((site) => (
          <SiteButton
            key={site.id}
            site={site}
            onClick={() => {
              logEvent("Part of Saved Site Click", {
                layer: SaveSiteLayer.Constraints,
                id: site.id,
              });

              onSelection({
                type: SelectionType.SITE,
                id: site.id,
                savable: false,
                feature: site,
              });
            }}
          />
        ))}
      </div>
    </CollapsibleConsiderationsCard>
  );
}
