import { LtIconsId } from "@landtechnologies/icons/font/lt-icons";
import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

export const IconAnimations = ["spin"];
type IconAnimation = (typeof IconAnimations)[number];
export const ButtonVariants = ["primary", "secondary", "tertiary", "ghost"];
type ButtonVariant = (typeof ButtonVariants)[number];

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  error?: boolean;
  leadIcon?: LtIconsId;
  leadIconAnimation?: IconAnimation;
  tailIcon?: LtIconsId;
  tailIconAnimation?: IconAnimation;
  variant?: ButtonVariant;
};

const primaryStyles = clsx(
  "atlas-text-text-base-inverted atlas-bg-background-button-primary atlas-ring-border-button-focus",
  "enabled:hover:atlas-bg-background-button-primary-hover",
  "enabled:active:atlas-bg-background-button-primary-pressed",
  "enabled:focus:atlas-ring-2",
  "disabled:atlas-bg-background-button-primary-disabled disabled:atlas-text-text-base-primary-disabled",
  "data-[error=true]:atlas-bg-negative-600 data-[error=true]:hover:atlas-bg-negative-700 data-[error=true]:focus:atlas-bg-negative-800 data-[error=true]:atlas-ring-border-action-destructive"
);

const secondaryStyles = clsx(
  "atlas-text-text-base-primary atlas-bg-background-button-secondary atlas-ring-border-button-secondary atlas-ring-2",
  "enabled:hover:atlas-bg-background-button-secondary-hover hover:atlas-ring-border-button-secondary-hover",
  "enabled:active:atlas-bg-background-button-secondary-pressed active:atlas-ring-border-button-secondary-pressed",
  "enabled:focus:atlas-ring-border-button-focus",
  "disabled:atlas-bg-background-button-secondary-disabled disabled:atlas-ring-border-button-disabled disabled:atlas-text-text-base-secondary-disabled"
);
const tertiaryStyles = clsx(
  "atlas-text-text-base-primary atlas-bg-background-button-tertiary atlas-ring-border-button-focus",
  "enabled:hover:atlas-bg-background-button-tertiary-hover",
  "enabled:active:atlas-bg-background-button-tertiary-pressed",
  "enabled:focus:atlas-ring-border-button-focus",
  "disabled:atlas-bg-background-button-tertiary-disabled disabled:atlas-text-text-base-primary-disabled"
);
const ghostStyles = clsx(
  "atlas-bg-transparent atlas-ring-border-button-focus/20",
  "enabled:hover:atlas-bg-background-button-tertiary",
  "enabled:active:atlas-bg-background-button-tertiary-hover",
  "enabled:focus:atlas-ring-2",
  "disabled:atlas-text-text-base-primary-disabled"
);

const variants: { [key: ButtonVariant]: string } = {
  primary: primaryStyles,
  secondary: secondaryStyles,
  tertiary: tertiaryStyles,
  ghost: ghostStyles,
};

/**
 * The default button used in the app. Supports:
 *
 * - a number of variants (primary, secondary, tertiary, ghost)
 * - a number of states (disabled, error)
 * - lead and trailing icons that can be animated independently
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = "primary",
      className,
      error,
      leadIcon,
      tailIcon,
      leadIconAnimation,
      tailIconAnimation,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        className={clsx(
          "atlas-inline-flex atlas-items-center atlas-justify-center atlas-relative atlas-isolate atlas-gap-x-2 atlas-px-2 atlas-py-2 atlas-rounded-lg atlas-text-sm ring-inset",
          variants[variant],
          {
            "atlas-cursor-pointer": !rest.disabled,
            "atlas-shadow-sm": variant !== "ghost",
          },
          className
        )}
        ref={ref}
        data-error={error}
        {...rest}
      >
        {leadIcon && <ButtonIcon icon={leadIcon} animation={leadIconAnimation} />}
        {children}
        {tailIcon && <ButtonIcon icon={tailIcon} animation={tailIconAnimation} />}
      </button>
    );
  }
);

interface ButtonIconProps {
  icon: LtIconsId;
  animation?: IconAnimation;
}

function ButtonIcon({ icon, animation }: ButtonIconProps) {
  return (
    <i
      className={clsx("atlas-text-lg atlas-leading-5", `icon-${icon}`, {
        "atlas-animate-spin": animation === "spin",
      })}
    />
  );
}

Button.displayName = "Button";
