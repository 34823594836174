import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { convertToYearsMonths, dateString } from "src/js/util/dates";

type NullishString = string | null | undefined;
type FormatHousingLandSupplyType = { hsStatus?: NullishString; date?: NullishString };
type FormatHousingLandSupplyReturnType = {
  body: string;
  status?: InsightStatusType;
  footerValue: string;
};

export const formatHousingLandSupply = ({
  hsStatus,
  date,
}: FormatHousingLandSupplyType): FormatHousingLandSupplyReturnType => {
  const { years } = convertToYearsMonths(Number(hsStatus) || 0);

  return {
    footerValue: date ? dateString(date) : "-",
    body: hsStatus ? `${years} year${years === 1 ? "" : "s"}` : "Unknown",
    // Housing Land Supply over 5 years
    status: hsStatus ? (Number(hsStatus) > 5 ? "red" : "green") : undefined,
  };
};
