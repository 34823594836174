import { HTMLProps } from "react";
import { DataPanel } from "react-migration/components/DataPanel";

type WrapperType = HTMLProps<HTMLDivElement> & { title: string; tooltip?: string };
export const Wrapper = ({ title, tooltip, children }: WrapperType) => {
  return (
    <DataPanel.Cell
      title={title}
      tooltip={tooltip}
      variant="light"
      className="atlas-px-4 atlas-py-4 atlas-rounded-xl"
    >
      {children}
    </DataPanel.Cell>
  );
};
