import { PropsWithChildren, RefObject, SyntheticEvent, forwardRef, useRef } from "react";

import classNames from "classnames";
import { useDropdownMenuContext } from "../../DropdownMenuProvider";

export enum DropdownMenuTriggerAlignment {
  RIGHT = "right",
  BOTTOM = "bottom",
  BOTTOM_LEFT = "bottom_left",
  LEFT = "left",
}

export type DropdownMenuTriggerProps = React.ButtonHTMLAttributes<HTMLDivElement> & {
  "data-testid"?: string;
  align?: DropdownMenuTriggerAlignment;
};

export const DropdownMenuTrigger = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DropdownMenuTriggerProps>
>(
  (
    {
      className: additionalClasses,
      "data-testid": dataTestId,
      align = DropdownMenuTriggerAlignment.RIGHT,
      children,
      ...props
    },
    forwardedRef
  ) => {
    const triggerRef = useRef<HTMLDivElement>(null);
    const { isOpen, openMenu, closeMenu } = useDropdownMenuContext();

    const handleOnClick = (event: SyntheticEvent) => {
      event.stopPropagation();
      if (isOpen === true) {
        closeMenu();
      } else {
        openMenu(triggerRef as RefObject<HTMLDivElement>, align);
      }
    };

    return (
      <div
        ref={forwardedRef ?? triggerRef}
        data-testid={dataTestId ?? "dropdown-menu-trigger"}
        className={classNames("border-transparent", "bg-transparent", additionalClasses)}
        onClick={handleOnClick}
        {...props}
      >
        {children}
      </div>
    );
  }
);

DropdownMenuTrigger.displayName = "DropdownMenuTrigger";
