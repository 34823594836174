import clsx from "clsx";
import { Button } from "../Button";
import React from "react";

export enum NotificationSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum NotificationIcon {
  warning = "icon-lt-alert-line",
  info = "icon-lt-information-line",
  check = "icon-lt-check-line",
  question = "icon-lt-question-line",
  error = "icon-lt-error-warning-line",
}
enum NotificationType {
  dark,
  success,
  error,
  info,
  warning,
}

type NotificationAction = {
  text: string;
  onClick: () => void;
};

export type NotificationProps = {
  title: string;
  message?: string | React.ReactNode;
  icon?: keyof typeof NotificationIcon;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: keyof typeof NotificationSize;
  closable?: boolean;
  className?: string;
  additionalClassName?: string;
  type?: keyof typeof NotificationType;
  primaryAction?: NotificationAction;
  secondaryAction?: NotificationAction;
};

const sharedClassnames =
  "atlas-pl-3 atlas-pr-2 atlas-flex  atlas-min-w-[40%] atlas-max-w-[600px] atlas-text-center atlas-text-sm atlas-font-medium atlas-leading-5 atlas-border atlas-border-solid atlas-rounded-lg";

const Light = ({
  title,
  message,
  icon,
  onClickClose,
  size = "small",
  closable,
  className,
  additionalClassName,
  type,
  primaryAction,
  secondaryAction,
}: NotificationProps) => {
  const containerClass = clsx(
    sharedClassnames,
    additionalClassName,
    {
      ["atlas-py-2 atlas-min-h-[56px] aatlas-text-xs atlas-leading-3"]: size === "small",
      ["atlas-py-4 atlas-min-h-[72px] aatlas-text-sm atlas-leading-3"]: size === "medium",
      ["atlas-py-4 atlas-min-h-[72px] aatlas-text-base atlas-leading-3 atlas-border-l-8"]:
        size === "large",
    },
    className
  );

  const textClass = clsx("atlas-m-0 atlas-text-sm atlas-font-semibold atlas-leading-4", {
    ["atlas-leading-3"]: size === "small",
    ["atlas-leading-4"]: size === "medium" || size === "large",
  });

  const iconClass = clsx(
    "atlas-pr-1 atlas-text-sm atlas-font-medium atlas-leading-4",
    {
      ["atlas-leading-3"]: size === "small",
      ["atlas-leading-4"]: size === "medium" || size === "large",
    },
    {
      "atlas-text-green-800": type === "success",
      "atlas-text-white": type === "dark",
      "atlas-text-red-700": type === "error",
      "atlas-text-blue-700": type === "info",
      "atlas-text-amber-600": type === "warning",
    }
  );

  return (
    <div className={containerClass} data-testid="li-react-notification">
      <div className="atlas-flex atlas-flex-row">
        {icon && (
          <div className={iconClass}>
            <i className={NotificationIcon[icon]} />
          </div>
        )}
        <div className="atlas-flex atlas-flex-col atlas-grow">
          <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-grow atlas-text-left">
            <p className={textClass}>{title}</p>
          </div>
          {message && (
            <p className="atlas-m-0 atlas-mr-5 atlas-pt-1 atlas-leading-4 atlas-font-normal atlas-text-start">
              {message}
            </p>
          )}
        </div>
        {primaryAction && <Button onClick={primaryAction.onClick}>{primaryAction.text}</Button>}
        {secondaryAction && (
          <Button
            variant="secondary"
            onClick={secondaryAction.onClick}
            className="atlas-text-neutral-800"
          >
            {secondaryAction.text}
          </Button>
        )}
        {closable && onClickClose && (
          <div>
            <button
              className="atlas-flex atlas-flex-wrap atlas-content-center"
              onClick={onClickClose}
            >
              <i aria-label="Close" role="img" className="icon-lt-close-line" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Error = ({ ...rest }: NotificationProps) => {
  const errorClass = "atlas-bg-red-50 atlas-text-neutral-800 atlas-border-red-700";
  return <Light className={errorClass} type="error" {...rest} />;
};

const Dark = ({ ...rest }: NotificationProps) => {
  const darkClass = "atlas-bg-neutral-700 atlas-text-white atlas-border-neutral-800";
  return <Light className={darkClass} type="dark" {...rest} />;
};

const Success = ({ ...rest }: NotificationProps) => {
  const successClass = "atlas-bg-green-50 atlas-text-neutral-800 atlas-border-green-800";
  return <Light className={successClass} type="success" {...rest} />;
};

const Warning = ({ ...rest }: NotificationProps) => {
  const warningClass = "atlas-bg-amber-50 atlas-text-neutral-800 atlas-border-amber-500";
  return <Light className={warningClass} type="warning" {...rest} />;
};

const Info = ({ ...rest }: NotificationProps) => {
  const infoClass = "atlas-bg-blue-100 atlas-text-neutral-800 atlas-border-platform-500";
  return <Light className={infoClass} type="info" {...rest} />;
};

export const Notification = {
  Info,
  Warning,
  Error,
  Light,
  Dark,
  Success,
};
