import { HTMLProps } from "react";
import clsx from "clsx";

import { ArrowRight } from "../Icons";

const AnchorWrapper = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-col atlas-p-4 atlas-w-screen md:atlas-w-full" {...rest}>
      {children}
    </div>
  );
};

const AnchorBody = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-col md:atlas-flex-row atlas-gap-4" {...rest}>
      {children}
    </div>
  );
};

const AnchorHeader = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-flex atlas-flex-col atlas-border-b-2 atlas-border-neutral-50 atlas-mb-4 atlas-cursor-pointer"
      {...rest}
    >
      {children}
    </div>
  );
};

const AnchorTitle = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-flex atlas-flex-row atlas-items-center atlas-mb-1 atlas-text-platform-700 atlas-text-md atlas-font-bold atlas-gap-1"
      {...rest}
    >
      {children} <ArrowRight />
    </div>
  );
};
const AnchorSubtitle = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-text-sm atlas-text-neutral-500 atlas-mb-2" {...rest}>
      {children}
    </div>
  );
};

const AnchorColumn = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-flex atlas-flex-col atlas-bg-neutral-50 atlas-p-4 atlas-grow atlas-rounded atlas-gap-4 lg:atlas-min-w-80"
      {...rest}
    >
      {children}
    </div>
  );
};

const AnchorColumnTitle = ({ children, className, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={clsx("atlas-flex atlas-flex-col atlas-bg-neutral-50 atlas-font-bold", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export const Anchor = {
  Wrapper: AnchorWrapper,
  Header: AnchorHeader,
  Title: AnchorTitle,
  Subtitle: AnchorSubtitle,
  Body: AnchorBody,
  Column: AnchorColumn,
  ColumnTitle: AnchorColumnTitle,
};
