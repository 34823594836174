import { useCallback } from "react";

import clsx from "clsx";

import { NumericInput } from "react-migration/components/NumericInput";
import { DividerV2 } from "react-migration/components/DividerV2";

import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { CheckboxTree, CheckboxTreeChange } from "react-migration/components/CheckboxTree";
import { planningTypes } from "react-migration/domains/planning/constants";
import { PlanningStatus, planningStatuses } from "react-migration/domains/planning/types";
import { DebouncedState } from "react-migration/lib/util/useDebounceCallback";

import { TrackControlChange } from "../../../ControlTrackingContext/ControlTrackingContext";
import { PlanningState, PlanningStateDispatch } from "../state/usePlanningFilterState";

interface PlanningApplicationsFiltersProps {
  disabled?: boolean;
  columns?: boolean;
  state: PlanningState;
  stateDispatch: PlanningStateDispatch;
  debouncedTrackControlChange: DebouncedState<TrackControlChange>;
}

const statusFilters = {
  id: "enabledStatuses",
  display_name: "Planning Statuses",
  childNodes: planningStatuses.map((status) => ({
    id: status.id,
    display_name: status.name,
    childNodes: [],
  })),
};
const STATUS_SCHEMA = statusFilters.childNodes.map((node) => ({ key: node.id }));

const typeFilters = {
  display_name: "Planning Types",
  id: "enabledTypes",
  childNodes: planningTypes.map((type) => ({
    id: type.id,
    display_name: type.name,
    childNodes: [],
  })),
};
const TYPES_SCHEMA = typeFilters.childNodes.map((node) => ({ key: node.id }));

export function PlanningApplicationsFilters({
  disabled,
  columns,
  state,
  stateDispatch,
  debouncedTrackControlChange,
}: PlanningApplicationsFiltersProps) {
  const { t } = useTranslation();
  const hasPlanningFilters = hasFeature(Feature.planningFilters);

  const { maxYear, minSize, enabledStatuses, enabledTypes } = state;

  const handleYearsChange = useCallback(
    (value: number) => {
      debouncedTrackControlChange({ filterName: "years", filterState: value });
      stateDispatch({ type: "SET_YEARS", value });
    },
    [stateDispatch, debouncedTrackControlChange]
  );

  const handleHomeCountChange = useCallback(
    (value: number) => {
      debouncedTrackControlChange({ filterName: "homeCount", filterState: value });
      stateDispatch({ type: "SET_HOMES", value });
    },
    [stateDispatch, debouncedTrackControlChange]
  );

  const handleStatusChange = useCallback<CheckboxTreeChange>(
    ({ visibleNodeIds }) => {
      debouncedTrackControlChange({ filterName: "status", filterState: visibleNodeIds });

      return stateDispatch({
        type: "SET_ENABLED_STATUSES",
        value: visibleNodeIds as PlanningStatus[],
      });
    },
    [stateDispatch, debouncedTrackControlChange]
  );

  const handleTypeChange = useCallback<CheckboxTreeChange>(
    ({ visibleNodeIds }) => {
      debouncedTrackControlChange({ filterName: "type", filterState: visibleNodeIds });
      return stateDispatch({
        type: "SET_ENABLED_TYPES",
        value: visibleNodeIds as PlanningStatus[],
      });
    },
    [stateDispatch, debouncedTrackControlChange]
  );

  return (
    <div className="atlas-space-y-2">
      <div className="atlas-flex-col atlas-space-y-3 atlas-text-sm atlas-pt-2 atlas-pb-4 atlas-border-b atlas-border-neutral-200">
        <h1 className={clsx("atlas-text-sm", { "atlas-opacity-50": disabled })}>Filters:</h1>
        <div
          className={clsx({
            "atlas-columns-2": columns,
          })}
        >
          <div
            className={clsx("atlas-flex atlas-items-center", {
              "atlas-justify-between": !columns,
              "atlas-flex-wrap": columns,
            })}
          >
            <div
              className={clsx("atlas-text-sm atlas-font-normal", { "atlas-opacity-50": disabled })}
            >
              Applications within the last{" "}
            </div>
            <div className="atlas-flex atlas-items-center atlas-gap-2">
              <NumericInput
                isDisabled={disabled}
                className="atlas-max-w-[80px]"
                size="small"
                value={maxYear}
                minValue={0}
                maxValue={35}
                onChange={handleYearsChange}
              />

              <div className={clsx({ "atlas-opacity-50": disabled })}>years</div>
            </div>
          </div>

          <div
            className={clsx("atlas-flex atlas-items-center", {
              "atlas-justify-between atlas-mt-3": !columns,
              "atlas-flex-wrap": columns,
            })}
          >
            <div
              className={clsx("atlas-text-sm atlas-font-normal", { "atlas-opacity-50": disabled })}
            >
              Number of homes
            </div>
            <div className="atlas-flex atlas-items-center atlas-gap-2">
              <NumericInput
                isDisabled={disabled}
                className="atlas-max-w-[65px]"
                size="small"
                value={minSize}
                minValue={0}
                onChange={handleHomeCountChange}
              />
              <div className={clsx({ "atlas-opacity-50": disabled })}>or more</div>
            </div>
          </div>
        </div>

        {hasPlanningFilters && (
          <div className={clsx({ "atlas-opacity-50": disabled })}>
            <DividerV2 />
            <CheckboxTree
              title={<h1 className="atlas-text-sm">{t("components.planning_record.status")}</h1>}
              treeStructure={statusFilters}
              treeSchema={STATUS_SCHEMA}
              visibleNodeIds={enabledStatuses}
              onChange={handleStatusChange}
              disabled={disabled}
              columns={columns}
            />
            <div className="atlas-mt-2">
              <DividerV2 />
            </div>
            <CheckboxTree
              title={<h1 className="atlas-text-sm">{t("components.planning_record.type")}</h1>}
              treeStructure={typeFilters}
              treeSchema={TYPES_SCHEMA}
              visibleNodeIds={enabledTypes}
              onChange={handleTypeChange}
              disabled={disabled}
              columns={columns}
            />
          </div>
        )}
      </div>
    </div>
  );
}
