import clsx from "clsx";
import { Tag, TagColor } from "react-migration/components/Tag";
import { DataPanel } from "react-migration/components/DataPanel";
import { protectedSmallDateFormatter } from "../../utils/protectedDateFormatter";
import { usePlanningTypesDisplay } from "../../utils/usePlanningTypesDisplay";
import { PlanningApplication } from "../../types";
import { getStatusTagColor } from "../../utils/colors";
import { ClassificationBadge } from "../ClassificationBadge";
import { AppealTag } from "../AppealTag";

export interface PlanningApplicationDetailMiniCardProps {
  onTitleClick?: () => void;
  application: Pick<
    PlanningApplication,
    | "ref"
    | "title"
    | "address"
    | "classification"
    | "types_derived"
    | "status_derived"
    | "date_received"
    | "appeal_decision"
  >;
}

export function PlanningApplicationDetailMiniCard({
  onTitleClick,
  application: {
    ref,
    title,
    classification,
    address,
    status_derived,
    types_derived,
    date_received,
    appeal_decision,
  },
}: PlanningApplicationDetailMiniCardProps) {
  const statusTagColor = getStatusTagColor(status_derived) || TagColor.DEFAULT;
  const typesDisplay = usePlanningTypesDisplay(types_derived);
  const dateDisplay = protectedSmallDateFormatter(date_received);

  return (
    <article
      className="atlas-bg-white atlas-border atlas-border-border-action atlas-rounded-xl atlas-p-4 atlas-flex atlas-flex-col atlas-cursor-default"
      data-testid="planning-application-detail-mini-card"
    >
      <div className="atlas-flex atlas-flex-row atlas-gap-x-2">
        <ClassificationBadge classification={classification} />
        <h1
          onClick={onTitleClick}
          className={clsx(
            "atlas-text-sm atlas-font-medium atlas-text-text-base-active atlas-truncate",
            {
              "hover:atlas-text-text-base-active-hover atlas-cursor-pointer": !!onTitleClick,
            }
          )}
        >
          {ref}
        </h1>
        {appeal_decision && (
          <div>
            <AppealTag appealDecision={appeal_decision} />
          </div>
        )}
      </div>
      <div className="atlas-mt-1">
        <p
          title={title}
          className="atlas-text-sm atlas-font-normal atlas-text-black atlas-line-clamp-2 atlas-h-10"
        >
          {title}
        </p>
        <p
          title={address}
          className="atlas-text-xs atlas-font-normal atlas-text-text-base-secondary-a atlas-line-clamp-1"
        >
          {address}
        </p>
      </div>
      <DataPanel.Grid columns={3}>
        <DataPanel.Cell title="Status">
          <Tag color={statusTagColor} tag={`status: ${status_derived}`} />
        </DataPanel.Cell>
        <DataPanel.Cell title="Type">
          <span title={`types: ${typesDisplay}`} className="atlas-line-clamp-1 atlas-text-sm">
            {typesDisplay}
          </span>
        </DataPanel.Cell>
        <DataPanel.Cell title="Received">
          <span
            title={`date received: ${dateDisplay}`}
            className="atlas-line-clamp-1 atlas-text-sm"
          >
            {dateDisplay}
          </span>
        </DataPanel.Cell>
      </DataPanel.Grid>
    </article>
  );
}
