import { useMemo, useState } from "react";
import { DetailsList } from "react-migration/components/DetailsList";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { CompanySection } from "../CompanySection";
import { LoadingPanel } from "../LoadingPanel";
import { TitlesSection } from "../TitlesSection";
import { useCompanyUltimateOwnersQuery } from "../useCompanyUltimateOwnersQuery";
import { PurchaseTitle } from "../PurchaseTitle";
import { PRICE_PER_TITLE_GBP } from "src/js/util/units_and_constants";
import { PurchaseTitleModal } from "../PurchaseTitleModal";
import { isDefined } from "react-migration/lib/util/isDefined";
import { uniqueTitlesById } from "../useTitleFeatures";
import { CompanyOwner, PersonOwner, isCompanyOwner, isPersonOwner } from "../useSelectedOwner";
import { PurchaseLocations } from "../PurchaseTitleModal/types";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { setShowUpgradeModal } from "src/js/stores/navigation/actions";
import { Title } from "react-migration/domains/ownership/typings/Title";
import { TransactionPurchaseType } from "react-migration/domains/ownership/typings/Transaction";

type OwnerDetailPageProps = {
  titles: Title[];
  selectedOwner?: CompanyOwner | PersonOwner;
  selectedTitleNumber?: string;
};

export function OwnerDetailPage({
  titles,
  selectedOwner,
  selectedTitleNumber,
}: OwnerDetailPageProps) {
  return (
    <div className="atlas-bg-white atlas-divide-y atlas-divide-border-base-divider">
      {isCompanyOwner(selectedOwner) && <CompanyOwnership companyOwner={selectedOwner} />}
      {isPersonOwner(selectedOwner) && <PersonalOwnership personOwner={selectedOwner} />}

      <TitlesSection titles={titles} selectedTitleNumber={selectedTitleNumber} />

      {!isCompanyOwner(selectedOwner) && titles.length > 1 && <PurchaseAllTitles titles={titles} />}
    </div>
  );
}

interface CompanyOwnershipProps {
  companyOwner: CompanyOwner;
}

function CompanyOwnership({ companyOwner }: CompanyOwnershipProps) {
  const { t } = useTranslation();
  const { company, ultimateOwner, loading } = useCompanyUltimateOwnersQuery(
    companyOwner.company.company_no
  );

  // companyMeta.company can be null, as can the company coming from the ultimateOwnerQuery
  // we only want to show the owner details if one is not null.
  const mergedCompany = (companyOwner || company) && Object.assign({}, companyOwner, company);

  if (loading) {
    return <LoadingPanel />;
  }

  return (
    <>
      {mergedCompany && (
        <CompanySection
          company={mergedCompany}
          proprietorshipAdded={companyOwner.date_proprietor_added}
        />
      )}
      {ultimateOwner && (
        <CompanySection
          heading={t("ownership.react.map.sidebar.ultimate_owner_details.heading")}
          company={ultimateOwner}
        />
      )}
    </>
  );
}

interface PersonalOwnershipProps {
  personOwner: PersonOwner;
}

function PersonalOwnership({ personOwner }: PersonalOwnershipProps) {
  const { person } = personOwner;
  const { t } = useTranslation();

  return (
    <div className="atlas-space-y-2 atlas-p-2">
      <div className="atlas-font-semibold atlas-text-sm atlas-text-text-base-tertiary atlas-mb-3">
        {t("ownership.react.map.sidebar.individual_owner")}
      </div>
      <DetailsList>
        <DetailsList.Item label="Name">{person.name}</DetailsList.Item>
        <DetailsList.Item label="Address">{person.address}</DetailsList.Item>
      </DetailsList>
    </div>
  );
}

interface PurchaseAllTitlesProps {
  titles: Title[];
}

function PurchaseAllTitles({ titles }: PurchaseAllTitlesProps) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const titlesToPurchase = useMemo(
    () =>
      uniqueTitlesById(titles)
        .map((title) => title.title_no)
        .filter(isDefined),
    [titles]
  );

  return (
    <>
      <div className="atlas-p-2 atlas-flex atlas-flex-col">
        <div className="atlas-font-semibold atlas-text-sm atlas-mb-3 atlas-text-text-base-tertiary">
          {t("ownership.titles_section.purchase_all_titles")}
        </div>

        <PurchaseTitle.Wrapper icon="icon-lt-file-copy-line-document">
          <PurchaseTitle.Heading>
            {t("ownership.titles_section.purchase_all_heading", {
              length: titlesToPurchase.length,
            })}
          </PurchaseTitle.Heading>
          <PurchaseTitle.Price
            onClick={() =>
              hasFeature(Feature.documentPurchase) ? setShowModal(true) : setShowUpgradeModal(true)
            }
          >
            {t("ownership.titles_section.purchase_all_for_price", {
              price: titlesToPurchase.length * PRICE_PER_TITLE_GBP,
            })}
          </PurchaseTitle.Price>
        </PurchaseTitle.Wrapper>
      </div>
      {showModal && (
        <PurchaseTitleModal
          title={t("ownership.titles_section.purchase_title_register_multiple")}
          titlesToPurchase={titlesToPurchase}
          onCancel={() => setShowModal(false)}
          transactionPurchaseType={TransactionPurchaseType.TITLE_REGISTER_DOCUMENT}
          location={PurchaseLocations.Map}
        />
      )}
    </>
  );
}
