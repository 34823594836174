import { includes } from "lodash";
import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { DesignationArea } from "./useFloodingSummaryQuery";

export function useFloodingKeyIndicators(floodRiskCoverageCategories?: DesignationArea[]) {
  const { t } = useTranslation();

  useKeyIndicatorTags(
    function setKeyIndicators() {
      const intersecting = floodRiskCoverageCategories?.map((area) => area.sub_category) || [];

      if (includes(intersecting, "high_risk_of_flooding_from_rivers_and_seas")) {
        return [
          {
            tag: t(
              "workbench.summary.flooding.risk_level.high_risk_of_flooding_from_rivers_and_seas"
            ),
            color: KeyIndicatorColor.RED,
          },
        ];
      } else if (includes(intersecting, "medium_risk_of_flooding_from_rivers_and_seas")) {
        return [
          {
            tag: t(
              "workbench.summary.flooding.risk_level.medium_risk_of_flooding_from_rivers_and_seas"
            ),
            color: KeyIndicatorColor.ORANGE,
          },
        ];
      } else if (includes(intersecting, "low_risk_of_flooding_from_rivers_and_seas")) {
        return [
          {
            tag: t(
              "workbench.summary.flooding.risk_level.low_risk_of_flooding_from_rivers_and_seas"
            ),
            color: KeyIndicatorColor.DEFAULT,
          },
        ];
      } else if (includes(intersecting, "very_low_risk_of_flooding_from_rivers_and_seas")) {
        return [
          {
            tag: t(
              "workbench.summary.flooding.risk_level.very_low_risk_of_flooding_from_rivers_and_seas"
            ),
            color: KeyIndicatorColor.DEFAULT,
          },
        ];
      } else {
        return [{ tag: "No risk", color: KeyIndicatorColor.GREEN }];
      }
    },
    [floodRiskCoverageCategories, t]
  );
}
