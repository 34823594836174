import { Tooltip } from "react-migration/components/Tooltip";
import type { RecursiveTree, HatchComponent } from "../types";
import { Theme } from "react-migration/lib/theme/Theme";

export type LabelItem = {
  label: JSX.Element;
};

export interface LabelTreeRecursiveProps {
  Hatch?: HatchComponent;
}

export type LabelTreeProps = {
  display_name: string;
  description?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillColorOverride?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lineColorOverride?: any;
};

export function labelTreeRecursive<U>(
  tree: RecursiveTree<U & LabelTreeProps>,
  props: LabelTreeRecursiveProps
): RecursiveTree<LabelItem & U> {
  const { Hatch } = props;

  const childNodes = tree.childNodes.map((n) => labelTreeRecursive(n, props));

  const isLeafNode = !childNodes.length;
  let label = <>{tree.display_name}</>;

  if (isLeafNode) {
    label = (
      <div className="atlas-flex atlas-gap-2 atlas-w-11/12">
        {Hatch && (
          <div className="atlas-flex-shrink-0 empty:atlas-hidden">
            <Hatch
              id={tree.id}
              fillColorOverride={tree.fillColorOverride}
              lineColorOverride={tree.lineColorOverride}
            />
          </div>
        )}
        <div className="atlas-flex-shrink atlas-text-ellipsis atlas-overflow-hidden">
          <Tooltip.Wrapper>
            <Tooltip.Content align="start" side="bottom" theme={Theme.Dark} size="sm">
              <Tooltip.Header>{tree.display_name}</Tooltip.Header>
              <Tooltip.Description>{tree.description}</Tooltip.Description>
            </Tooltip.Content>
            <Tooltip.Trigger>
              <div>{label}</div>
            </Tooltip.Trigger>
          </Tooltip.Wrapper>
        </div>
      </div>
    );
  }

  return {
    ...tree,
    childNodes,
    label,
  };
}
