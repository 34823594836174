import { createContext, PropsWithChildren, useContext, useState } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import cn from "clsx";
import { Theme } from "react-migration/lib/theme/Theme";
import { ContentProvider, useContentProvider } from "./ContentProvider";

type TooltipTriggerContextType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TooltipTriggerContext = createContext<TooltipTriggerContextType>({
  open: false,
  setOpen: () => false,
});

// https://www.radix-ui.com/primitives/docs/components/tooltip#provider
type WrapperProps = Pick<RadixTooltip.TooltipProviderProps, "children"> &
  Partial<Pick<RadixTooltip.TooltipProviderProps, "delayDuration">>;

function Wrapper({ children, ...props }: WrapperProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <RadixTooltip.Provider {...props}>
      <RadixTooltip.Root
        onOpenChange={(e) => {
          setOpen(e);
        }}
        open={open}
      >
        <TooltipTriggerContext.Provider value={{ open, setOpen }}>
          {children}
        </TooltipTriggerContext.Provider>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

// https://www.radix-ui.com/primitives/docs/components/tooltip#content
type ContentProps = Pick<RadixTooltip.TooltipContentProps, "children"> &
  Partial<Pick<RadixTooltip.TooltipContentProps, "align" | "side" | "sideOffset">> & {
    /** Size of tooltip.  */
    size?: "xs" | "sm" | "m" | "lg";
    /** Conditionally render tooltip arrow. */
    showArrow?: boolean;
    /** Control tooltip theme. */
    theme?: Theme;
    /** Clamp tooltip width. */
    clampWidth?: boolean;
  };

function Content({
  children,
  showArrow = true,
  theme = Theme.Light,
  sideOffset = 8,
  clampWidth = true,
  size = "m",
  ...contentProps
}: ContentProps) {
  return (
    <RadixTooltip.Content {...contentProps} sideOffset={sideOffset} className="atlas-z-50">
      <ContentProvider.Provider value={{ theme }}>
        <section
          className={cn("atlas-shadow-md atlas-rounded atlas-text-xs atlas-text-left", {
            "atlas-px-2.5 atlas-py-1": size === "xs",
            "atlas-px-3 atlas-py-2": size === "sm",
            "atlas-px-5 atlas-py-3": size === "m",
            "atlas-px-6 atlas-py-4": size === "lg",
            "atlas-bg-neutral-700 atlas-text-white": theme === Theme.Dark,
            "atlas-bg-white atlas-text-text-base-primary": theme === Theme.Light,
            "atlas-max-w-xs": clampWidth,
          })}
        >
          {children}
          {showArrow && (
            <RadixTooltip.Arrow
              className={cn("atlas-w-3 atlas-h-1.5", {
                "atlas-fill-neutral-700": theme === Theme.Dark,
                "atlas-fill-white": theme === Theme.Light,
              })}
            />
          )}
        </section>
      </ContentProvider.Provider>
    </RadixTooltip.Content>
  );
}

function Trigger(props: RadixTooltip.TooltipTriggerProps) {
  const { setOpen } = useContext(TooltipTriggerContext);
  return (
    <RadixTooltip.Trigger
      asChild
      onClick={(event) => {
        props.onClick?.(event);
        window.matchMedia && !window.matchMedia("(hover: hover)").matches && setOpen(true);
      }}
      {...props}
    />
  );
}

function Header({ children }: PropsWithChildren) {
  return <h1 className="atlas-text-sm atlas-font-semibold atlas-mb-1">{children}</h1>;
}

function Description({ children }: PropsWithChildren) {
  const { theme } = useContentProvider();

  return (
    <p
      className={cn("atlas-m-0 atlas-text-xs", {
        "atlas-text-text-base-secondary": theme === Theme.Light,
        "atlas-text-neutral-200": theme === Theme.Dark,
      })}
    >
      {children}
    </p>
  );
}

/**
 * "Radix Powered" Tooltip component.
 * https://www.radix-ui.com/primitives/docs/components/tooltip
 *
 * 🚨 Radix components do not play nicely with Vuera in production, or the way we render React in Vue.
 * If you think this is going to be a problem, please use `components/DeprecatedTooltip` instead.
 */
export const Tooltip = {
  Wrapper,
  Content,
  Trigger,
  Header,
  Description,
};
