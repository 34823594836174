import clsx from "clsx";
import { HTMLProps, useMemo } from "react";

export enum AvatarSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
}

export type AvatarProps = Omit<HTMLProps<HTMLDivElement>, "size"> & {
  content?: string;
  firstName?: string;
  lastName?: string;
  size?: AvatarSize;
};

const COLOR_CLASS_NAMES = [
  "atlas-bg-red-500",
  "atlas-bg-amber-500",
  "atlas-bg-green-500",
  "atlas-bg-platformBlue-500",
  "atlas-bg-[#F096D0]",
  "atlas-bg-[#29AD98]",
  "atlas-bg-[#D862E9]",
  "atlas-bg-[#E7AB30]",
  "atlas-bg-blueGrey-500",
  "atlas-bg-marketingPurple-500",
];

const SIZES = {
  xs: "atlas-w-5 atlas-h-5 atlas-text-avatar-xs",
  sm: "atlas-w-7 atlas-h-7 atlas-text-avatar-sm",
  md: "atlas-w-9 atlas-h-9 atlas-text-avatar-md",
  lg: "atlas-w-11 atlas-h-11 atlas-text-avatar-lg",
};

export const Avatar = ({
  firstName,
  lastName,
  size = AvatarSize.XS,
  content,
  className,
  ...rest
}: AvatarProps) => {
  const initials =
    size === AvatarSize.XS ? firstName?.[0] ?? "" : (firstName?.[0] ?? "") + (lastName?.[0] ?? "");

  const colourClassName = useMemo(() => {
    if (content || !firstName || !lastName) return "atlas-bg-neutral-300";

    const numberHash = [...firstName, ...lastName].reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
    return COLOR_CLASS_NAMES[Math.abs(numberHash % COLOR_CLASS_NAMES.length)];
  }, [firstName, lastName, content]);

  return (
    <div
      className={clsx(
        SIZES[size],
        "atlas-block atlas-rounded-full atlas-overflow-hidden atlas-border-2 atlas-border-white",
        className
      )}
      data-testid="li-avatar-container"
      {...rest}
    >
      <div
        className={clsx(
          colourClassName,
          "atlas-text-neutral-50 atlas-uppercase atlas-w-full atlas-h-full atlas-flex atlas-justify-center atlas-items-center atlas-font-medium"
        )}
        data-testid="li-avatar"
      >
        {content ?? initials}
      </div>
    </div>
  );
};
