import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

enum CopyStates {
  DEFAULT = "DEFAULT",
  COPIED = "COPIED",
}

interface ClipboardCopyProps {
  text: string;
  onCopyText?: (text: string) => void;
}

export function ClipboardCopy({ text, onCopyText }: ClipboardCopyProps) {
  return (
    <div className={clsx("atlas-flex", "atlas-justify-between", "atlas-font-mono")}>
      <input
        type="text"
        readOnly
        value={text}
        className={clsx(
          "atlas-p-1",
          "atlas-w-full",
          "atlas-text-neutral-600",
          "atlas-text-xs",
          "atlas-text-clip",
          "atlas-rounded-l",
          "atlas-bg-neutral-100",
          "atlas-border",
          "atlas-border-r-0",
          "atlas-border-neutral-200"
        )}
        data-testid="clipboard-copy-text"
      />
      {navigator.clipboard && (
        <ClipboardCopyButton text={text} onCopyText={onCopyText} roundedRight />
      )}
    </div>
  );
}

interface ClipboardCopyButtonProps {
  text: string;
  onCopyText?: (text: string) => void;
  roundedLeft?: boolean;
  roundedRight?: boolean;
}

export function ClipboardCopyButton({
  text,
  roundedLeft,
  roundedRight,
  onCopyText = () => ({}),
}: ClipboardCopyButtonProps) {
  const [copyState, setCopyState] = useState<CopyStates>(CopyStates.DEFAULT);

  const copyToClipboard = useCallback(async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      setCopyState(CopyStates.COPIED);
      onCopyText(text);
    }
  }, [text, setCopyState]);

  useEffect(() => {
    if (copyState === CopyStates.COPIED) {
      const timer = setTimeout(() => setCopyState(CopyStates.DEFAULT), 1000);
      return () => clearTimeout(timer);
    }
  }, [copyState, setCopyState]);

  return (
    <button
      className={clsx(
        "atlas-flex",
        "atlas-items-center",
        "atlas-px-2",
        "atlas-border",
        "atlas-border-solid",
        "atlas-bg-neutral-100",
        {
          "atlas-border-neutral-200 focus:atlas-border-platform-500":
            copyState === CopyStates.DEFAULT,
          "atlas-border-green-600": copyState === CopyStates.COPIED,
          "atlas-rounded-l": roundedLeft,
          "atlas-rounded-r": roundedRight,
        }
      )}
      onClick={copyToClipboard}
      disabled={copyState !== CopyStates.DEFAULT}
      data-testid="clipboard-copy-button"
    >
      <i
        className={clsx({
          "atlas-text-neutral-600 icon-lt-file-copy-line": copyState === CopyStates.DEFAULT,
          "atlas-text-green-600 icon-lt-check-line": copyState === CopyStates.COPIED,
        })}
      />
    </button>
  );
}
