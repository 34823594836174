import { ComponentType, useCallback } from "react";
import { LayerTypeControlPageProps } from "../../types";
import Feature from "src/js/stores/user/Feature";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useControlTracking } from "../../ControlTrackingContext";
import { useSnapshot } from "valtio";
import { MapState } from "src/js/stores/map/store";
import { comparablesStore } from "src/js/stores/comparables";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import { PriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { updateSelection } from "src/js/stores/map/actions/updateSelection";
import {
  setPriceMode as applyPriceMode,
  setFilters,
  setPriceType,
} from "src/js/stores/comparables/actions";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { PriceMode } from "react-migration/domains/comparables/components/Visualisations/PriceMode";
import { Filters } from "react-migration/domains/comparables/components/Filters/Filters";
import { PriceType } from "react-migration/domains/comparables/components/Visualisations/PriceType";
import { CalculatorButton } from "react-migration/domains/comparables/components/Calculator/CalculatorButton";
import clsx from "clsx";

export const ComparablesControlPage: ComponentType<LayerTypeControlPageProps> = ({ disabled }) => {
  const { debouncedTrackControlChange, trackControlChange } = useControlTracking();
  const { filters, priceMode, priceType } = useSnapshot(comparablesStore);

  const setPriceMode = useCallback((updatedPriceMode: PriceModes) => {
    updateSelection();
    applyPriceMode(updatedPriceMode);
  }, []);

  const unitPreference = getMeasurementSystemPreference();
  const openCalc = useCallback(() => {
    logEvent("Comparables Calculator Open", {
      priceMode,
      priceType: priceType === 0 ? "full" : "ppa",
    });
    updateMapSettings("state", MapState.COMPARABLES_CALCULATOR);
  }, [priceMode, priceType]);

  return (
    <div className={clsx("atlas-flex atlas-flex-col", { "atlas-opacity-50": disabled })}>
      <div className="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-2 atlas-pb-4 atlas-border-b atlas-border-neutral-200">
        {hasFeature(Feature.comparablesPpSqftMode) ? (
          <PriceMode
            priceType={priceType}
            priceMode={priceMode}
            setPriceMode={setPriceMode}
            zooplaLicense={hasFeature(Feature.zooplaFullAccess)}
            eventTracking={debouncedTrackControlChange}
          />
        ) : null}
        {hasFeature(Feature.comparablesPpSqftMode) ? (
          <PriceType
            unitPreference={unitPreference}
            priceType={priceType}
            setPriceType={setPriceType}
            eventTracking={trackControlChange}
          />
        ) : null}
      </div>
      <div className="atlas-relative atlas-duration-300 atlas-transition-colors atlas-pb-4 atlas-border-b atlas-border-neutral-200">
        <Filters
          filters={filters}
          setFilters={setFilters}
          unitPreference={unitPreference}
          priceMode={priceMode}
          eventTracking={debouncedTrackControlChange}
        />
      </div>
      <div className="atlas-py-2">
        <CalculatorButton
          openCalculator={openCalc}
          hasLicense={hasFeature(Feature.comparablesCalculator)}
        />
      </div>
    </div>
  );
};
