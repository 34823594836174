import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { LayerTypeLayerSummaryProps } from "../../types";
import { DemographicsLayerSummary } from "./DemographicsLayerSummary";

export const DemographicsSummaryConsiderationsCard = (props: LayerTypeLayerSummaryProps) => {
  const { id } = props.layer;
  const summaryId = `${id}--summary`;

  return (
    <CollapsibleConsiderationsCard
      heading={`${props.layer.title} Summary`}
      name={summaryId}
      defaultExpanded={props.layer.cardExpanded}
      contentPadding={4}
    >
      <DemographicsLayerSummary {...props} />
    </CollapsibleConsiderationsCard>
  );
};
