import React, { CSSProperties, PropsWithChildren, useCallback, useMemo } from "react";
import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSelectedParcelIds } from "../../store/hooks/useSelectedParcelIds";
import { useParcels } from "../../store/hooks/useParcels";

import { SkipTraceOrderTableHeader } from "./SkipTraceOrderTableHeader";
import { SkipTracingParcel } from "react-migration/domains/sites/typings/SkipTracingParcel";
import { TableVirtuoso } from "react-virtuoso";
import classNames from "classnames";
import { translate } from "react-migration/lib/i18n/useTranslation";
import { updateSelectedParcelIds } from "../../store/actions/updateSelectedParcelIds";
import {
  SkipTraceTableBorderStyles,
  SkipTraceTableColStyles,
  SkipTraceTableFixedHeaderStyles,
  SkipTraceTableRowStyles,
} from "../../commonTableStyles";

const columnHelper = createColumnHelper<Readonly<SkipTracingParcel>>();

const CELL_STYLES: Partial<Record<keyof SkipTracingParcel, CSSProperties>> = {};

/**
 * We can't use Tanstack's internal API for initial column sizing because of this issue:
 * https://github.com/TanStack/table/discussions/3192#discussioncomment-6458134
 *
 * It was causing columns of unspecified size to not grow dynamically as the page resized
 *
 * As a workaround we are using the <colgroup> HTML api to apply widths to the table
 * This might mean we'll run into troubles if we want to enable resizing but works for now
 */
const HEADER_STYLES: Record<string, CSSProperties> = {
  checkboxes: { width: "65px" },
  siteName: { minWidth: "250px" },
  address: { minWidth: "250px" },
  parcelNumber: { width: "250px" },
  ownerNames: { minWidth: "250px" },
  email: { width: "350px" },
  phone: { width: "250px" },
  status: { width: "250px" },
};

type CellWithTooltipProps = PropsWithChildren & {
  message: string;
};

const CellWithTooltip = ({ message, children }: CellWithTooltipProps) => (
  <Tooltip.Wrapper>
    <Tooltip.Trigger>
      <div>{children}</div>
    </Tooltip.Trigger>
    <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
      <span className="atlas-whitespace-pre-line">{message}</span>
    </Tooltip.Content>
  </Tooltip.Wrapper>
);

export const SkipTraceOrderTable = () => {
  const data = useParcels();

  const selectedParcelIds = useSelectedParcelIds();

  const headerCheckboxValue: CheckboxState = useMemo(() => {
    const selectedSitesLength = Object.keys(selectedParcelIds).length;
    const eligibleSitesLength = data.filter((parcel) => parcel.address).length;

    if (selectedSitesLength === 0) {
      return CheckboxState.EMPTY;
    } else if (selectedSitesLength === eligibleSitesLength) {
      return CheckboxState.CHECKED;
    } else {
      return CheckboxState.INDETERMINATE;
    }
  }, [selectedParcelIds, data]);

  const onHeaderCheckboxChange = useCallback(() => {
    const newSelection =
      headerCheckboxValue === CheckboxState.CHECKED
        ? {}
        : data.reduce((result, item) => {
            if (item.address) {
              result[item.parcelNumber] = true;
            }
            return result;
          }, {} as Record<string, boolean>);
    updateSelectedParcelIds(newSelection);
  }, [data, headerCheckboxValue]);

  const getCellCheckboxValue = useCallback(
    (apn: string) => {
      return selectedParcelIds[apn] ? CheckboxState.CHECKED : CheckboxState.EMPTY;
    },
    [selectedParcelIds]
  );

  const onCellCheckboxChange = useCallback(
    (apn: string) => {
      const newSelection = { ...selectedParcelIds };
      if (selectedParcelIds[apn]) {
        delete newSelection[apn];
      } else {
        newSelection[apn] = true;
      }
      updateSelectedParcelIds(newSelection);
    },
    [selectedParcelIds]
  );

  const onCheckboxClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "checkboxes",
        size: 65,
        header: () => (
          <div className={classNames("atlas-flex", "atlas-items-center", "atlas-justify-center")}>
            <Checkbox
              className="atlas-cursor-pointer"
              data-testid="skip-trace-order-table-header-checkbox"
              onChange={onHeaderCheckboxChange}
              onClick={onCheckboxClick}
              value={headerCheckboxValue}
            />
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const CellCheckbox = (
            <div
              className={classNames(
                "atlas-flex",
                "atlas-items-center",
                "atlas-justify-center",
                "atlas-cursor-pointer"
              )}
            >
              <Checkbox
                className="atlas-cursor-pointer"
                data-testid="skip-trace-order-table-cell-checkbox"
                onChange={() => onCellCheckboxChange(item.parcelNumber)}
                onClick={onCheckboxClick}
                value={getCellCheckboxValue(item.parcelNumber)}
                disabled={!row.getCanSelect()}
              />
            </div>
          );

          if (!row.getCanSelect()) {
            return (
              <CellWithTooltip
                message={translate("sites.skip_tracing.order_table.this_parcel_cannot_be_selected")}
              >
                {CellCheckbox}
              </CellWithTooltip>
            );
          }
          return CellCheckbox;
        },
      }),
      columnHelper.accessor("parcelNumber", {
        id: "parcelNumber",
        header: translate("sites.skip_tracing.order_table.parcel_number"),
      }),
      columnHelper.accessor("siteName", {
        id: "siteName",
        header: translate("sites.skip_tracing.order_table.site_name"),
      }),
      columnHelper.accessor("address", {
        id: "address",
        header: translate("sites.skip_tracing.order_table.address"),
      }),
      columnHelper.accessor("ownerNames", {
        id: "ownerNames",
        header: translate("sites.skip_tracing.order_table.owners"),
        cell: ({
          row: {
            original: { ownerNames },
          },
        }) => (
          <div
            className={classNames(
              "atlas-flex",
              "atlas-flex-row",
              "atlas-flex-wrap",
              "atlas-overflow-hidden",
              "atlas-text-ellipsis"
            )}
          >
            {ownerNames?.map((name, idx) => (
              <span
                className={classNames(
                  "atlas-whitespace-nowrap",
                  "atlas-overflow-hidden",
                  "atlas-text-ellipsis",
                  "atlas-mr-1"
                )}
                key={name}
              >
                {name}
                {idx < ownerNames.length - 1 ? "," : null}
              </span>
            ))}
          </div>
        ),
      }),
      columnHelper.accessor("email", {
        id: "email",
        header: translate("sites.skip_tracing.order_table.email"),
      }),
      columnHelper.accessor("phoneNumbers", {
        id: "phone",
        header: translate("sites.skip_tracing.order_table.phone_number"),
      }),
      columnHelper.accessor("status", {
        id: "status",
        header: translate("sites.skip_tracing.order_table.status"),
      }),
    ],
    [
      getCellCheckboxValue,
      headerCheckboxValue,
      onCellCheckboxChange,
      onCheckboxClick,
      onHeaderCheckboxChange,
    ]
  );

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: (row) => !!row.original.address,
    getCoreRowModel: getCoreRowModel(),
  });

  const rows = table.getRowModel().rows;
  return (
    <div
      className={classNames(SkipTraceTableBorderStyles, "atlas-flex", "atlas-flex-col")}
      data-testid="table-container"
    >
      <SkipTraceOrderTableHeader />

      <div className="atlas-grow">
        <TableVirtuoso
          style={{ height: "100%" }}
          className="atlas-h-full atlas-border-t atlas-border-border-action"
          data={rows}
          increaseViewportBy={200}
          totalCount={data.length}
          fixedHeaderContent={() => {
            return table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={classNames(SkipTraceTableFixedHeaderStyles)}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ));
          }}
          components={{
            Table: ({ children, ...props }) => {
              return (
                <table
                  {...props}
                  className={classNames(
                    "atlas-w-full",
                    "atlas-table-fixed",
                    "atlas-border-collapse",
                    "atlas-border-spacing-0",
                    rows.length && [
                      "atlas-border-border-action",
                      "atlas-border-b",
                      "atlas-border-solid",
                    ]
                  )}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <colgroup key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <col key={header.id} style={HEADER_STYLES[header.id]} />
                      ))}
                    </colgroup>
                  ))}
                  {children}
                </table>
              );
            },
            TableRow: (props) => {
              const index = props["data-index"];
              const row = rows[index];
              if (!row) {
                return null;
              }

              return (
                <tr
                  key={row.id}
                  {...props}
                  className={classNames(SkipTraceTableRowStyles, "atlas-h-16")}
                  onClick={() =>
                    row.getCanSelect() && onCellCheckboxChange(row.original.parcelNumber)
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={classNames(
                        SkipTraceTableColStyles,
                        cell.column.id === "checkboxes" && "atlas-cursor-default"
                      )}
                      style={CELL_STYLES[cell.column.id as keyof SkipTracingParcel]}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            },
          }}
        />
      </div>
    </div>
  );
};
