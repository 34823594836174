import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const NoSearchResults = () => {
  const { t } = useTranslation();
  return (
    <div className="atlas-mt-[80px] atlas-h-full atlas-w-full atlas-flex atlas-flex-col atlas-items-center atlas-justify-center atlas-bg-background-surface">
      <div className="atlas-h-14 atlas-w-14 atlas-flex atlas-items-center atlas-justify-center atlas-border-background-surface-neutral atlas-bg-background-surface-neutral-subtle atlas-border-8 atlas-rounded-full">
        <div className="atlas-w-6 atlas-h-6">
          <SearchIcon />
        </div>
      </div>
      <div className="atlas-font-medium atlas-text-xl atlas-mt-5 atlas-text-text-base-primary">
        {t("dashboard.transactions.no_search_results")}
      </div>
      <div className="atlas-font-normal atlas-text-base atlas-mt-2 atlas-text-text-base-secondary">
        {t("dashboard.transactions.no_search_results_message")}
      </div>
    </div>
  );
};

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3421_2488)">
        <path
          fill="currentColor"
          d="M12.021 11.078l2.856 2.854-.944.944-2.854-2.856a6.002 6.002 0 01-9.745-4.687c0-3.312 2.688-6 6-6a6.002 6.002 0 014.687 9.745zm-1.337-.495a4.665 4.665 0 00-3.35-7.917 4.665 4.665 0 00-4.667 4.667 4.665 4.665 0 007.917 3.35l.1-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3421_2488">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
