export function sortByCategoryList<T extends { sub_category: string }>(
  list: T[] = [],
  orderList: string[]
) {
  return list.slice().sort((a, b) => {
    const aIndex = orderList.indexOf(a.sub_category);
    const bIndex = orderList.indexOf(b.sub_category);
    return aIndex - bIndex;
  });
}
