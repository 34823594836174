import { Tooltip } from "react-migration/components/Tooltip";
import { InfoList } from "react-migration/components/InfoList";
import { Theme } from "react-migration/lib/theme/Theme";

export interface RecordItemProps {
  title: string | JSX.Element[];
  value?: string | JSX.Element | JSX.Element[] | null;
  tooltip?: string;
}
export const RecordItem = ({ title, value, tooltip }: RecordItemProps) => {
  if (value && tooltip) {
    return (
      <Tooltip.Wrapper>
        <Tooltip.Content align="start" side="bottom" theme={Theme.Dark} size="sm">
          <Tooltip.Description>{tooltip}</Tooltip.Description>
        </Tooltip.Content>

        <InfoList.Item data-testid="record-item">
          <InfoList.ItemTitle>{title}</InfoList.ItemTitle>
          <Tooltip.Trigger>
            <InfoList.ItemValue className="atlas-text-text-base-active">{value}</InfoList.ItemValue>
          </Tooltip.Trigger>
        </InfoList.Item>
      </Tooltip.Wrapper>
    );
  }

  if (value) {
    return (
      <InfoList.Item data-testid="record-item">
        <InfoList.ItemTitle>{title}</InfoList.ItemTitle>
        <InfoList.ItemValue>{value}</InfoList.ItemValue>
      </InfoList.Item>
    );
  }
  return null;
};
