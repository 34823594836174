import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { Layer } from "../types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { GreenGreyBeltLayerType } from "../layer_types/GreenGreyBeltLayerType/GreenGreyBeltLayerType";

export const GREY_BELT: Layer = {
  id: ConstraintsCategory.GREY_BELT,
  layerType: ConstraintsLayerType,
  title: "Grey Belt & Declassified Green Belt",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  label: { color: "BLUE", translationKey: "map.explore.label.new" },
  description:
    "Land that could become classified as grey belt and areas of green belt that have been declassified",
  hideByDefault: true,
  layerConfig: {
    intersectionThresholdMeters: -1,
    minZoom: 6,
    rootCategory: ConstraintsCategory.GREY_BELT,
    categorySchema: [
      { key: "grey_belt_land_most_favourable" },
      { key: "grey_belt_land_more_favourable" },
      { key: "grey_belt_land_average_favourability" },
      { key: "grey_belt_land_less_favourable" },
      { key: "grey_belt_land_least_favourable" },
      { key: "declassified_green_belt" },
    ],
  },
};

// There is currently no green belt layer, creating a temp layer to combine a summary of green and grey belt.
// <3 JN & ST!
export const GREEN_GREY_SUMMARY: Layer = {
  id: `${ConstraintsCategory.GREY_BELT}_summary`,
  layerType: GreenGreyBeltLayerType,
  title: "Green & Grey Belt",
  requiredFeatureAccess: [],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description:
    "This dataset is from multiple sources showing housing delivery test, 5 year land supply and status of emerging plans.",
  hideByDefault: true,
};
