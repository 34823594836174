import { PlanningClassification, StatusDerived } from "../../types";

export const CLASSIFICATION_COLS = [
  PlanningClassification.RESIDENTIAL,
  PlanningClassification.COMMERCIAL,
  PlanningClassification.MIXED_USE,
  PlanningClassification.OTHER, // also includes PlanningClassification.UNKNOWN
];

export const STATUS_COLS = [
  StatusDerived.PENDING,
  StatusDerived.APPROVED,
  StatusDerived.REJECTED,
  StatusDerived.WITHDRAWN,
  StatusDerived.UNKNOWN,
];
