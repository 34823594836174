import { Icon } from "react-migration/components/Icon";
import { PropsWithChildren } from "react";
import {
  Transaction,
  TransactionPurchaseType,
} from "react-migration/domains/ownership/typings/Transaction";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

interface WrapperProps extends PropsWithChildren {
  icon: string;
}

function Wrapper({ children, icon }: WrapperProps) {
  return (
    <div className="atlas-flex atlas-items-center atlas-rounded atlas-border atlas-border-border-action atlas-p-2 atlas-bg-white atlas-grow">
      <Icon size="lg" extraClasses="atlas-me-4 atlas-ps-2" icon={icon} />
      <div className="atlas-flex-grow">{children}</div>
    </div>
  );
}

interface PriceProps extends PropsWithChildren {
  onClick(): void;
}

function Price({ onClick, children }: PriceProps) {
  return (
    <div
      className="atlas-text-xs atlas-font-semibold atlas-text-text-base-active atlas-cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
}

interface ViewPurchasedProps {
  transaction: Transaction;
  titleType: TransactionPurchaseType;
}

function ViewPurchased({ transaction, titleType }: ViewPurchasedProps) {
  const { t } = useTranslation();
  const isPhysical = transaction.state?.type === "title_doc_success_physical";

  return isPhysical ? (
    <>{t("ownership.titles_section.physical")}</>
  ) : (
    <a
      rel="noreferrer"
      href={transaction.purchase?.title_document?.url}
      target="_blank"
      className="atlas-font-semibold atlas-text-text-base-active atlas-cursor-pointer"
    >
      {titleType === TransactionPurchaseType.TITLE_PLAN_DOCUMENT
        ? t("ownership.titles_section.view_title_plan")
        : t("ownership.titles_section.view_title_register")}
    </a>
  );
}

function Heading({ children }: PropsWithChildren) {
  return <div className="atlas-text-xs">{children}</div>;
}

export const PurchaseTitle = {
  Wrapper,
  Heading,
  Price,
  ViewPurchased,
};
